import React from "react";
import Layouts from "../../helpers/LayoutsBan";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Bar() {
  return (
    <>
       {/* Helmet for SEO Metadata */}
       <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Banquet Management System| Banquet Software | MicroGenn </title> */}
        <meta
          name="title"
          content="Banquet Management System| Banquet Software | MicroGenn "
        />
        <meta name="description" content="Automate venue reservations, billing, and event coordination with Microgenn’s banquet management system. Real-time monitoring and resource optimization." />
        <meta name="keywords" content="banquet management software, event planning, venue booking, session-based billing, event automation, task monitoring, banquet reservations, catering management, event coordination, hospitality software." />
      </Helmet>
    <Layouts
      pageTitle="Banquet Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Banquet Management", path: "/banquet-management-system" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}