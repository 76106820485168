import React from "react";
import Layouts from "../../helpers/LayoutsIrs";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Irs() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Online Internet Reservation System | Best Hotel Booking System</title> */}
        <meta
          name="title"
          content="Online Internet Reservation System | Best Hotel Booking System"
        />
        <meta name="description" content="Automate bookings, manage availability, and increase efficiency with Microgenn's hotel online reservation system. Secure, easy-to-integrate, and guest-friendly." />
        <meta name="keywords" content="hotel booking system, online hotel reservation software, internet reservation system, hotel management, maximize bookings, hotel channel management." />
      </Helmet>
    <Layouts
      pageTitle="IRS"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Internet reservation system", path: "/internet-reservation-system" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
