import React from "react";
import Layouts from "../../helpers/Layouts";
import AboutSection from "../../HomeThree/AboutSection";
import BacktoTopCom from "../../helpers/BackToTopCom";
import BacktoTopComm from "../../helpers/BackToTopComm";
import TestimonialSection from "../../HomeThree/TestimonialSection";
import WorkProcessSection from "../../HomeThree/WorkProcessSection";
import { Helmet } from 'react-helmet';



export default function AboutOne() {

  return (

       <>
      {/* Helmet for SEO Metadata */}
      <Helmet>
       
        {/* <title>Leading Software Solutions Provider in Coimbatore | Microgenn</title> */}
        <meta
          name="title"
          content="Leading Software Solutions Provider in Coimbatore | Microgenn"
        />
        <meta name="description" content="Microgenn offers leading software solutions with 500+ happy clients. Expertise in hotel management, automation, mobile apps, web development.    " />
        <meta name="keywords" content="software solutions, Coimbatore, hotel management software, automation services, mobile apps, web development, SEO services, IT solutions, user-friendly software, Microgenn
        software solutions, Coimbatore, hotel management software, automation services, mobile apps, web development, SEO services, IT solutions, user-friendly software, Microgenn " />
  
      </Helmet>
   
      <Layouts
        pageTitle="About Us"
        breadcrumbs={[
          { name: "home", path: "/" },
          { name: "About Us", path: "/about-us" },
        ]}
      >
        <AboutSection className="pb-240" />
        <WorkProcessSection />
        <TestimonialSection /> 
      </Layouts>
    </>
  );
}
