import React from "react";
import Layouts from "../../helpers/Layouts";
import MainSection from "./MainSection";


export default function Leave() {
  return (
    <Layouts
      pageTitle="Leave Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Leave Management", path: "/Leave" },
      ]}
    >
      <MainSection />
    </Layouts>
  );
}
