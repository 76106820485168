import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                  <li>
                    <a href="/service-details">Frontoffice</a>
                  </li>
                  <li className="active">
                    <a href="/service-details">Point of Sale</a>
                  </li>
                  <li>
                    <a href="/service-details">Bar</a>
                  </li>
                  <li>
                    <a href="/service-details">Web Development</a>
                  </li>
                  <li>
                    <a href="/service-details">Power</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
               // style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-40">
                <img
                  src={
                    require(`../../../assets/images/services/service-details.jpg`)
                      .default
                  }
                  alt="Service Details"
                />
              </div>
              <h2>Point of Sale</h2>
              <p>Microgenn’s Point of sale software is meant for fine dining outlets, Cafes, Food courts and quick 
                service restaurants, Bars, and Elite Bars. Our POS system interfaces with the front office 
                system and its easy to use with minimal training. Creating bills by pulling up the table or 
                room number from the system is quite simple. 
                {" "}
              </p>
              <ul className="list-style-three pt-15 pb-30">
                <li>Displaying Menu according to Item Category & Item Group
                </li>
                <li>Table Live Status with Vacant, Occupied & Billed
                </li>
                <li>Item Group-wise Discount and User-wise Discount Restriction.
                </li>
                <li>Kitchen Display System with Escalation Matrix SMS              </li>
                <li>Notification to the management of daily reports and updates via SMS </li>
                <li>Non-Charge KOT </li>
              </ul>
              <h3>Explore our audit & assurance services</h3>
              <p>Microgenn’s POS system maintains a detailed guest profile, which ensures that details of repeat 
                visits of guests are stored in history along with their preferences in order to provide them with
                 personalized services. Before settlement bills, high security is provided to verify the guest and member’s signature and photos. A separate discount percentage is allotted for food & beverages, liquor, and smoke. Corporate discounts,
                 guest discounts, and member discounts are considered during bill preparation.{" "}
              </p>
              <div className="row pt-25">
                <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                  <div className="service-normal style-two wow fadeInUp delay-0-2s">
                    <div className="icon">
                      <i className="flaticon flaticon-computer"></i>
                    </div>
                    <h6>Software Development</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                  <div className="service-normal style-two wow fadeInUp delay-0-4s">
                    <div className="icon">
                      <i className="flaticon flaticon-analysis"></i>
                    </div>
                    <h6>Machine Learning</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                  <div className="service-normal style-two wow fadeInUp delay-0-6s">
                    <div className="icon">
                      <i className="flaticon flaticon-design-process"></i>
                    </div>
                    <h6>Software Development</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
