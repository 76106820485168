import React from "react";
import Layouts from "../../helpers/LayoutsApp";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Mobile() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Hotel Management Mobile Application | Hotel Application software</title> */}
        <meta
          name="title"
          content="Hotel Management Mobile Application | Hotel Application software"
        />
        <meta name="description" content="Simplify operations, encourage sales, and enhance guest experience with Microgenn’s hotel management mobile app. Check-in, food ordering, payments, and more." />
        <meta name="keywords" content="hotel management app, mobile check-in, contactless hotel, hotel operations, food ordering app, instant payments, hotel software." />
      </Helmet>
    <Layouts
      pageTitle="Mobile Application"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Mobile Application", path: "/hotel-management-mobile-application" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
