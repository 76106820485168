import React from "react";
// import Layouts from "../../components/helpers/Layouts/index";
import Layouts from "../../helpers/Layouts/index";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Blog() {
  return (
<>
    {/* Helmet for SEO Metadata */}
    <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Hotel Management Systems: Shaping the Future of Hospitality</title> */}
        <meta
          name="title"
          content=" Essential RMS Reports for Optimizing Hotel Revenue Management"
        />
        <meta name="description" content=" Key revenue management software reports to optimize pricing, forecast demand, and boost revenue. Learn how RMS data-driven insights can maximize hotel profits.
" />
        <meta name="keywords" content="Revenue management software, hotel revenue management, RMS reports, RevPAR, occupancy rate, ADR, competitor analysis, forecasting, profit and loss, market segment analysis, hotel profitability
" />
      </Helmet>

   

    <Layouts
      pageTitle="Blog "
      breadcrumbs={[
        { name: "home", path: "/" },
        { name: "blog", path: "/blog/essential-rms-reports-for-optimizing-hotel-revenue-management" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}
