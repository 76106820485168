import React from "react";
import Layouts from "../../helpers/LayoutsRe";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Bar() {
  return (
    <>
         {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        <title>Hotel Revenue Management Software | Revenue Management System</title>
        <meta
          name="title"
          content=" Hotel Revenue Management Software | Revenue Management System  "
        />
        <meta name="description" content="Optimize pricing with Microgenn’s hotel revenue management system. Tailor rates, forecast demand, and boost hotel profitability." />
        <meta name="keywords" content="revenue management software, hotel revenue management, dynamic pricing, inventory management, demand forecasting, real-time pricing, occupancy forecasting, ADR management, hotel profitability, total revenue management." />
      </Helmet>
    <Layouts
      pageTitle="Revenue Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Revenue Management", path: "/hotel-revenue-member-management-software" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}