import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/hotels.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                  
                  <li  className="active">
                    <a href="/Websitebuilder">Hotels and restaurant website builder</a>
                  </li> 
                  <li>
                    <a href="/Fbcosting">F & B Costing</a>
                  </li>
                  <li>
                    <a href="/Club">Club Management</a>
                  </li>
                  <li>
                    <a href="/Guestfeedback">Guest Feedback</a>
                  </li>
                  
                  
                  <li>
                    <a href="/Kot">Tab-based KOT System</a>
                  </li>
                 
                  <li>
                    <a href="/Self">Self-ordering Restaurant QR code based</a>
                  </li>
                  <li>
                    <a href="/Webcheck">Web Check-in</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
               // style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-9566411119">+91-95664-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-40">
                {/* <img
                  src={
                    product
                  }
                  alt="hotels"
                /> */}
              </div>
              <h2>Hotels and restaurant website builder</h2>
              <p>Microgenn comprises a website that strengthens your brand&#39;s online presence
                and it is the first point of contact that customers would look at before visiting
                your hotel. For F&amp;B businesses and accommodation providers, it is a simple
                website builder at an affordable price. Along with a dedicated web designer, our
                solution will give you complete access to your website and the freedom to make
                changes.
                {" "}
              </p>
              <p>Microgenn website builder helps clients to showcase their brand, exactly how
                they want it and also takes care of all requisites. Select or customize your
                website by choosing any of our pre-designed templates, theme option, and
                several pages. It helps to design the most elegant website for your brand and
                attract more visitors to it.
              {" "}
              </p>
              <p>Our hotel website maker designs websites that are responsive on all types of
                devices like desktops, laptops, mobile, or tablets. The hotel website creator
                includes all SEO-related features that can make your website rank well on
                search engines. Our website maker has a bunch of widgets like exit popups,
                notifications, and more to attract visitors to make bookings.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Dedicated website designer
                </li>
                <li>Multiple template options
                </li>
                <li>An array of themes
                </li>
                <li>Multi-language support</li>
                <li>Free hosting and SSL</li>
                <li>Drag and drop elements</li>
                <li>SEO-friendly site</li>
                <li>Responsive on all device</li>
                <li>Chains of properties/outlets. One website</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
