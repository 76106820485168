import React from "react";
import Layouts from "../../helpers/LayoutsPurchase";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Bar() {
  return (
  <>
  {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Purchasing Software | Purchasing Management System        </title> */}
        <meta
          name="title"
          content=" Purchasing Software | Purchasing Management System    "
        />
        <meta name="description" content="MicroGenn provides a purchasing management system that, by combining features and reporting, enables tighter cost control and eliminates of inefficiency." />
        <meta name="keywords" content="purchase management software, procurement system, cost control, purchase orders, purchase requisitions, supplier management, budget analysis, GRN, automated purchasing, hospitality software." />
      </Helmet>
    <Layouts
      pageTitle="Purchase"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Purchase", path: "/purchase-software" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}