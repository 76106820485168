import React from "react";
import Layouts from "../../helpers/layoutsLinen";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Linen() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Linen Management Software for Hotel | Laundry Management System        </title> */}
        <meta
          name="title"
          content="Linen Management Software for Hotel | Laundry Management System   "
        />
        <meta name="description" content="The MicroGenn's linen management software system helps you manage linens for commercial laundry, hospitality laundry service, uniform service, and more." />
        <meta name="keywords" content="Linen Management Software, Laundry Management System, Linen Management, Laundry Management, Commercial laundry software, Linen inventory." />
      </Helmet>
    <Layouts
      pageTitle="Linen Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Linen Management", path: "/linen-management-software" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
