import React from "react";
import CounterUp from "../helpers/CounterUp";
import SliderCom from "../helpers/SliderCom";
import author1 from "../../assets/images/testimonials/testi-author-1.jpg";
import author2 from "../../assets/images/testimonials/testi-author-2.jpg";
export default function TestimonialSection() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section
      className="testimonial-section bg-lighter pt-60 rpt-95 pb-120 rpb-100"
      id="testimonial-section"
    >
      <div className="container">
        <div className="section-title text-center mb-65">
          <span className="sub-title">It Support For Business</span>
          <h2>
            Preparing for your success <br />
            <span id="color">Trusted </span>source in IT services.
          </h2>
        </div>
        <div className="fact-counter-color text-center mb-30">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-2s">
                <span className="count-text plus">
                  <CounterUp
                    sectionSelect="testimonial-section"
                    endValue={24}
                  />
                </span>
                <p className="text-center">Awards Won</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-4s">
                <span className="count-text plus">
                  <CounterUp
                    sectionSelect="testimonial-section"
                    endValue={20}
                  />
                </span>
                <p className="text-center">Years Experience</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="success-item wow fadeInUp delay-0-6s">
                <span className="count-text plus">
                  <CounterUp
                    sectionSelect="testimonial-section"
                    endValue={550}
                  />
                </span>
                <p className="text-center">Happy Clients</p>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-wrap">
          <SliderCom settings={settings}>
            <div className="testimonial-item wow fadeInLeft delay-0-2s">
              <div className="author-description">
                <img
                  src={
                    author1
                  }
                  alt="Author"
                />
                <div className="designation">
                  <h5>Mr.Ramkumar</h5>
                  <span>Veeras Residency MD</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p>The Package has excellent features and options to manage critical and 
                day to day situations in typical hotel atmosphere
              </p>
            </div>
            <div className="testimonial-item wow fadeInRight delay-0-2s">
              <div className="author-description">
                <img
                  src={
                    author2
                  }
                  alt="Author"
                />
                <div className="designation">
                  <h5>Mr.Raju</h5>
                  <span>Raju Sree Rayappas Manageing Partner</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p>Hotel Management System supplied and installed is working very good and thier Support also too good   </p>
            </div>
            {/* <div className="testimonial-item">
              <div className="author-description">
                <img
                  src={
                    author1
                  }
                  alt="Author"
                />
                <div className="designation">
                  <h5>Mr.Ramkumar</h5>
                  <span>Hotel Chithambam Manager</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p>Software withstood without data loss at occations of frequent power faliure and thier Support also too good      </p>
            </div> */}
            {/* <div className="testimonial-item">
              <div className="author-description">
                <img
                  src={
                    author2
                  }
                  alt="Author"
                />
                <div className="designation">
                  <h5>Naneen</h5>
                  <span>General Manager</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p>The high-speed performance of this package is a unique solutions to peak rush situation           </p>
            </div> */}
          </SliderCom>
        </div>
      </div>
    </section>
  );
}
