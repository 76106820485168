import React from "react";
import { Link } from "react-router-dom";
function FeatureSection() {
  return (
    <section className="featured-section bgs-cover pt-150 rpt-150 pb-120 rpb-100 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="section-title text-center mb-35">
              <span className="sub-title">One-step ideal software solution for your hotels</span>
              <h2>Modules in Microgenn’s <br></br><span id="color">Hotel Management Software </span> MHMS</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-2s">
              <div className="icon">
                <i className="flaticon flaticon-art"></i>
              </div>
              <div className="feature-content">
                <h5>Front Office</h5>
                <p>Front office system is a primary requirement for every property because every multi-departmental physical business needs to have a front office ..
                </p>
                <Link to="frontoffice" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-6s">
              <div className="icon">
                <i className="flaticon flaticon-development-3"></i>
              </div>
              <div className="feature-content">
                <h5>Point Of Sale</h5>
                <p>Point of sale software is meant for fine dining outlets, Cafes, Food courts and quick service restaurants, Bars and Elite Bars with the help of ..
                </p>
                <Link to="/pointofsales" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-8s">
              <div className="icon">
                <i className="flaticon flaticon-analysis-1"></i>
              </div>
              <div className="feature-content">
                <h5>Bar Management</h5>
                <p>Bar management is loaded with extraordinary features which allow your bartenders to quickly and accurately serve customers and create a unique ..</p>
                <Link to="/bar-management-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-4s">
              <div className="icon">
                <i className="flaticon flaticon-cloud-computing-1"></i>
              </div>
              <div className="feature-content">
                <h5>Housekeeping</h5>
                <p>Housekeeping software makes the business process very smooth and flourishes rapidly. Planning, assigning and tracking of housekeeping activities ..
                </p>
                <Link to="/housekeeping" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Linen Management</h5>
                <p>Microgenn linen management module helps to take it to next level. Tagging and tracking customers’ garments help to run in a hassle-free way..</p>
                <Link to="/linen-management-software" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-0s">
              <div className="icon">
                <i className="flaticon flaticon-web-development"></i>
              </div>
              <div className="feature-content">
                <h5>Banquet</h5>
                <p>Banquet and event management enables the automation and management of a single day event, multi-day events or any conventions. Support every ..               </p>
                <Link to="/banquet-management-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Purchase</h5>
                <p>Microgenn purchasing module is a well-organized procurement process based on intuitive features and functions. Tighter cost control and .. </p>
                <Link to="/purchase-software" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Material Management</h5>
                <p>The major challenge that materials managers face is maintaining a consistent flow of materials for production. Our software is a one-stop ..               </p>
                <Link to="/Material-Management-System" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-0s">
              <div className="icon">
                <i className="flaticon flaticon-web-development"></i>
              </div>
              <div className="feature-content">
                <h5>Payroll Management</h5>
                <p> Payroll management is a complex process due to a large number of calculations and statutory compliance requirements. This process consists of the ..</p>
                <Link to="/payroll-management-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Gate Pass</h5>
                <p>Microgenn gate pass module maintains and monitors gate registers for visitors, materials, and company vehicles.Returnable and non-returnable ..</p>
                <Link to="/gatepass-management-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Task Management</h5>
                <p>Microgenn task management module helps enhance overall guest experience and satisfaction by providing services like- automated task handling ..</p>
                <Link to="/task-management-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Leisure Management</h5>
                <p>Covering all business aspects of planning, reservations, and service accounting, Microgenn offers many possibilities for tracking and keeping ..</p>
                <Link to="/leisure-management-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Travel Desk</h5>
                <p>Employees must be within the reach of the guests to create a memorable experience, throughout their stay.Whether your employees are working ..</p>
                <Link to="/travel-desk" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Retail Management</h5>
                <p>While maintaining established standards, the field of hospitality retail management offers managers an opportunity to enhance a customer’s hospitality..</p>
                <Link to="/retail-management" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Asset Tracking</h5>
                <p>Like all businesses &amp; resort industries will have a period of peak performance and periods of economic decline.There is no doubt that there are ..</p>
                <Link to="/assest-management" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Kitchen Display System (KDS)</h5>
                <p>Recipe viewers manage quality control as the software includes images of the end product before getting run to diners.</p>
                <Link to="/kitchen-display-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Power Automation System</h5>
                <p>The power automation system is a system for managing, controlling, and protecting the various components connected to the ..</p>
                <Link to="/power-automation-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Central Reservation System</h5>
                <p>Microgenn central reservation system manages all bookings and distribution from one place fast and easily. It maintains .. </p>
                <Link to="/central-reservation-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Internet Reservation System</h5>
                <p>The Internet reservation system is an online booking engine that allows guests to make reservations through the hotel website..</p>
                <Link to="/internet-reservation-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          
        </div>
       
      </div>
    </section>
  );
}

export default FeatureSection;
