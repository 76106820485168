import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/sms.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="#">Wifi Interface</a>
                  </li>
                
                <li    className="active">
                    <a href="/Sms">E-Alert SMS</a>
                  </li>
                  <li>
                    <a href="/Email">E-Alert Email</a>
                  </li>
                  <li>
                    <a href="/Whatsapp">E-Alert WhatsApp</a>
                  </li>
                  
                  <li>
                    <a href="/Hotelchannel">Hotel Channel Manager</a>
                  </li>
                
                  
                  <li>
                    <a href="/Tally">Tally Interface</a>
                  </li>
                  <li >
                    <a href="#">Door lock</a>
                  </li>

                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
               // style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-30">
                {/* <img
                  src={
                    product
                  }
                  alt="Frontoffice"
                /> */}
              </div>
              <h2> <span id="color">SMS</span> E-Alert System</h2>
              <p>Automated communication with customers, volunteers, and members facilitate
                the mitigation of problems quickly and effectively. With our premium
                Microgenn SMS alert system, your notifications will be delivered immediately
                without any delay. Whether you are a multinational company, a community
                organization, a school, or a college, Our SMS alert system will be your best
                investment.
                {" "}
              </p>
              <p>For a hotel, SMS offers a great opportunity to send Bulk or a specific messages
                to guests. In an industry where owners are away from the hotel, easily they can
                know about hotel positions and collection details. We have different templates
                for your automated messages, and you can create them easily.
              {" "}
              </p>
              <p>Hotels using this system also can send important notifications to their staff.
                With our microgenn’s SMS alert system, hotels can respond to guests faster and
                stay connected with guests even after their stay. This smart system is also a
                great tool to enhance brand loyalty among your evolving guests.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <h2>SMS to Hotel Guests</h2>
              <ul className="list-style-three pt-15 pb-30">
                <li>Reservation Confirmation.
                </li>
                <li>Confirmation on Amendments &amp; Cancellation.
                </li>
                <li>Birthday, Anniversary Wishes.
                </li>
                <li>Welcome message on Arrivals.</li>
                <li>Thank you Message on Departures. </li>
                <li>Send messages with your brand sender ID.</li>
                <li>Send guest messages directly to guest mobile from PMS.</li>
                <li>Guest Feedback.</li>                
              </ul>
              <h2>SMS to Hotel Management</h2>
              <ul className="list-style-three pt-15 pb-30">
                <li>Hourly Alerts – Alerts on room Position &amp; Occupancy status can send SMS-based scheduling.
                </li>
                <li>Daily Alerts – Delivers Night Audit SMS (Revenue, Occupancy %, Collection Status) once Night Audit completes.
                </li>
                <li>Alerts on Exceptions – Alerts on High balance, Reservation.
                </li>
                <li>Cancellation, Room Transfer, Checkout, KOT/Bill Cancellation and etc.</li>
                <li>Guest Complaints – Complaint SMS delivered to concerned department/persons. Ensures speedy solutions to Guest Complaints. </li>             
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
