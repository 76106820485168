import React from "react";
import image1 from "../../assets/images/about/about-three-1.jpg";
import image2 from "../../assets/images/about/about-three-2.jpg";
export default function AboutSection({ className }) {
  return (
    <section className={`about-three py-120 rpy-100 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-three-image rmb-60 mr-10 rmr-0 wow fadeInLeft delay-0-2s">
              <img
                src={
                  image1
                }
                alt="About"
              />
              <img
                src={
                  image2
                }
                alt="About"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content pl-20 rpl-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">What we are Specialized in?</span>
                <h2>
                We are the experts in developing<span id="color"><br></br>Hotel</span> Management Software
                </h2>
              </div>
              <p>
              We are one of the leading software solutions providers in Coimbatore with 500+ happy clients.We excel in Hotel management software, Power automation services, Mobile applications, Website development, SEO services or any other IT solution on our client’s wish list.
              </p>
              <ul className="list-style-three mt-15">
                <li>Reduced Complexity </li>
                <li>User-friendly</li>
                <li>Single Unit</li>
                <li>24/7 Support</li>
              </ul>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
