import React from "react";
import Layouts from "../../helpers/LayoutsLe";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Leisure() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Leisure Management System | Wellness Management System </title> */}
        <meta
          name="title"
          content="Leisure Management System | Wellness Management System"
        />
        <meta name="description" content="Microgenn’s integrated leisure management system for spas, sports clubs, and wellness centers. Optimize efficiency and enhance guest experiences." />
        <meta name="keywords" content="Leisure management, spa software, gym management, wellness solutions, membership software, hotel management, staff scheduling, guest services, transaction tracking, fitness center management." />
      </Helmet>
    <Layouts
      pageTitle="Leisure Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Leisure Management", path: "/leisure-management-system" },
      ]}
    >
      <MainSection />
    </Layouts>
  </>);
}
