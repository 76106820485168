import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/tally.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="#">Wifi Interface</a>
                  </li>       
                <li >
                    <a href="/Sms">E-Alert SMS</a>
                  </li>
                  <li>
                    <a href="/Email">E-Alert Email</a>
                  </li>
                  <li>
                    <a href="/Whatsapp">E-Alert WhatsApp</a>
                  </li>
                  
                  <li>
                    <a href="/Hotelchannel">Hotel Channel Manager</a>
                  </li>
                
                  
                  <li   className="active">
                    <a href="/Tally">Tally Interface</a>
                  </li>

                  <li >
                    <a href="#">Door Lock</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-30">
                {/* <img
                  src={
                    product
                  }
                  alt="Tally"
                /> */}
              </div>
              <h2>Tally Interface Software</h2>
              <p>The tally interface enables restaurants and hotels to post their invoice and
                receivables details automatically to the financial accounting software directly
                from the hotel PMS restaurant POS respectively. It provides accuracy in posting
                details and saves time.
                {" "}
              </p>
              <p>The hotel management software maintains a record of all the expenses made in
                the hotel thoroughly along with the bills as proof. A bookkeeping module that
                keeps track of all the cash inflow and outflow in real-time, within the software.
                By using this software, hoteliers can create price quotation documents and
                handle their purchases.
              {" "}
              </p>
              <p>The software will provide detailed inventory handling features powered by the
                Tally ERP 9 add-ons for hotels. Hotel Management on Tally performs a detailed
                analysis of the workforce efficiency and generates reports on those analyses. It
                enables a GST-compliant taxation system. Hotel owners plan various discount
                schemes and check for their profitability through the cost analysis module.
              {" "}
              </p>
              <p>Hotel Management on Tally is a top-of-the-class solution that is designed for
                hoteliers to manage daily operations and monitor their workforce activities. It
                has pre-built modules for check-ins, check-outs, restaurant services, etc. to
                reduce the daily work of the hotel staff. Users can create a digital outlay of the
                entire hotel premises to monitor all the tasks related to it. For instance, staff
                members can create rooms and label them and also categorize them separately
                as per their prices, types, etc. This also helps them to quickly allot rooms for
                customers and check the status of occupancy. {" "}</p>
              <p>Hotel Management on Tally eliminates the scope for double or overbooking by
                using the above-mentioned hotel outlay. It also helps hotel owners manage their
                finances accurately by keeping track of all the expenses and revenue from a
                single place. It also provides standardized templates for billing, menu, and
                banquet design to hotel staff to manage their daily workflow. It is a one-stop
                solution that allows different working teams of the hotel to seamlessly assign
                and report their activities. This hotel management software solution uses the
                Tally ERP 9 integration for hassle-free account management. It helps
                hoteliers manage and track their inventory in real-time. It also helps with cost
                analysis assessments for all the expenses.{" "}</p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Provides accuracy in posting details
                </li>
                <li>Increases speed and efficiency
                </li>
                <li>Reduces human error
                </li>
                <li>Easy access to information</li>
                <li>Saves time </li>
                <li>Financial Accounting</li>
                <li>Purchase &amp; stores management</li>
                <li>Branch management</li>
                <li>Bill management</li>
                <li>Payroll</li>
                <li>Statutory report </li>
                <li> Tally Add-Ons for Hotels</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
