import React from "react";
import Layouts from "../../helpers/layoutsKds";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Kds() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Kitchen Display System for Hotel | Kitchen Display Software</title> */}
        <meta
          name="title"
          content="Kitchen Display System for Hotel | Kitchen Display Software"
        />
        <meta name="description" content="Microgenn’s Kitchen Display System optimizes food quality, workflow, Real-time updates, durable components, and customer satisfaction in restaurants." />
        <meta name="keywords" content="Kitchen display system, KDS, food quality, workflow optimization, customer satisfaction, restaurant efficiency, order prioritization, ticket times." />
      </Helmet>
    <Layouts
      pageTitle="KDS"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "KDS", path: "/kitchen-display-system" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
