import React from "react";
import { useState } from "react";
import axios, * as others from 'axios';
import background from "../../assets/images/contact/contact-page.jpg";
import Layouts from "../helpers/Layouts";
import Swal from "sweetalert2";
import { Helmet } from 'react-helmet';

export default function Contact() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");
    const [mobile, setMobile] = useState("");

    const addContact = () => {
    
      var mobile = document.getElementById('mobile').value;
      var name = document.getElementById('name').value;
      var email = document.getElementById('email').value;
      
      if(mobile !='' && name !='' && email !=''){

         axios.post('https://formspree.io/f/xayggypg', {
        name : name,
        email: email,
        msg : msg,
        mobile: mobile
      }).then(()=>{
      
        Swal.fire({
          title: "Success",
          text: "Thanks, We will Contact you Shortly.",
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        }).then(()=>{
          setEmail("");
          setMobile("");
          setMsg("");
          setName("");
        });
      
      });
    }
      else{

        Swal.fire({
          title: "Required",
          text: "fill out All the required fields",
          icon: "error",
          showConfirmButton: false,
          timer: 2000
        })
      }
    };
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Connect with Us: Reach Out Today! | MicroGenn</title> */}
        <meta
          name="title"
          content="Connect with Us: Reach Out Today! | MicroGenn"
        />
        <meta name="description" content="Visit our Contact Us page to get in touch. Find our email, phone number, and address. We’re here to assist!" />
        <meta name="keywords" content="Contact us, Reach out, Email, Phone Number, Address." />
      </Helmet>
      <Layouts
        pageTitle="Contact us"
        breadcrumbs={[
          { name: "home", path: "/" },
          { name: "Contact Us", path: "/contact" },
        ]}
      >
        <section className="contact-page py-120 rpy-100">
          <div className="container">
            <div className="contact-info-area mb-80">
              <div className="contact-info-item wow fadeInUp delay-0-2s">
                <i className="far fa-map"></i>
                <p>#284, Peranaidu Layout, Ram Nagar, Coimbatore, Tamil Nadu 641009.</p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-4s ">
                <i className="far fa-envelope"></i>
                <p className="mb-0">
                  <a href="mailto:info@microgenn.com" className="text-center"><span>Partnership </span>: info@microgenn.com</a>{" "}
                </p>
                <p className="mb-0">
                  <a href="mailto:sales@microgenn.com" className="text-center"><span className="contactpad1">Sales </span>: sales@microgenn.com</a>{" "}
                  
                </p>
                <p className="mb-0">
                  <a href="mailto:sales@microgenn.com" className=""><span>Support </span>: customersupport@microgenn.com</a>{" "}
                </p>

                  
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-6s">
                <i className="fas fa-phone-alt"></i>
                <p>
                  <a href="callto:+91-9790090010"><span>Partnership </span>: +91-99525-11119</a><br />
                  <a href="callto:+91-8220311119"><span className="contactpad1">Sales </span>: +91-82203-11119</a><br></br>
                  <a href="callto:+91-9566411119"><span className="contactpad3">Support </span>: +91-95664-11119</a><br />
                  
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="contact-form-left bgs-cover h-100 wow fadeInLeft delay-0-2s"
                  style={{ backgroundImage: `url(${background})` }}
                >
                 {/* } <h2>Leave A Message</h2> { */}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="contact-form ml-40 rml-0 rmt-55 wow fadeInRight delay-0-2s">
                  <h2 className="comment-title mb-35">Send A Message</h2>
                  <p>
                    Avoids pleasure itself, because it is pleasure, but because
                    those who do not know how to pursue pleasure rationally
                    encounter
                  </p>
  
                  <form
                    id="comment-form"
                    className="comment-form mt-35 App"
                    name="comment-form"
                    
                    method="POST"
                  >
                    <div className="row clearfix justify-content-center">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="full-name">
                            <i className="far fa-user"></i>
                            <span className="spanco">*</span>
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Your Full Name"
                            required="required"
                            onChange={(event) =>{ setName(event.target.value);}}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            <i className="far fa-envelope"></i>
                            <span className="spanco">*</span>
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Your Email"
                            required="required"
                            onChange={(event) =>{ setEmail(event.target.value);}}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="number">
                            <i className="fa fa-mobile"></i>
                            <span className="spanco">*</span>
                          </label>
                          <input
                            type="text"
                            id="mobile"
                            name="mobile"
                            className="form-control"
                            placeholder="Your phone Number"
                            required="required"
                            maxLength={10}
                            onChange={(event) =>{ setMobile(event.target.value);}}
                          />
                        </div>
                      </div>
                      
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="comments">
                            <i className="fas fa-pencil-alt"></i>
                          </label>
                          <textarea
                            name="msg"
                            id="comments"
                            className="form-control"
                            rows="4"
                            placeholder="Write Message"
                            required=""
                            onChange={(event) =>{ setMsg(event.target.value);}}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group mb-0">
                          <button type="button" className="theme-btn1" onClick={addContact}>
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="contact-page-map">
          <div className="our-location">
            <iframe
              title="map"
              src="https://maps.google.com/maps?q=microgenn%20software&t=&z=13&ie=UTF8&iwloc=&output=embed"
              height="650"
              style={{ border: "0", width: "100%" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </Layouts>
    </>
  );
}
