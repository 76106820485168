import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/leave.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li>
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar</a>
                  </li>
                  <li>
                    <a href="/Webdevelopment">Web Development</a>
                  </li>
                  <li >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Banquet">Banquet/Event Management</a>
                  </li>
                  <li>
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  
              
                  <li>
                    <a href="/Revenue">Revenue Management</a>
                  </li>
                 
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>
                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                 
                  <li  >
                    <a href="/Crm">CRM</a>
                  </li>
                  
                  <li>
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li>
                    <a href="/Tally">Tally Interface</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>
                  <li  className="active">
                    <a href="/Leave">Leave Management</a>
                  </li>
                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>
                  
                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li>
                  <li>
                    <a href="/Asset">Asset Management</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-40">
                {/* <img
                  src={
                    product
                  }
                  alt="Leave"
                /> */}
              </div>
              <h2>Leave Management</h2>
              <p>A leave management system will provide a quick view of the monthly
                attendance of employees to view complete attendance details. Integration with
                the attendance management system enables employees to view complete
                attendance details for the payroll period. It includes online leave requests,
                approval and regularization, and also employees’ leave history.
                {" "}
              </p>
              <p>As per the company’s policy, it Configures the different types of leaves and
                defines rules for availing the leaves. Create a dynamic workflow to approve the
                leave request applications in the HMS. Carry forward and encashment of leave
                can also be configured in the leave management system. Managers can effectively
                manage Resource availability before approving the leave.
              {" "}
              </p>
              <p>The leave management module is integrated with biometrics/access control data
                and absentee data which is directly recorded in the HMS system. The leave
                management module of employees consists of leave definitions and policies
                applicable to granting leave, weekend policies, restrictions, etc. It supports
                easily applying, approving, canceling, and rejecting leaves through the online
                interface. Leave cancellation before and after approval can be done without any
                hassle. All attendance events like in-time, out-time, permission, late, and on-
                duty are displayed in the calendar view. Create regional, customized, national,
                or restricted holiday lists, and publish them across the organization. Email alerts
                are provided for approval, cancellation, and rejection of online leave
                applications.
              {" "}
              </p>
              <h3>Features</h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Integration into the attendance system
                </li>
                <li>Leave framework
                </li>
                <li>Employee friendly
                </li>
                <li>Public calendar</li>
                <li>Holiday lists</li>
                <li>Alerts</li> 
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
