import React from "react";
import Layouts from "../../helpers/LayoutsPayroll";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Bar() {
  return (
    <>
          {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Payroll Management System for Hotel | Payroll Software </title> */}
        <meta
          name="title"
          content="Payroll Management System for Hotel | Payroll Software"
        />
        <meta name="description" content="Microgenn’s payroll management system streamlines salary calculations, reduces manual work, and enhances employee productivity." />
        <meta name="keywords" content="Payroll Management system, Payroll Software, Payroll management, Payroll automation, Salary certificates, Staff management." />
      </Helmet>
    <Layouts
      pageTitle="Payroll Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Payroll Management", path: "/payroll-management-system" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}