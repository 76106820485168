import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/payroll.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li className="active">
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Frontoffice"
                /> */}
              </div>
              <h2>Payroll Management System</h2>
              <p>Payroll management is a complex process due to a large number of calculations
                and statutory compliance requirements. This process consists of the calculation
                of employee salaries and tax deductions, employee benefits and payment of
                salaries. You need a stress-free solution that is accurate, fully baked and very
                secure. Our microgenn’s payroll management system manages the payroll
                professionally without any hassles and it is quite convenient to use.
                {" "}
              </p>
              <p>With the incorporation of this system, you can easily access contact
                information, personal information, salary data, attendance, investment details,
                salary certificates and much more. This will totally reduce human errors and
                increase productivity. By tracking their salary revision history and designation
                changes, follow employee’s career progress. Enter the departing employee’s last
                working day with the final and full settlement date to process the payment
                automatically. With automated gratuity calculation, acknowledge the efforts of
                your long-serving staff.
              {" "}
              </p>
              <p>Microgenn’s payroll management system reduces the dependency on manual
                entries and it provides easy access to pieces of information that are required to
                make decisions more effectively and efficiently. The pre-defined template is
                used to import previous employment or salary details, reduce manual work and
                delete duplicates. This is a proven solution that manages the employees from
                the time they joined until they leave the organization.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Configurable salary structure.
                </li>
                <li>Add unlimited salary components.
                </li>
                <li>Automate reimbursements, loans, and advances.
                </li>
                <li>Process payroll with automated payroll inputs.</li>
                <li>Generate and distribute customized payslips. </li>
                <li>Generate statutory reports.</li>
                <li>Disburse salaries with ease.</li>
                <li>Generate accounts.</li>                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
