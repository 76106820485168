import React from "react";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                  <li  className="active">
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li>
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li>
                    <a href="/Bar">Bar</a>
                  </li>
                  
                  <li>
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li>
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li>
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li>
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
               
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">

              <h2>Front Office System</h2>
              <p >Microgenn offers a gamut of software solutions that specifically fulfill
                every requirement of the hospitality industry. Microgenn develops
                solutions based on the complexity and correlation of various functions in
                hospitality. We reckon on the fact that software solutions should simplify
                your business process by helping you to focus on the issues of business
                growth and customer satisfaction.
                {" "}
              </p>
              <p>
              Microgenn front office system is a futuristic management system that
              facilitates hotels and hotel chains to manage business operations in an
              effective and efficient manner. To meet international standards, we have
              designed and developed using the latest technology.
              {" "}
              </p>
              <p>
              The simple Graphical User Interface and features ensure seamless
              understanding and adaptation to the solution. Microgenn is perfectly
              capable to meet the specific requirements of our clients, whether you are
              an individual banquet hotel or a multi-chain property. Its easy-to-use
              features and simple Graphical User Interface ensure learning and adapting
              the solution is effortless Whether you are an individual boutique hotel or a
              multi-chain property.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Quick Check-in
                </li>
                <li>Group Check-in
                </li>
                <li>Reservation Check-in
                </li>
                <li>Walk-in</li>
                <li>Checkout </li>
                <li>Group checkout</li>
                <li>Group Transfer</li>
                <li>Partial Checkout</li>
                <li>Split Bill</li>
                <li>Advance &amp; Bill Posting</li>
                <li>Color-Based Room Status</li>
                <li>Auto Rate positioning based on the Occupancy</li>
                <li>Occupancy Ratio and Position</li>
                <li>GST Ready Reports</li>
                <li>Integration with Any Accounting Software</li>
                <li>Greetings and notification to the Guest and Management</li>
                <li>Restrict Unauthorized Entry into the Room</li>
                <li>Mobile App for the Revenue, Room Position, and Collection</li>
                <li>Comparison Reports</li>
                <li>User-friendly operation</li>
                <li>Unbreakable security structure (Multi-level User rights escalation matrix)</li>
                <li>Integration with All leading Channel managers</li>
                <li>Integration with All leading hotel Locks</li>
                <li>Room type and Rack Rate Tariff based Booking.</li>
                <li>A complete reservation management system.</li>
                <li>Cashiering – Handles Various Transactions (Advance, checkout, Refund).</li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
