import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li>
                    <a href="/Websitebuilder">Hotels and restaurant website builder</a>
                  </li> 
                
                  <li  >
                    <a href="/Fbcosting">F & B Costing</a>
                  </li>
                  <li  >
                    <a href="/Club">Club Management</a>
                  </li>
                 
                  <li>
                    <a href="/Guestfeedback">Guest Feedback</a>
                  </li>
                  
                  
                  <li >
                    <a href="/Kot">Tab-based KOT System</a>
                  </li>
                 
                  <li  className="active">
                    <a href="/Self">Self-ordering Restaurant QR code based</a>
                  </li>
                  <li>
                    <a href="/Webcheck">Web Check-in</a>
                  </li>
                 
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-9566411119">+91-95664-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Self Ordering"
                /> */}
              </div>
              <h2> Self-ordering Room Service QR based System</h2>
              <p>To offer a pleasant in-room ordering experience, the hospitality industry uses a
                QR code hotel room service menu. The guest can scan the tableside QR code to
                order &amp; pay for their meals. In the hospitality business, the convenience of
                digitalization has a significant impact. 
                {" "}
              </p>
              <p>The hospitality industry should give innovative measures in catering to the
                needs of their client, whether booking a room or accessing a hotel digital room
                service menu. The target population for a hotel menu is distinct.
              {" "}
              </p>
              <p>Most hotel guests order room service to enjoy comfort and convenience during
              their stay. Most customers are overseas tourists, business travelers, disabled
              travelers, and families who use in-room dining amenities.You will need a technology-based solution to meet your customers’
              expectations, to avoid the drawbacks of providing room service. The food and
              beverage outlets can provide convenient and fast service to the consumers with
              digital menu software by seeking comfort within their immediate vicinity.
              {" "}
              </p>
              <p>
               By looking at the digital menu, customers can get a sense of the personality and
              character of the menu. It is the hospitality and restaurant industry’s most
              significant marketing tool. It is difficult to avoid making mistakes while
              building a digital menu. The missteps can go overlooked by the management.
              </p>
              <p>While creating a digital menu, some pointers for avoiding common blunders are, </p>
                
              <ul className="list-style-three pt-5 pb-20">
                <li>Save the high-resolution picture format of your QR code
                </li>
                <li>Avoid inverted QR code colors
                </li>
                <li>Consider the size of the menu QR code that you will produce
                </li>
                
                <li>Don’t over-customize the QR code for the menu </li>
                
                <li>Collect feedback easily</li>
                <li>Track analytics</li>
              </ul>
              
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-5 pb-20">
                <li>Hassle-free room service using mobiles
                </li>
                <li>Ordering and paying for food within the room
                </li>
                <li>Tracking of the orders and additional services for audit purposes
                </li>
                
                <li>Provide comfort and convenience to guests </li>
                
                <li>Collect feedback easily</li>
                <li>Track analytics</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
