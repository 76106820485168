import { Link } from "react-router-dom";
function FooterHomeOne() {
  return (
    <footer className="main-footer footer-one text-white">
      <div className="footer-widget-area bgs-cover pt-100">
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-lg-4 col-sm-6">
              <div className="footer-widget about-widget">
                
                  <h4 className="footer-title" id="font">ABOUT MICROGENN</h4>
                
                <div className="text">
                Microgenn Software Solutions has been incorporated in the year 2002 in India by a team of young dynamic professionals
                 with a perfect blend of Technical, Marketing, and Administration skills.
                </div>
              </div>
            </div> */}
              <div className="col-lg-3 col-sm-6">
              <div className="footer-widget about-widget">
                
                  <h4 className="footer-title text-center" id="font">AWARDS</h4>
                
                <div className="d-flex ">
                  <a href='https://www.softwaresuggest.com/microgenn-sw-so'target='_blank'>
                    <img src='https://www.softwaresuggest.com/award_logo/best-value-fall2020.svg' width={180}></img>
                    
                  </a>
                  <a href='https://www.softwaresuggest.com/microgenn-sw-so' target='_blank'>
                    <img src='https://www.softwaresuggest.com/award_logo/Faster-Growing-Software-Products.png' width={180}></img>
                  </a>
                 
                </div>
                <div className="d-flex justify-content-center">
                  <a href='https://www.softwaresuggest.com/microgenn-sw-so' target='_blank'>
                    <img src='https://www.softwaresuggest.com/award_logo/Trending-2022.png' width={180}></img>
                  </a>

                </div>
              </div>
            </div> 
            <div className="col-lg-2 col-sm-6">
              <div className="footer-widget link-widget ml-20 rml-0 mr-30 rmr-0">
                <h4 className="footer-title text-uppercase" id="font">Consumer Policy</h4>
                <ul className="list-style-two">
                  <li>
                    <Link to="/TermsandConditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/CancellationRefund">Cancellation & Refund</Link>
                  </li>
                  <li>
                    <Link to="/Shipping">Shipping & Delivery Policy</Link>
                  </li>
                  <li>
                    <Link to="/Payment">Payment</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="footer-widget contact-widget mr-30 rmr-0">
                <h4 className="footer-title" id="font">CONTACT US</h4>
                <ul className="list-style-two">
                  <li>
                    <i className="fas fa-map-marker-alt"></i> #284, Peranaidu Layout, Ram Nagar,<br></br> Coimbatore - 641009.
                  </li>
                  <li>
                    <i className="fas fa-clock"></i> Mon-Sat 9:30 - 6:30
                  </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                    <a href="mailto:sales@microgenn.com"><span className="contactpad1">Sales  </span>: sales@microgenn.com</a>
                  </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                    <a href="mailto:info@microgenn.com"><span className="contactpad2">Partnership </span> : info@microgenn.com</a>
                  </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                    <a href="mailto:customersupport@microgenn.com"><span className="contactpad3">Support </span>: customersupport@microgenn.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 s">
              <div className="footer-widget about-widget">
                  <h4 className="footer-title" id="font">LOCATION</h4>
                  <div>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.2966445731563!2d76.96121927422575!3d11.01635545472397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8585562528fed%3A0x63c56ba58e246ad1!2sMicrogenn%20Software%20Solutions!5e0!3m2!1sen!2sin!4v1672035855475!5m2!1sen!2sin" 
                   loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div className="copyright-area bg-blue">
        <div className="container-fluid">
          <div className="copyright-inner pt-15">
            <div className="social-style-one mb-10">
              <a href="https://www.facebook.com/microgennhms/">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://mobile.twitter.com/microgennss">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://in.linkedin.com/company/microgennss">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://in.pinterest.com/microgennsoftwaresolutions/">
                <i className="fab fa-pinterest-p"></i>
              </a>
            </div>
            <p className="pl-10">Copyright 2024 Microgenn All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterHomeOne;
