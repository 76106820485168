import React from "react";
import Layouts from "../../helpers/LayoutsFb";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Fbcosting() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Food and Beverage Costing Software | F & B Costing system </title> */}
        <meta
          name="title"
          content="Food and Beverage Costing Software | F & B Costing system "
        />
        <meta name="description" content="Track costs, optimize recipes, and analyze menu performance with Microgenn’s F&B costing system. Recipe management, purchase requisitions, and yield analysis.
" />
        <meta name="keywords" content="Menu engineering, recipe costing, contribution margin, cost analysis, purchase requisitions, yield management, menu mix, gross profit
" />
      </Helmet>
    <Layouts
      pageTitle="F & B Costing"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "F & B Costing", path: "/Fbcosting-system" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
 