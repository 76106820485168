import React from "react";
import Layouts from "../../helpers/LayoutsDmds";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Dmds() {
  return (
    <>
       {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Digital Menu Digital System for Hotel</title> */}
        <meta
          name="title"
          content="Digital Menu Digital System for Hotel"
        />
        <meta name="description" content="Enhance customer satisfaction, boost sales, and streamline operations with Microgenn’s digital menu display system for hotel." />
        <meta name="keywords" content="Digital menu, menu display system, restaurant menu, customer satisfaction, digital signage, menu board, increase sales, easy updates, restaurant technology, visual appeal." />
      </Helmet>
    <Layouts
      pageTitle="Digital Menu Display System"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "DMDS", path: "/digital-menu-digital-system" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
