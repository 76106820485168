import React from "react";
import Layouts from "../helpers/Layouts/index";
import FeatureSection from "./FeatureSection";
import { Helmet } from 'react-helmet'; 

export default function Integration() {
  return ( 
    <>
     {/* Helmet for SEO Metadata */}
     <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>All-in-One Integration Hotel Software: Integrations, Alerts, & Management</title> */}
        <meta
          name="title"
          content="All-in-One Integration Hotel Software: Integrations, Alerts, & Management"
        />
        <meta name="description" content="Optimize hotel operations with automated communication, inventory management, financial integration, and secure door lock solutions." />
        <meta name="keywords" content="hotel software, wifi interface, e-alert SMS, e-alert email, e-alert WhatsApp, channel manager, tally interface, hotel management, automation, door lock system." />
      </Helmet>
    <Layouts
      pageTitle="Our Products"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Integrations", path: "/products/integration" },
      ]}
    >
      <FeatureSection />
    </Layouts>
    </>
  );
}
