import React from "react";
import Layouts from "../../helpers/layoutsWeb";
import MainSection from "./MainSection";

export default function Bar() {
  return (
    <Layouts
      pageTitle="Web Development"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Web Development", path: "/Webdevelopment" },
      ]}
    >
      <MainSection />
    </Layouts>
  );
}
