import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
// import product from "../../../assets/images/products/central.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li     className="active">
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Crs"
                /> */}
              </div>
              <h2>Wifi Interface Services</h2>
              <p>According to a survey over 90% of business travellers and 1 in 3 leisure travellers want Wi-Fi
                access in their rooms and a third of them wouldn’t return to a hotel without one. Wi-Fi access
                is becoming an important factor that travellers look for in a hotel. Most guests expect hotels
                to have Free Wi-Fi access in rooms. For the higher speeds, many guests who are on a
                business trip are ready to pay for the “paid access”. In recent times, guests started asking
                receptionists what the Wi-Fi password is instead of asking them whether they have Wi-Fi
                service. The guest just assumes that every hotel will provide Wi-Fi service.
                {" "}
              </p>
              <p>More than 70% of travellers use their smartphones while travelling to locate shopping areas
                and restaurants, research activities or attractions, or simply look for directions. In addition, a
                reliable internet connection will allow guests to check their emails and social media. It also
                allows them to do things they normally do at their home like stream videos, post on social
                media, watch movies, etc. Wi-Fi should be one of the amenities on the top of your mind If
                your hotel hosts events like gatherings, business meetings, marriages, debuts, etc.
              {" "}
              </p>
              <p>It is also possible to provide a feedback page that guests can easily fill out from their mobile
                devices. You can also place a link on the portal page to your business if you don’t have a
                feedback page. It gives ideas and information about what your guests liked or disliked about
                your services and finds areas where you can improve. Asking for feedback via this method is
                convenient. Using your network, you can also send them a notification so that you wouldn’t
                forget.
              {" "}
              </p>
              <p>One of the things your guests wouldn’t forget is experiencing a free and fast wireless internet
                connection while staying at your hotel. In this era, it is one of the best things to turn your

                guests into loyal customers. At the same time, as a reward for their loyalty, it is possible to
                offer returning customers access to a faster connection.
              {" "}
              </p>
              <p>Microgenn makes it possible for you to create various internet access plans and assign them
                to your guests. This makes it possible to give a faster connection to loyal guests than the one
                you’re providing first-time guests.
              {" "}
              </p>

              <p>As technology has proven to be a real game-changer in the hospitality industry. All over the
                globe, hotels are trying to enhance the customer experience by providing seamless and
                smooth internet Wi-Fi services to their valued guests. Internet is a necessity nowadays, and
                structured hotel management software ensures to give your organization a competitive edge.
                It is a smart move for any hotel to invest in a hotel Wi-Fi interface solution, as it is the
                quickest way to keep guests delighted with internet services, which in turn automatically
                boosts bottom-line results.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Multiple access methods
                </li>
                <li>Persistent connections
                </li>
                <li>Content &amp; application filtering
                </li>
                <li>Advanced Statistics</li>
                <li>Guest Portal</li>
                <li>Wi-Fi User-Authentication Page Design</li>
                <li>Dynamic Pricing</li>
                <li>Bandwidth and Traffic Management</li>
                <li>Data collection</li>
                <li>Questionnaire and surveys</li>
                <li>Social wi-fi</li>
                <li>Engagement and automated campaigns</li>
                <li>Recommended Destinations and banners</li>
                <li>MIS Reports</li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
