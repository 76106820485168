import React from "react";
import Layouts from "../../helpers/LayoutsSms";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Bar() {
  return (
    <>
     {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>SMS e-alert system for hotel management system</title> */}
        <meta
          name="title"
          content="SMS e-alert system for hotel management system  "
        />
        <meta name="description" content="Automate notifications with Microgenn’s SMS e-alert system. Ideal for hotels and organizations, it ensures instant communication with guests, staff, and more." />
        <meta name="keywords" content="Microgenn SMS alert system, hotel SMS notifications, automated SMS system, bulk SMS for hotels, guest communication, staff alerts, hotel messaging, SMS templates." />
      </Helmet>
    <Layouts
      pageTitle="E-Alert SMS"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "E-Alert SMS", path: "/sms-ealert-system" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}