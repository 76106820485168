import React from "react";

import Layouts from "../helpers/Layouts/index";
import FeatureSection from "./FeatureSection";
import { Helmet } from 'react-helmet';

export default function ServiceOne() {
  return ( 
    <>
      {/* Helmet for SEO Metadata */}
      <Helmet>
        {/* <title>All-in-One Cloud Hotel Management Software Solution | Microgenn</title>
         */}
           <meta
          name="title"
          content=" All-in-One Cloud Hotel Management Software Solution | Microgenn"
        />
        <meta name="description" content="Streamline hotel operations with Microgenn’s cloud solutions, front office management, bar management, and website builder for hotels and restaurants." />
        <meta name="keywords" content="cloud hotel software, front office, bar management, hotel website builder, restaurant website, hospitality software, Microgenn, hotel management, online presence, property management." />
      </Helmet>
    <Layouts
      pageTitle="Our Products"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Cloud", path: "/products/cloud" },
      ]}
    >
      <FeatureSection />
    </Layouts>
    </>
  );
}
