import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/mobile.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li  className="active">
                    <a href="/Webdevelopment">Web Development</a>
                  </li>
                  
                  <li>
                    <a href="/Mobile">Mobile Application</a>
                  </li>
              
                  <li>
                    <a href="/Revenue">Revenue Management</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-40">
                {/* <img
                  src={
                    product
                  }
                  alt="Web Development"
                /> */}
              </div>
              <h2>Web Development</h2>
              <p>Microgenn mobile application provides check-in and check-out, food ordering,
                instant payments, contactless room access, and direct chat with the staff. It
                makes all hotel management operations touch-free to serve guests effectively.
                {" "}
              </p>
              <p>
              This App provides a solution for hotels and restaurants to streamline internal
                operations, promote their business, and encourage increased sales and profits.
                Designed using the latest web-based technologies, the system’s friendly
                graphical user interface simplifies and streamlines common everyday tasks.
              {" "}
              </p>
              <p>The hotel management mobile app is a helpful hand for hotel owners, managers,
                and even staff to handle hotel operations on the move from their smartphones.
                Hotels should effectively be able to use a hotel management software app to perform operations from room allocation, and folio settlements to inventory
                updates on channels, without needing a PC or a laptop.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Daily, monthly, and yearly reports
                </li>
                <li>Room-wise occupancy statistics
                </li>
                <li>Room-wise collection statistics
                </li>
                <li>Day-wise occupancy/revenue statistics</li>
                <li>Cashier report and manager report</li>
                <li>Table and chart representation of details</li>
                <li>User-friendly design</li>
                <li>Separate logins for the manager and MD</li>
                <li>Guestbook-digital guest directory</li>
                <li>Automated self check-in and check-out</li>
                <li>Contactless service ordering</li>
                <li>Contact-free room access</li>
                <li>Payments and invoices</li>
                <li>Folio settlements</li>
                <li>Push notifications</li>
                <li>Reputation management</li>
                <li>Identity scanning</li>
                <li>Track audit trails</li>
                <li>Intuitive dashboard</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
