import React from "react";
import Background from "../../assets/images/counter/counter-bg.jpg";
import CounterUp from "../helpers/CounterUp";
import about from "../../assets/images/about/about.png";

function AboutSection({ className, funFact = true }) {
  return (
    <section
      className={`about-section bg-light-black pt-120 mb-200 rpt-100 ${
        className || ""
      }`}
      id="about-section "
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-image-shape rmb-70 wow fadeInLeft delay-0-2s">
              <img
                src={about}
                alt="About"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content text-white  rpr-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">What we are Specialized in?</span>
                <h2>
                  We are the experts in developing<br></br> Hotel Management Software
                </h2>
              </div>
              <p>
                {/* We are one of the leading software solutions providers in Coimbatore with 500+ happy clients.We excel in Hotel management software, Power automation services, Mobile applications, 
                Website development, SEO services or any other IT solution on our client’s wish list. */}
                 We are experts in providing tailormade simple solutions for your Hotel Bussiness.
              </p>
              <ul className="list-style-one mt-15">
                <li>Reduced Complexity</li>
                <li>All Services Under One Roof</li>
                <li>Simple Solutions</li>
                <li>Pocket Friendly Solutions</li>
                <li>User-friendly</li>
                <li>24/7 Support</li>
              </ul>
            </div>
          </div>
        </div>
        {funFact && (
          <div
            className="fact-counter-inner br-5 px-25 pt-80 pb-30 text-white text-center"
            style={{backgroundColor:"#135893"}}
            // style={{ backgroundImage: `url(${Background})` }}
          >
            <div className="row">
              {/* <div className="col-lg-2 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-2s">
                  <span
                    className="count-text"
                    data-speed="5000"
                    data-stop="520"
                  >
                    <CounterUp endValue={300} sectionSelect="about-section " />
                  </span>
                  <p className="text-center">Projects Done</p>
                </div>
              </div> */}
              <div className="col-lg-2 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-4s">
                  <span className="count-text plus">
                    <CounterUp endValue={24} sectionSelect="about-section " />
                  </span>
                  <p className="text-center">Awards Won</p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-6s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="12"
                  >
                    <CounterUp endValue={20} sectionSelect="about-section " />
                  </span>
                  <p className="text-center">Years Experience</p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-8s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="352"
                  >
                    <CounterUp endValue={550} sectionSelect="about-section " />
                  </span>
                  <p className="text-center">Happy Clients</p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-6s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="352"
                  >
                    <CounterUp endValue={3150} sectionSelect="about-section " />
                  </span>
                  <p className="text-center">Users</p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-6s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="12"
                  >
                    <CounterUp endValue={18} sectionSelect="about-section " />
                  </span>
                  <p className="text-center">Interfaces</p>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-6s">
                  <span
                    className="count-text plus"
                    data-speed="5000"
                    data-stop="12"
                  >
                    <CounterUp endValue={36} sectionSelect="about-section " />
                  </span>
                  <p className="text-center">Products</p>
                </div>
              </div>
            
              
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AboutSection;
