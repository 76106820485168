import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/gate.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li  className="active">
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Gatepass"
                /> */}
              </div>
              <h2>Gate Pass System</h2>
              <p>Microgenn gate pass module maintains and monitors gate registers for visitors,
                materials, and company vehicles. Returnable and non-returnable passes are
                provided for inward and outward movements. For returnable gate passes, the
                original gate pass transaction is taken into reference such that the pending
                returnable details are obtained from the system at any time.
                {" "}
              </p>
              <p>
              Store-issued documentation is referred to during gate pass creation when
                materials are routed through a store. Materials are dispatched from either user
                departments or material stores. A unique gate pass number sequence is created
                for inward and outward gate passes.
              {" "}
              </p>
              <p>
              Track records of the movement of customers, vendors, visitors, and sales
                personnel are maintained properly. The visitor input form consists of the
                visitor’s name, address, company details, designated contact, the purpose of
                visit, etc. Reports of material inward register, vehicle movement register,
                vehicle availability register, and pending gate pass register are provided for
                registers.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Approver workflow- once a gate pass request is raised, approval from different levels of authorities is required
                </li>
                <li>Capture material details
                </li>
                <li>Alerts &amp; reminders- mobile and email alert
                </li>
                <li>Identify each material</li>
                <li>To &amp; from reminder configuration</li>
                <li>Accuracy in gate pass management</li>
                <li>Ability to track all user logs</li>
                <li>Built-in reports- ability to search, sort, analyze and retrieve</li>
                <li>Real-time tracking</li>
                <li>Notifications about the progress of each task- email, SMS or push notification</li>
                <li>Provides returnable and non-returnable gate pass</li>
                <li>Easy integration</li>
                <li>Customizable</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
