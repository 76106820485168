import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/banquet.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li className="active">
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li>
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
             
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image ">
                {/* <img
                  src={s
                    product
                  }
                  alt="Event management"
                /> */}
              </div>
              <h2>Banquet Management System</h2>
              <p>The system provides the possibility to block a venue for wait-listed bookings
                and also for maintenance purposes. Reservation of a venue can be done with
                confirmed or tentative status. A cut-off date is defined when reservations are
                tentatively made.
                {" "}
              </p>
              <p>The system supports audit logs for reservation amendments so a detailed history
                can be maintained for all the changes that are made for a reservation. The venue
                status chart which contains venue availability is highlighted on the reservation
                screen displays for any period. It is possible to display a function catalogue,
                bills, and receipt printing functionality and function confirmation.
              {" "}
              </p>
              <p>Splitting of bills, splitting of amounts, session-based billing, and consolidated
                billing are included in the billing features. Refunds, advances, and paid-out are
                consolidated and all the sums are added to the final bill prior to settlement. The
                final bill gets posted in the accounting module of Microgenn’s system.
              {" "}
              </p>
              <p>Our Microgenn’s Banquet and event management enables the automation and
                management of a single-day event, multi-day events, or any conventions. This is
                an effective and effortless system that enables support for every phase of any
                event management process including reserving banquet halls, coordinating the
                use of resources like Catering, Audio and video equipment, personnel, real-time
                monitoring of event’s tasks status completion, etc. Our task monitoring system
                provides real-time information to manage and control different work
                departments such as dining, service, etc.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Analytics and information of Guaranteed and Expected pax.
                </li>
                <li>Booking multiple venues in a single reservation.
                </li>
                <li>Amenities of each function.
                </li>
                <li>Steward details for each function.</li>
                <li>Plan food menus.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
