import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/revenue.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                
                  <li>
                    <a href="/Webdevelopment">Web Development</a>
                  </li>
                  
                  <li>
                    <a href="/Mobile">Mobile Application</a>
                  </li>
              
                  <li    className="active">
                    <a href="/Revenue">Revenue Management</a>
                  </li>
                 
                  
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-40">
                {/* <img
                  src={
                    product
                  }
                  alt="revenue"
                /> */}
              </div>
              <h2>Revenue Management System</h2>
              <p>Microgenn&#39;s revenue management system helps to strategize your inventory by
                reasonable pricing. It allows you to assess demand, manage inventory, post
                availability with the right rates, forecast occupancy, and save hours of manual
                work. The prices on rooms can be set up using dynamic rules like lead time,
                seasonality, booking pace, day of the week, and more on a single secured cloud
                platform.
                {" "}
              </p>
              <p>Revenue management software helps hotels sell the right product to the right
                customer at the right price, as a solution for variables like price, channel, and
                timing to maximize profitability. Revenue management systems demand
                forecasts and predictive analytics to recommend rates for each customer
                segment and room type across each of the major distribution channels. It helps
                to tailor each channel on which your hotel is selling.
              {" "}
              </p>
              <p>New innovations like total revenue management and real-time pricing are
                making revenue management software smarter. Sophisticated revenue
                management tools like Microgenn offers insightful data and time-saving
                productivity tools, allowing hoteliers to increase profit, forecast more
                accurately, and run efficiently.
              {" "}
              </p>
              <p>
              A revenue management system helps hotels to determine how much to charge
                for a room per day, factoring in real-time supply and demand data, and
                balancing occupancy and ADR (average daily rate).    {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Real-Time Dynamic Pricing
                </li>
                <li>Compset Analysis
                </li>
                <li>Proactive Pricing Approach
                </li>
                <li>Micro-level Pricing Analysis</li>
                <li>Intuitive Dashboard</li>
                <li>Pick up the Report</li>
                <li>Market Insights</li>
                <li>Market Insights</li> 
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
