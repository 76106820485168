
import BacktoTopCom from "../helpers/BackToTopCom";
import BacktoTopComm from "../helpers/BackToTopComm";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne/index";
import Hero from "./Hero";
import NewSection from "./ServiceSection"
import GetInTouchSection from "./GetInTouchSection";
import { Helmet } from 'react-helmet'; 

function HomeOne() {
  return (
    <>
         {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Join Microgenn: Begin to New Heights! | MicroGenn</title> */}
        <meta
          name="title"
          content=" Join Microgenn: Begin to New Heights! | MicroGenn"
        />
        <meta name="description" content="Explore career opportunities at Microgenn and be part of our growth journey. Elevate your career with us!" />
        <meta name="keywords" content="Career opportunities, Microgenn careers, Professional development." />
      </Helmet>
    <div style={{ overflowX: "hidden" }}>
      <HeaderHomeOne />
      <Hero />
      <NewSection />
      <GetInTouchSection />
      <FooterHomeOne />
      <BacktoTopCom />
      <BacktoTopComm />
    </div>
</>  );
}

export default HomeOne;
