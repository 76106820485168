import React from "react";
import Layouts from "../../helpers/LayoutsInv";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Dmds() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Food and Beverage Costing Software | F & B Costing system (Need to include keyword in content) </title> */}
        <meta
          name="title"
          content="Food and Beverage Costing Software | F & B Costing system "
        />
        <meta name="description" content="Know how hotel inventory management system optimizes room availability, pricing, and distribution. Maximize revenue with strategic inventory practices.
" />
        <meta name="keywords" content="Inventory management software in India, Inventory Control Software, Inventory Software, Stock management software, Inventory Management, Inventory Management System." />
      </Helmet>
    <Layouts
      pageTitle="Inventory Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Inventory Management", path: "/Inventory-management-system" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
