import React from "react";
import { Link } from "react-router-dom";

function ServiceSection() {
  return (
    <section className="services-section pt-120 rpt-100 pb-90 rpb-70">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-2s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-computer"></i>
                </div>
                <h6>Front Office</h6>
                <p>
                Front office system is a primary requirement for every property..
                </p>
                <Link className="btn-circle" to="/frontoffice">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </Link>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Front office system is a primary requirement for every property..</p>
                <Link to="/frontoffice" className="theme-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web-development-4"></i>
                </div>
                <h6>Point Of Sale</h6>
                <p>
                Point of sale software is meant for fine dining outlets, Cafes, Food..
                </p>
                <Link className="btn-circle" to="/Pointofsales">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </Link>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Point of sale software is meant for fine dining outlets, Cafes, Food..</p>
                <Link to="/Pointofsales" className="theme-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-6s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web"></i>
                </div>
                <h6>Bar Management</h6>
                <p>
                Bar management is loaded with extraordinary features which..
                </p>
                <Link  to="/Bar" className="btn-circle">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </Link>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Bar management is loaded with extraordinary features which..</p>
                <Link to="/Bar" className="theme-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-data"></i>
                </div>
                <h6>Housekeeping</h6>
                <p>
                Housekeeping software makes the business process very smooth..
                </p>
                <Link className="btn-circle" to="/Housekeeping">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </Link>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Housekeeping software makes the business process very smooth..</p>
                <Link to="/Housekeeping" className="theme-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;
