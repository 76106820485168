import React from "react";
import Layouts from "../helpers/Layouts/index";
import FeatureSection from "./FeatureSection";
 import { Helmet } from 'react-helmet';

export default function Premises() {
  return ( 
    <>
    {/* Helmet for SEO Metadata */}
    <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Complete Hotel Management Software Solution | Microgenn</title> */}
        <meta
          name="title"
          content="Complete Hotel Management Software Solution | Microgenn"
        />
        <meta name="description" content="Microgenn’s Hotel Management Software offers modules for front office, POS, housekeeping, bar management, payroll, reservations, and more operations." />
        <meta name="keywords" content="hotel management software, front office, POS, bar management, housekeeping, payroll, reservations, Microgenn, hospitality solutions, hotel software, property management, automation" />
  
      </Helmet>
    <Layouts
      pageTitle="Our Products"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "On Premises", path: "/products/premises" },
      ]}
    >
      <FeatureSection />
    </Layouts>
    </>
  );
}
