import React from "react";
import Layouts from "../../helpers/LayoutsGate";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Gatepass() {
  return (
    <>
    {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Gate Pass Management System for Hotel | Gate Pass software </title> */}
        <meta
          name="title"
          content="Gate Pass Management System for Hotel | Gate Pass software "
        />
        <meta name="description" content="Monitor visitors, materials, and company vehicles with Microgenn’s gate pass management system. Returnable passes, documentation, and tracking." />
        <meta name="keywords" content="Gate pass management, visitor tracking, material dispatch, returnable passes, unique pass numbers, documentation, vehicle movement." />
      </Helmet>
    <Layouts
      pageTitle="Gate Pass"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Gate Pass", path: "/Gatepass-management-system" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
