import React from "react";
import { Link } from "react-router-dom";

function FeatureSection() {
  return (
    <section className="featured-section bgs-cover pt-150 rpt-150 pb-120 rpb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="section-title text-center mb-35">
              <span className="sub-title">One-step ideal software solution for your hotels</span>
              <h2><span id="color">Cloud</span> Products</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          
        <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-2s">
              <div className="icon">
                <i className="flaticon flaticon-art"></i>
              </div>
              <div className="feature-content">
                <h5>Front Office</h5>
                <p>Front office system is a primary requirement for every property because every multi-departmental physical business needs to have a front office ..
                </p>
                <Link to="/frontoffice" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-8s">
              <div className="icon">
                <i className="flaticon flaticon-analysis-1"></i>
              </div>
              <div className="feature-content">
                <h5>Bar Management</h5>
                <p>Bar management is loaded with extraordinary features which allow your bartenders to quickly and accurately serve customers and create a unique ..</p>
                <Link to="/bar-management-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Hotels And Restaurant Website Builder</h5>
                <p>Microgenn comprises a website that strengthens your brand&#39;s online presence and it is the first point  would ..</p>
                <Link to="/hotel-and-restaurant-website-builder" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
 
        </div>

      </div>
    </section>
  );
}

export default FeatureSection;
