import React from "react";


function Hero() {
  return (
    <section className="hero-section overlay bgs-cover pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-10 offset-1 text-justify">
            <div>
              <h3 className="text-muted">1. Terms</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  By accessing this web site, you are agreeing to be bound by
                  these web site Terms and Conditions of Use, applicable laws
                  and regulations and their compliance. If you disagree with any
                  of the stated terms and conditions, you are prohibited from
                  using or accessing this site. The materials contained in this
                  site are secured by relevant copyright and trade mark law.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">2. Use License</h3>
              <ul className="pb-25 ">
                <li className="pl-30 pt-5">
                  Permission is allowed to temporarily download one duplicate of
                  the materials (data or programming) on microgenn Software
                  Solutions’s site for individual and non-business use only.
                  This is the just a permit of license and not an exchange of
                  title, and under this permit you may not.
                  <ul className="list-style-three pt-15 pb-30">
                    <li>modify or copy the materials;</li>
                    <li>
                      use the materials for any commercial use , or for any
                      public presentation (business or non-business);
                    </li>
                    <li>
                      attempt to decompile or rebuild any product or material
                      contained on microgenn Software Solutions’s site;
                    </li>
                    <li>
                      remove any copyright or other restrictive documentations
                      from the materials; or
                    </li>
                    <li>
                      transfer the materials to someone else or even “mirror”
                      the materials on other server.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">3. Disclaimer</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  The materials on microgenn Software Solutions’s site are given
                  “as is”. microgenn Software Solutions makes no guarantees,
                  communicated or suggested, and thus renounces and nullifies
                  every single other warranties, including without impediment,
                  inferred guarantees or states of merchantability, fitness for
                  a specific reason, or non-encroachment of licensed property or
                  other infringement of rights. Further, microgenn Software
                  Solutions does not warrant or make any representations
                  concerning the precision, likely results, or unwavering
                  quality of the utilization of the materials on its Internet
                  site or generally identifying with such materials or on any
                  destinations connected to this website.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">4. Constraints</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  In no occasion should microgenn Software Solutions or its
                  suppliers subject for any harms (counting, without constraint,
                  harms for loss of information or benefit, or because of
                  business interference,) emerging out of the utilization or
                  powerlessness to utilize the materials on microgenn Software
                  Solutions’s Internet webpage, regardless of the possibility
                  that microgenn Software Solutions or a microgenn Software
                  Solutions approved agent has been told orally or in written of
                  the likelihood of such harm. Since a few purviews don’t permit
                  constraints on inferred guarantees, or impediments of
                  obligation for weighty or coincidental harms, these
                  confinements may not make a difference to you.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">5. Amendments and Errata</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  The materials showing up on microgenn Software Solutions’s
                  site could incorporate typographical, or photographic
                  mistakes. microgenn Software Solutions does not warrant that
                  any of the materials on its site are exact, finished, or
                  current. microgenn Software Solutions may roll out
                  improvements to the materials contained on its site whenever
                  without notification. microgenn Software Solutions does not,
                  then again, make any dedication to update the materials.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">6. Links</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  microgenn Software Solutions has not checked on the majority
                  of the websites or links connected to its website and is not
                  in charge of the substance of any such connected webpage. The
                  incorporation of any connection does not infer support by
                  microgenn Software Solutions of the site. Utilization of any
                  such connected site is at the user’s own risk.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">7. Site Terms of Use Modifications</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  microgenn Software Solutions may update these terms of
                  utilization for its website whenever without notification. By
                  utilizing this site you are consenting to be bound by the then
                  current form of these Terms and Conditions of Use.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">8. Governing Law</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  Any case identifying with microgenn Software Solutions’s site
                  should be administered by the laws of the country of India
                  microgenn Software Solutions State without respect to its
                  contention of law provisions.
                </li>
                <li>
                  General Terms and Conditions applicable to Use of a Web Site.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
