import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/crm.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li>
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar</a>
                  </li>
                  <li>
                    <a href="/Webdevelopment">Web Development</a>
                  </li>
                  <li>
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Banquet">Banquet/Event Management</a>
                  </li>
                  <li>
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  
              
                  <li>
                    <a href="/Revenue">Revenue Management</a>
                  </li>
                 
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>
                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                 
                  <li   className="active">
                    <a href="/Crm">CRM</a>
                  </li>
                  
                  <li>
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li>
                    <a href="/Tally">Tally Interface</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>
                  <li>
                    <a href="/Leave">Leave Management</a>
                  </li>
                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>
                  
                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li>
                  <li>
                    <a href="/Asset">Asset Management</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
               // style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-40">
                {/* <img
                  src={
                    product
                  }
                  alt="Frontoffice"
                /> */}
              </div>
              <h2>CRM (Customer Relationship Management)</h2>
              <p>Microgenn understands that for every business to be successful, customers are
                the key, and it is important how you manage your customers. Microgenn’s
                CRM is an intelligent and all-inclusive solution that is a web-based and
                groupware system. It is platform-independent and built on state-of-the-art
                technologies. Mainly for small and medium businesses, it is enterprise-ready
                software. It is designed to eliminate the excessive use of paper and to make it a
                paperless, productive, and fast-paced environment.
                {" "}
              </p>
              <p>
               Microgenn’s CRM manages, maintains, and grows your customer database with
                a click. You can create more opportunities for client communication and also
                generate more leads and conversions. It is an automated sales force management
                application. The application handles everything from customer support,
                services, and marketing to reporting with ease and speed.
              {" "}
              </p>
              <p>
              It helps keep track of your guests and make use of all the key information like-
                personal preferences of regular clients, dietary restrictions, notes on
                housekeeping, and information on allergies to provide the finest experience by
                personalizing your guest’s stay.
              {" "}
              </p>
              <p>It offers enterprise-grade business productivity-enhancing CRM modules such
                as a customer portal, based on your requirement. E-calendaring and E-mail
                integration features engage your customers actively. As a single platform and
                being able to manage large chunks of data, you can immediately cut the excess
                costs of managing different software for different tasks. An extensive CRM
                solution is required for any given business. Microgenn’s CRM can be
                customized to suit your business needs. {" "}
              </p>
              <h3>Features</h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Single platform to manage the company’s internal processes.
                </li>
                <li>Sales force automation
                </li>
                <li>On-premise CRM
                </li>
                <li>Customizable software features</li>
                <li>CRM Call Centre</li>
                <li>Business intelligence CRM</li>
                <li>Low CRM costs</li>
                <li>iPad CRM supported</li>
                <li>Actively tracks and manages customer information</li>
                <li>Connects tracks and manages customer information</li>
                <li>Intelligently captures customer emails</li>
                <li>Simplifies repetitive tasks so you can concentrate on leads</li>
                <li>Delivers instant insights and recommendations</li>
                <li>Extends and customizes as your business grows</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
