import React from "react";
import Layouts from "../../helpers/LayoutsSwiggy";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Swiggy() {
  return (
    <>
        {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Swiggy POS Integration for Restaurant Billing</title> */}
        <meta
          name="title"
          content="Swiggy POS Integration for Restaurant Billing "
        />
        <meta name="description" content="MicroGenn offer Swiggy Integration POS, Billing Software For Restaurant to manage online orders from the MicroGenn Restaurant POS." />
        <meta name="keywords" content="Swiggy integration, Online order management, POS Application, CRM Integration." />
      </Helmet>
    <Layouts
      pageTitle="Swiggy Online Order "
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Swiggy Online Order", path: "/swiggy-pos-integration" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
