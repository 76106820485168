import React from "react";
import Layouts from "../../helpers/Layouts";
import MainSection from "./MainSection";


export default function Kot() {
  return (
    <Layouts
      pageTitle="Tab-based KOT System"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Tab-based KOT System", path: "/Kot" },
      ]}
    >
      <MainSection />
    </Layouts>
  );
}
