import React from "react";
import Layouts from "../../helpers/Layouts/index";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Blog() {
  return (
<>
    {/* Helmet for SEO Metadata */}
    <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Top Advantages of Hotel Management Software for Small Hotels
        </title> */}
       <meta
          name="title"
          content="Top Advantages of Hotel Management Software for Small Hotels"
        />
        <meta name="description" content=" Learn how hotel management software enhances small hotel operations, from reservations to housekeeping, boosting efficiency, guest experience, and profitability.

" />
        <meta name="keywords" content="Hotel management software, small hotels, guest experience, reservation management, housekeeping efficiency, channel management, financial management, hospitality technology, HMS

" />
      </Helmet>

   

    <Layouts
      pageTitle="Blog"
      breadcrumbs={[
        { name: "home", path: "/" },
        { name: "blog", path: "/blog/top-advantages-of-using-hotel-management-software-for-small-hotels" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}
