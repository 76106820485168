import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/housekeeping.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  className="active">
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li>
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li>
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li>
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-30">
                {/* <img
                  src={
                    product
                  }
                  alt="Frontoffice"
                /> */}
              </div>
              <h2>House Keeping Software</h2>
              <p>A hospitality environment is judged by its appearance; checking whether the
                hotel rooms are maintained properly and the linens are tidy. Bad housekeeping
                will be a total put-off for the customers and the rating of the hotel will go down.
                {" "}
              </p>
              <p>This is the major reason why owners of a hotel or facility pay special attention
                to housekeeping activities. A good software system is essential to record and
                monitor guest requests, complaints, and suggestions. With the best
                housekeeping module, it is possible to block rooms for maintenance and repairs.
              {" "}
              </p>
              <p>The rooms that are blocked are reflected in the room availability chart using the
                drill-down feature. The dirty rooms are automatically tagged as dirty rooms,
                once the guest checks out. The lost and found feature is enabled to track all the
                delivery statuses and to send alerts and reminders to the guest. Laundry
                management options are available for launderer receipts, tracking, and issues.
              {" "}
              </p>
              <p>Microgenn’s Housekeeping software will manage all your maids, their personal
                and professional details, their availabilities, specialties, schedules, and many
                more. This software works in complete sync with the front office and
                effectively improves service efficiency and completely reduces the paperwork.
                It has an intuitive dashboard with the current room status information. It allows
                you to assign rooms to your housekeeping staff based on blocks/floors or sort
                them based on their status to manage the division of work.
                {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Track tasks for timely completion
                </li>
                <li>Avoid work duplication
                </li>
                <li>Avoid incorrect check-in of rooms under maintenance
                </li>
                <li>Automate room status</li>
                <li>Guest Amenities Consumption</li>
                <li>Track your Outside Launderer stock</li>
                <li>Room-wise stock</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
