import React from "react";
import Layouts from "../../helpers/Layoutsdoor";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Doorlock() {
  return (
    <>
       {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Wireless Electronic Door Lock Interface System</title> */}
        <meta
          name="title"
          content="Wireless Electronic Door Lock Interface System"
        />
        <meta name="description" content="Boost guest safety with wireless electronic door locks. Keycards and mobile credentials streamline check-in, reduce costs, and improve security." />
        <meta name="keywords" content="Door lock system, Wireless locks, Security technology, Guest safety." />
      </Helmet>
    <Layouts
      pageTitle="Door Lock"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Door Lock Interface", path: "/wireless-door-lock" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
