import React from "react";
import Layouts from "../../helpers/LayoutsClub";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Member() {
  return (
    <>
        {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Club Management Software for Hotel | Member Management</title> */}
        <meta
          name="title"
          content="Club Management Software for Hotel | Member Management "
        />
        <meta name="description" content="Effortlessly manage your club with Microgenn’s customizable software. Simplify payments, inventory, and employee tasks. Ideal for sports clubs." />
        <meta name="keywords" content="club management software, member management, payment management, revenue management, inventory management, employee management, sports clubs, fitness clubs, golf clubs, health clubs." />
      </Helmet>
    <Layouts
      pageTitle="Member Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Member Management", path: "/club-member-management-software" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
