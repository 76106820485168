import React from "react";
import Layouts from "../../helpers/LayoutsT";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Frontoffice() {
  return (
    <>
        {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Task Management System for Hotel | Task Management Software </title> */}
        <meta
          name="title"
          content="Task Management System for Hotel | Task Management Software "
        />
        <meta name="description" content="Microgenn task management system module automates task handling, complaint escalation, and request resolution, ensuring efficient service, guest satisfaction." />
        <meta name="keywords" content="Task management, complaint handling, guest satisfaction, Microgenn, automated tasks, complaint escalation, staff performance, request handling, hotel management." />
      </Helmet>
    <Layouts
      pageTitle="Task Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Task Management", path: "/Task" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
