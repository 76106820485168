import React from "react";
import Layouts from "../../helpers/LayoutsSelf";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Self() {
  return (
    <>

       {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>QR-based ordering system for restaurant</title> */}
        <meta
          name="title"
          content="QR-based ordering system for restaurant"
        />
        <meta name="description" content="Revolutionize your restaurant with a QR code-based self-ordering system. Offer contactless menu access, order placement, and payment from customers mobile devices." />
        <meta name="keywords" content="QR-based ordering system for restaurant, QR code menu, contactless ordering, dine-in safety, mobile menu, restaurant technology." />
      </Helmet>
    <Layouts
      pageTitle="Self-ordering restaurant QR code based"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Self-ordering restaurant QR code based", path: "/qr-based-ordering-system" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
