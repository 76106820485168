import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/linen.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li className="active">
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li>
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li>
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Linen Management"
                /> */}
              </div>
              <h2>Linen Management Software</h2>
              <p>Microgenn linen management module helps to take it to next level. Tagging and
                tracking customers’ garments help to run in a hassle-free way. The expenses for
                linen management are maintained properly and a detailed report of daily
                account settlement is provided.
                {" "}
              </p>
              <p>Generating monthly bills will keep the finances on track with the help of digital
                and online channel payment, receiving bills and making payments is made easy.
                Streamline communication with hotels is made possible via seamlessly
                integrating SMS, Email and WhatsApp services. By using our AI-powered and
                user-friendly software, providing clean linens to hotels on a timely basis has
                been made simple.
              {" "}
              </p>
              <p>It is a comprehensive management software solution for commercial and
                industrial laundries serving the hotel industry. The software’s findings are
                displayed on a screen so that a person can immediately direct the linen to its
                next destination. Control over stock allows laundries to understand their
                customers and how they are using linen, and also customers want a clearer view
                of the service they receive.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Effortless identification, location, and tracking of items
                </li>
                <li>Deliveries and collections are counted quickly and accurately
                </li>
                <li>Reduces manual labor, leading to overall time and cost savings
                </li>
                <li>Easy view of the inventory &amp; hassle-free management</li>
                <li>Increased accuracy in check-in and check-out services</li>
                <li>Provide accurate wash control records and many other set parameters details</li>
                <li>Reduce losses and theft instances, also help identify lost linen items</li>
                <li>Keeps stock rotation on track to keep the quality and freshness of the linen at the highest</li>
                <li>Staff have peace of mind, knowing their deliveries and stock is accounted for</li>
                <li>Shipping &amp; Receiving Management</li>
                <li>Billing Invoices &amp; Other Financials</li>
                <li>Order Management &amp; Linen Rental</li>
                <li>Pickup &amp; Deliveries Management</li>
                <li>Rewash, reject, Reclaim and Discard Tracking</li>
                <li>Route Management &amp; Optimization</li>
                <li>Customized Production and Financial Reporting</li>
                <li>PPOH and Labour Cost Tracking</li>
                <li>Driver&#39;s App</li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
