import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/asset.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li   className="active">
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Asset Management"
                /> */}
              </div>
              <h2>Inventory Management System</h2>
              <p>Hotel inventory management is the process of ordering, storing, and distributing a company’s
                finished goods or its current assets, which includes processing and managing of finished
                goods, components and raw materials. It ensures that the company keeps enough stock to
                meet customer demands. Rooms are the primary inventory in the hotel industry. Hoteliers
                ensure that if the rooms are not at a competitive price, it remains unoccupied which in turn
                decreases revenue. The supplies for guest rooms, gift shops and restaurants are the secondary
                inventory, with sufficient stocks like towels, blankets, toiletries and other items. If inventory
                management is not proper, it causes property owners to lose money, as they won’t be able to
                sell their rooms.
                {" "}
              </p>
              <p>Microgenn inventory management system ensures that the hotels have the right-priced rooms
                and products for guests. It helps hoteliers identify the most popular services and products. It
                will help develop marketing campaigns by hotel owners based on their popular rooms and
                highest-selling products. Microgenn inventory management helps keep track of the ordered
                and in-stock products and identify how much stock is available. This helps reduce
                overstocking of products.
              {" "}
              </p>
              <p>Based on the industry, inventory management differs, for hoteliers, it helps to create and
                manage demand. To maximize bookings during the targeted period, hoteliers must use room
                inventory strategies to identify their channels, which in turn reduces all associated costs. To
                build an inventory strategy, your business should collect accurate data, that helps hoteliers
                identify why the demand falls or rises throughout the travel season. It also helps companies
                develop room inventory strategies for targeting customers during peak periods. Reducing
                room prices during the off-season and raising them during peak periods will maximize the
                ROI, it also provides customers with discounts and incentives at different times of the year.
              {" "}
              </p>
              <p>
              The use of a market segmentation strategy will help hoteliers to sell hotel rooms at different
              prices. Automated hotel inventory management has real time tracking features for distributing
              rooms efficiently. Manually entering inventory will increase errors. Using a software will
              help reduce the mistakes and manage multiple booking channels. When a room is booked, the
              automated software will prevent overbooking and decrease the total inventory on all sites. It
              will alert when running low on a certain inventory. Analytics helps to view the packages and
              rooms that are popular among guests.
              {" "}
              </p>
              <p>
              Data collection will help businesses identify weaknesses in their marketing plans can be
              identified using data collection. Aggregation of data is possible using inventory management
              and PMS. It helps identify which channel is more profitable and makes certain adjustments to
              the advertising strategy.
                {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Centralized inventory
                </li>
                <li>Product Listing
                </li>
                <li>Item kitting
                </li>
                <li>Use data to support buying decisions</li>
                <li>Accurately forecast your high-demand period</li>
                <li>Allows hotels to segment target markets</li>
                <li>Improves accuracy</li>
                <li>Saves time and money</li>
                <li>Develop targeted promotions and practices</li>
                <li>Identify the most profitable items</li>
                <li>Use space more efficiently</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
