import React from "react";
import Layouts from "../../helpers/LayoutsTravel";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Travel() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Hotel Travel Desk Services | MicroGenn</title> */}
        <meta
          name="title"
          content="Hotel Travel Desk Services | MicroGenn"
        />
        <meta name="description" content="Create memorable stays with a travel desk that helps guests plan their itinerary, ensuring a remarkable experience with local insights and personalized service.
" />
        <meta name="keywords" content="Hotel travel desk, guest experience, itinerary planning, hotel amenities, hospitality service, guest satisfaction, local attractions, hotel services, travel planning, memorable stays." />
      </Helmet>
    <Layouts
      pageTitle="travel Desk"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Travel Desk", path: "/Travel" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
