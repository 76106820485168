import React from "react";
import Layouts from "../../helpers/LayoutsEmail";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Email() {
  return (
    <>
        {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>E-Alert Email | Email Notification Software for Hotels</title> */}
        <meta
          name="title"
          content="E-Alert Email | Email Notification Software for Hotels"
        />
        <meta name="description" content="Boost sales with customized email alerts notification software for hotel bookings, invoices etc. Streamline communication and attract more guests." />
        <meta name="keywords" content="Email notification software, Hotel email alerts, Push notifications for hotels, Hotel management software, Hotel service notifications" />
      </Helmet>
    <Layouts
      pageTitle="E-Alert Email"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "E-Alert Email", path: "/email-notification-software" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
