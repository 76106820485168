import React from "react";
import Layouts from "../../helpers/LayoutA";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Asset() {
  return (
    <>
        {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Asset Tracking | Assest Management for hotel| MicroGenn </title> */}
        <meta
          name="title"
          content="Asset Tracking | Assest Management for hotel| MicroGenn "
        />
        <meta name="description" content="Find how Microgenn’s hotel asset management tools enhance resource tracking, minimize costs, and optimize asset usage. Improve your hotel operations today!" />
        <meta name="keywords" content="Hotel asset management, asset tracking, resort asset management, minimize costs, asset maintenance, digital asset management." />
      </Helmet>
    <Layouts
      pageTitle="Asset Tracking"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Asset Tracking", path: "/assest-management" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
