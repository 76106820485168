import React, { useEffect } from "react";
import BackToTop from "../../lib/BackToTop";
import logo from "../../assets/images/logos/wlogo.jpg";

function BacktoTopCom({ className }) {
  useEffect(() => {
    BackToTop(".scroll-topp");
  });
  return ( 
    <button
      type="button"
      className={`scroll-topp scroll-to-target ${className || ""}`}
    >
      <a href=" https://wa.me/+919952511119" target={"_blank"}><img src={logo} style={{ borderRadius:50}}></img></a>
      {/* <span className="fa fa-angle-up"></span> */}
    </button>

  
  );
}

export default BacktoTopCom;
