import React from "react";

function Hero() {
  return (
    <section className="hero-section overlay bgs-cover pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-10 offset-1 text-justify">
            <div>
              <h3 className="text-muted">Shipping and Delivery Policy</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  This Shipping and Delivery Policy outlines the terms and
                  conditions related to the shipping and delivery of physical
                  items associated with the purchase of [Software Product Name]
                  (the “Product”). By making a purchase and agreeing to the
                  terms of this Shipping and Delivery Policy, you acknowledge
                  and accept the practices described herein. If you do not agree
                  with these terms, please refrain from making a purchase.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-muted">Shipping and Handling</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  We offer shipping services for specific physical items that
                  may be bundled with the purchase of the Product. Shipping and
                  handling fees are outlined during the checkout process and are
                  based on the destination and selected shipping method.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">Delivery Timeframes</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  <strong>Estimated Delivery Time:</strong>The estimated
                  delivery timeframe will be provided during the checkout
                  process. Please note that this timeframe is an estimate and
                  actual delivery times may vary based on factors such as
                  shipping carrier performance, customs clearance (for
                  international shipments), and unforeseen delays.
                </li>
                <li>
                  <strong>Delivery Tracking :</strong>Once your order has been
                  shipped, you will receive a shipping confirmation email
                  containing tracking information, if applicable. You can use
                  this information to track the progress of your shipment.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">Shipping Address</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  Please ensure that the shipping address provided during the
                  checkout process is accurate and complete. We are not
                  responsible for any delays or issues that may arise due to
                  incorrect or incomplete address information.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">International Shipments</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  For international shipments, please be aware that customs and
                  import duties may apply, and these charges are the
                  responsibility of the recipient. Customs procedures may also
                  impact the delivery timeframe.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">Lost or Damaged Shipments</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  In the event that your shipment is lost, damaged, or delayed,
                  please contact our customer support team at [Customer Support
                  Email]. We will work with the shipping carrier to address the
                  issue and provide assistance.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">Return Policy for Physical Items</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  Please refer to our Return Policy for information regarding
                  returns and exchanges of physical items.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-muted">Contact Us</h3>
              <ul className="list-group list-group-flush list-style-three pt-15 pb-30">
                <li>
                  if you have questions about this Shipping and Delivery Policy
                  or if you require assistance with a shipment, please contact
                  our customer support team at info@microgenn.com ||
                  +91-9790090010.
                </li>
                <li>
                  {" "}
                  Changes to this Policy We may update this Shipping and
                  Delivery Policy to reflect changes in our practices or legal
                  obligations. Any updates will be effective upon posting on our
                  website.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
