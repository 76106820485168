import React from "react";
import Layouts from "../../helpers/LayoutsBar";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Bar() {
  return (
    <>
    {/* Helmet for SEO Metadata */}
    <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Bar Management System | MicroGenn </title>
         */}
           <meta
          name="title"
          content="Bar Management System | MicroGenn"
        />
        <meta name="description" content="Manage your bar with Microgenn's bar management system. Reduce waste, optimize inventory, and enhance customer service with advanced analytics and automation." />
        <meta name="keywords" content="bar management software, inventory control, bar analytics, waste reduction, liquor management, bartender efficiency, bar operations, hospitality software, bar optimization, spirit control." />
      </Helmet>
    <Layouts
      pageTitle="Bar Management system"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Bar Management System", path: "/bar-management-system" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}
