import React from "react";
import Layouts from "../helpers/Layouts";
import Hero from './Hero';

export default function Shipping() {
  return (
    <>
      <Layouts
        pageTitle="Shipping & Delivery Policy"
        breadcrumbs={[
          { name: "home", path: "/" },
          { name: "Shipping & Delivery Policy", path: "/Shipping" },
        ]}
      >
       <Hero />  
      </Layouts>
    </>
  );
}
