import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/hotelc.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="#">Wifi Interface</a>
                  </li>
                <li >
                    <a href="/Sms">E-Alert SMS</a>
                  </li>
                  <li>
                    <a href="/Email">E-Alert Email</a>
                  </li>
                  <li  >
                    <a href="/Whatsapp">E-Alert WhatsApp</a>
                  </li>
                  
                  <li className="active">
                    <a href="/Hotelchannel">Hotel Channel Manager</a>
                  </li>
                
                  
                  <li>
                    <a href="/Tally">Tally Interface</a>
                  </li>

                  <li >
                    <a href="#">Door lock</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-30">
                {/* <img
                  src={
                    product
                  }
                  alt="hotel channel"
                /> */}
              </div>
              <h2>Hotel Channel Manager  | Global Distribution System (GDS)</h2>
              <p>Microgenn&#39;s channel manager provides help to hotels for easily managing
                inventory and property rates on various travel websites/channels. It instantly
                updates all the minor and major changes made on the client&#39;s end to all the
                channels automatically.
                {" "}
              </p>
              <p>Our software enables a pathway to access without any hassles via smartphones
                or tablets from anywhere and at any time. Connecting to the global distribution
                system (GDS), ensures that rooms are distributed globally.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Single-point dashboard for managing distribution channels
                </li>
                <li>Instant automatic update
                </li>
                <li>Yield Management
                </li>
                <li>Maintain rate parity without any effort</li>
                <li>Rate spider - Monitor your competitor&#39;s rates</li>
                <li>Real Time Updates</li>
                <li>Exclusive Account Manager</li>
                <li>100+ Travel Booking Channels</li>
                <li>Mobile Channel Manager</li>
                <li>And More</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
