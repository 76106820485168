import React from "react";
import { Link } from "react-router-dom";

export default function WorkprocessSection() {
  return (
    <section className="work-progress-two mb-180">
      <div className="container"> 
        <div className="work-progress-inner-two">
          <div className="row ">
            <div className="col-lg-2 col-sm-6 p-1">
              <div className="progress-item-two wow fadeInUp delay-0-2s">
                <div className="icon">
                  <i className="flaticon flaticon-speech-bubble"></i>
                </div>
                <h3> 
                Website <br />
                Development 
                </h3>
                <p>
                Microgenn comprises a website that strengthens your brand&#39;s 
                online presence ..
                </p>
                <Link to="/Webdevelopment" className="learn-more" >Read More <i className="fas fa-arrow-right"></i></Link>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 p-1">
              <div className="progress-item-two wow fadeInUp delay-0-4s">
                <div className="icon">
                  <i className="flaticon flaticon-vector"></i>
                </div>
                <h3>
                Mobile <br />
                Application
                </h3>
                <p>
                Microgenn mobile application provides check-in and check-out,
                 food ordering, instant and ..
                </p>
                
                <Link to="/Mobile" className="learn-more" >Read More <i className="fas fa-arrow-right"></i></Link>
              
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 p-1">
              <div className="progress-item-two wow fadeInUp delay-0-6s ">
                <div className="icon">
                  <i className="flaticon flaticon-like-1"></i>
                </div>
                <h3>
                Revenue <br />
                Management
                </h3>
                <p>
                A revenue management system helps hotels to 
                determine how much to charge  ..
                </p>
                
                <Link to="/Revenue" className="learn-more" >Read More <i className="fas fa-arrow-right"></i></Link>
               
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 p-1">
              <div className="progress-item-two wow fadeInUp delay-0-6s">
                <div className="icon">
                  <i className="flaticon flaticon-speech-bubble"></i>
                </div>
                <h3>
                
                E-Alert <br />
                Whatsapp
                </h3>
                <p>
                In recent times WhatsApp business for hotels gained good traction in recent times..
                </p>
                
                <Link to="/Whatsapp" className="learn-more" >Read More <i className="fas fa-arrow-right"></i></Link>
               
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 p-1">
              <div className="progress-item-two wow fadeInUp delay-0-6s">
                <div className="icon">
                  <i className="flaticon flaticon-vector"></i>
                </div>
                <h3>
                
                E-Alert <br />
                SMS
                </h3>
                <p>
                Automated communication with customers, volunteers, and members facilitates..
                </p>
               
               
                <Link to="/Sms" className="learn-more" >Read More <i className="fas fa-arrow-right"></i></Link>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 p-1">
              <div className="progress-item-two wow fadeInUp delay-0-6s no-border mb-0">
                <div className="icon">
                  <i className="flaticon flaticon-like-1"></i>
                </div>
                <h3>
                
                E-Alert <br />
                Email
                </h3>
                <p>
                Email is one of the most widely used communication channels in the corporate ..
                </p>
                

                <Link to="/Email" className="learn-more" >Read More <i className="fas fa-arrow-right"></i></Link>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
