import { useState } from "react";
import { Link } from "react-router-dom";
//import logo from "../../../assets/images/logos/logo-three.png";
import "./style.css";

function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      if (item !== value) {
        setSize(`${50 * getItems}px`);
        setItem(value);
      } else {
        setItem("");
      }
    }
  };
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <a
                  href="/"
                  className="offcanvas-brand text-center mb-40 d-block"
                >
                  {/*}<img src={} alt="" />{*/}
                </a>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li
                      onClick={(e) => handler(e, "home")}
                      id="home"
                      className="menu-item-has-children active"
                    >
                     {/*}  <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>{*/}
                      <Link to="/">Home</Link>
                 {/*}     <ul
                        className="sub-menu"
                        style={{
                          height: item === "home" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/">Home 1</Link>
                        </li>
                        <li>
                          <Link to="/home-two">Home 2</Link>
                        </li>
                        <li>
                          <Link to="/home-three">Home 3</Link>
                        </li>
                        <li>
                          <Link to="/home-four">Home 4</Link>
                        </li>
                        <li>
                          <Link to="/home-five">Home 5</Link>
                        </li>
                        <li>
                          <Link to="/home-six">Home 6</Link>
                        </li>
                        <li>
                          <Link to="/home-seven">Home 7</Link>
                        </li>
                        <li>
                          <Link to="/home-eight">Home 8</Link>
                        </li>
                        <li>
                          <Link to="/home-nine">Home 9</Link>
                        </li>
                      </ul> {*/}
                    </li>
                    <li
                      onClick={(e) => handler(e, "about")}
                      id="about"
                      className="menu-item-has-children active"
                    >
                     {/*} <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                    </span>{*/}
                      <Link to="/about-us">About</Link>
                    {/*}   <ul
                        className="sub-menu"
                        style={{
                          height: item === "about" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/about-us">About 1</Link>
                        </li>
                        <li>
                          <Link to="/about-two">About 2</Link>
                        </li>
                        <li>
                          <Link to="/about-three">About 3</Link>
                        </li>
                        <li>
                          <a href="/about-four">About four</a>
                        </li>
                        <li>
                          <a href="/about-five">About five</a>
                        </li>
                        <li>
                          <a href="/about-six">About Six</a>
                        </li>
                        <li>
                          <a href="/about-seven">About Seven</a>
                        </li>
                      </ul>{*/}
                    </li>
                    <li
                      onClick={(e) => handler(e, "service")}
                      id="service"
                      className="menu-item-has-children active"
                    >
                      {/*} <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                    </span>{*/}
                      <Link to="/services">Service</Link>
                      {/*} <ul
                        className="sub-menu"
                        style={{
                          height: item === "service" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <a href="/service-one">Service One</a>
                        </li>
                        <li>
                          <a href="/service-two">Service Two</a>
                        </li>
                        <li>
                          <a href="/service-three">Service Three</a>
                        </li>
                        <li>
                          <a href="/service-four">Service Four</a>
                        </li>
                        <li>
                          <a href="/service-five">Service five</a>
                        </li>
                        <li>
                          <a href="/service-six">Service Six</a>
                        </li>
                        <li>
                          <a href="/service-seven">Service Seven</a>
                        </li>
                        <li>
                          <a href="/service-details">Service Details</a>
                      </li>
                      </ul>{*/}
                    </li>
                    <li
                      onClick={(e) => handler(e, "portfolio")}
                      id="portfolio"
                      className="menu-item-has-children active"
                    >
                       <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                    </span>
                      <Link to="#">Products</Link>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "portfolio" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <Link to="/products/premises">On premises</Link>
                        </li>
                        <li>
                          <Link to="/products/cloud">Cloud</Link>
                        </li>
                        <li>
                          <Link to="/products/integration">Integrations</Link>
                        </li>
                        <li>
                          <a href="https://www.hotelsmartlock.com/" target="_blank" rel="noopener noreferrer">Hotel Locks</a>
                        </li>
                        
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "Pages")}
                      id="Pages"
                      className="menu-item-has-children active"
                    >
                  
                      <Link to="/partner">Partners</Link>
                    
                    </li>
                    <li
                      onClick={(e) => handler(e, "Blogs")}
                      id="Blogs"
                      className="menu-item-has-children active"
                    >
                     {/*} <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>{*/}
                      <Link to="/careers">Careers</Link>
                     
                    </li>
                    <li
                      onClick={(e) => handler(e, "contact")}
                      id="contact"
                      className="menu-item-has-children active"
                    >
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a href="https://www.facebook.com/microgennhms/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://mobile.twitter.com/microgennss">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://in.linkedin.com/company/microgennss">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://in.pinterest.com/microgennsoftwaresolutions/">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget-info">
                  <ul>
                    <li>
                      <a href="mailto:info@microgenn.com">
                        <i className="fa fa-envelope"></i>info@microgenn.com
                      </a>
                    </li>
                    <li>
                      <a href="callto:+91-9566411119">
                        <i className="fa fa-phone"></i> +91-9566-411119
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-map-marker-alt"></i>  #284, 284, Peranaidu Layout, 
                        Ram Nagar, Coimbatore - 641009.
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
