import React from "react";
import FeatureSection from "../../ServicePages/ServiceTwo/FeatureSection";
import Layouts from "../../helpers/Layouts";
import WorkprocessSection from "../../HomeTwo/WorkprocessSection";
import { Helmet } from 'react-helmet';


export default function ServiceTwo() {
  return (
<>
      {/* Helmet for SEO Metadata */}
      <Helmet>
  
        {/* <title>Hotel Management Software Solutions | Microgenn</title> */}
        <meta
          name="title"
          content="Hotel Management Software Solutions | Microgenn"
        />
        <meta name="description" content="Enhance your brand with Microgenn's web development, mobile apps, revenue management, and automated communication via WhatsApp, SMS, and Email." />
        <meta name="keywords" content="website development, mobile applications, revenue management, WhatsApp business, SMS alerts, email communication, brand enhancement, IT solutions, Microgenn, online presence." />
  
      </Helmet>
    <Layouts
      pageTitle="Our Service"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Our Service", path: "/services" },
      ]}
    >
      <FeatureSection/>
      <WorkprocessSection />
    </Layouts>
    </>
  );
}
