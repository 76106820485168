import React from "react";
import Layouts from "../../helpers/LayoutsZomato";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Zomato() {
  return (
    <>
        {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Zomato POS Integration for Restaurant Billing</title> */}
        <meta
          name="title"
          content="Zomato POS Integration for Restaurant Billing"
        />
        <meta name="description" content="MicroGenn offer Zomato Integration POS, Billing Software For Restaurant to manage online orders from the MicroGenn Restaurant POS." />
        <meta name="keywords" content="Zomato integration, Online order management, POS Application, CRM Integration." />
      </Helmet>
    <Layouts
      pageTitle="Zomato Online Order "
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Zomato Online Order", path: "/zomato-pos-integration" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
