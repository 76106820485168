import React from "react";
import background from "../../../assets/images/banner.jpg";

export default function Hero({ pageTitle, breadcrumbs = [] }) {
  return (
    <section
      className="page-banner bgs-cover overlay pt-50"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="banner-inner">
          <h1 className="page-title">{pageTitle}</h1>
          <p className=" col-8 text-light " id="Partner">Microgenn Software Solution has partnered with over 200 partners, enabling businesses to achieve their goals with the assistance of our guidance, 
            creating a community focused on progress. Join our group of regional partners and grow your business with opportunities from the best.</p>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {breadcrumbs &&
                breadcrumbs.length > 0 &&
                breadcrumbs.map((item) => (
                  <li key={Math.random()} className="breadcrumb-item">
                    <a href={item.path}>{item.name}</a>
                  </li>
                ))}
            </ol>
          </nav> 
          <a className="theme-btn btn-circle" href="#p_page">
                Become a Partner
              </a>                                                   
        </div>
        <div></div>
      </div>
    </section>
  );
}
