import React from "react";
import Layouts from "../../helpers/LayoutsWhat";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Whatsapp() {
  return (
    <>
            {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>WhatsApp Business API integration for hotels</title> */}
        <meta
          name="title"
          content="WhatsApp Business API integration for hotels"
        />
        <meta name="description" content="Improve guest experiences with WhatsApp Business for hotels. API integration provides timely notifications, reports, and seamless communication." />
        <meta name="keywords" content="WhatsApp Business, Hotel communication, API integration, Guest notifications." />
      </Helmet>
    <Layouts
      pageTitle="E-Alert Whatsapp"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "E-Alert Whatsapp", path: "/whatsapp-business-integration" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}