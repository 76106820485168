import { Route, Routes } from "react-router-dom";
import AboutFive from "./components/AboutPages/AboutFive";
import AboutFour from "./components/AboutPages/Aboutfour";
import AboutOne from "./components/AboutPages/AboutOne/index";
import AboutSeven from "./components/AboutPages/AboutSeven";
import AboutSix from "./components/AboutPages/AboutSix";
import AboutThree from "./components/AboutPages/AboutThree/index";
import AboutTwo from "./components/AboutPages/AboutTwo/index";
import Blog from "./components/BlogPages/Blog";
import BlogDetails from "./components/BlogPages/BlogDetails";
import Contact from "./components/Contact/index";
import CounterPage from "./components/CounterPage";
import FourZeroFour from "./components/FourZeroFour/index";
import HomeEight from "./components/HomeEight/index";
import HomeFive from "./components/HomeFive";
import HomeFour from "./components/HomeFour";
import HomeNine from "./components/HomeNine";
import HomeOne from "./components/HomeOne";
import HomeSeven from "./components/HomeSeven";
import HomeSix from "./components/HomeSix";
import HomeThree from "./components/HomeThree";
import HomeTwo from "./components/HomeTwo";
import Partner from "./components/Partner/index";
import ProtfolioDetails from "./components/PortfolioPages/PortfolioDetails";
import PortfolioFour from "./components/PortfolioPages/PortfolioFour";
import PortfolioOne from "./components/PortfolioPages/PortfolioOne";
import PortfolioThree from "./components/PortfolioPages/PortfolioThree";
import PortfolioTwo from "./components/PortfolioPages/PortfolioTwo";
import PricingFive from "./components/PricingPages/PricingFive/index";
import PricingFour from "./components/PricingPages/PricingFour/index";
import PricingOne from "./components/PricingPages/PricingOne";
import PricingSix from "./components/PricingPages/PricingSix";
import PricingThree from "./components/PricingPages/PricingThree/index";
import PricingTwo from "./components/PricingPages/PricingTwo";
import Frontoffice from "./components/Products/Frontoffice";
import FO from "./components/ColudProducts/FO";
import Barm from "./components/ColudProducts/Barm";
import Web from "./components/ColudProducts/Web";
import Bar from "./components/Products/Bar";
import Doorlock from "./components/Products/Doorlock";
import Wifi from "./components/Products/wifi";
import Pointofsales from "./components/Products/Pointofsales";
import Webdevelopment from "./components/Products/Webdevelopment";
import Housekeeping from "./components/Products/Housekeeping";
import Banquet from "./components/Products/Banquet";
import Material from "./components/Products/Material";
import Payroll from "./components/Products/Payroll";
import Sms from "./components/Products/Sms";
import Club from "./components/Products/Club";
import MEMBER from "./components/Products/Member";
import Gatepass from "./components/Products/Gatepass";
import Mobile from "./components/Products/Mobile";
import Crm from "./components/Products/Crm";
import Guestfeedback from "./components/Products/Guestfeedback";
import Whatsapp from "./components/Products/Whatsapp";
import Linen from "./components/Products/Linen";
import Travel from "./components/Products/Travel";
import Powerautomation from "./components/Products/Powerautomation";
import Hotelchannel from "./components/Products/Hotelchannel";
import Websitebuilder from "./components/Products/Websitebuilder";
import Revenue from "./components/Products/Revenue";
import Purchase from "./components/Products/Purchase";
import Fbcosting from "./components/Products/Fbcosting";
import Task from "./components/Products/Task";
import Email from "./components/Products/Email";
import Crs from "./components/Products/Crs";
import Irs from "./components/Products/Irs";
import Kot from "./components/Products/Kot";
import Tally from "./components/Products/Tally";
import Leisure from "./components/Products/Leisure";
import Leave from "./components/Products/Leave";
import Self from "./components/Products/Self";
import Selfroom from "./components/Products/Selfroom";
import Swiggy from "./components/Products/Swiggy";
import Zomato from "./components/Products/Zomato";
import DMDS from "./components/Products/Dmds";
import INV from "./components/Products/Inv";
import Retail from "./components/Products/Retail";
import Asset from "./components/Products/Asset";
import Kds from "./components/Products/Kds";
import Webcheck from "./components/Products/Webcheck";
import ServiceDetails from "./components/ServicePages/ServiceDetails/index";
import ServiceFive from "./components/ServicePages/ServiceFive";
import ServiceFour from "./components/ServicePages/ServiceFour";
import ServiceOne from "./components/ServicePages/ServiceOne/index";
import Cloud from "./components/Cloud/index";
import Careers from "./components/Careers/index";
import Premises from "./components/Premises/index";
import Integration from "./components/Integration/index";
import ServiceSeven from "./components/ServicePages/ServiceSeven";
import ServiceSix from "./components/ServicePages/ServiceSix";
import ServiceThree from "./components/ServicePages/ServiceThree";
import ServiceTwo from "./components/ServicePages/ServiceTwo/index";
import Shop from "./components/ShopPages/Shop";
import ShopDetails from "./components/ShopPages/ShopDetails";
import TeamFour from "./components/TeamPages/TeamFour";
import TeamOne from "./components/TeamPages/TeamOne";
import TeamSingle from "./components/TeamPages/TeamSingle";
import TeamThree from "./components/TeamPages/TeamThree";
import TeamTwo from "./components/TeamPages/TeamTwo";
import TestimonialFour from "./components/TestimonialPages/TestimonialFour";
import TestimonialThree from "./components/TestimonialPages/TestimonialThree";
import TestimonialTwo from "./components/TestimonialPages/TestimonialTwo/index";
import TestimonialOne from "./components/TestimonialPages/TestmonialOne";
import PrivacyPolicy from './components/PrivacyPolicy/index';
import CancellationRefund from './components/CancellationRefund/index'
import Shipping from './components/Shipping/index';
import TermsandConditions from './components/TermsandConditions/index'
import Payment from "./components/Payment";
import HotelManagment from "./components/blog/index";
import HotelManagmentAdv from "./components/blog/HMadvantage/index";
import Rmsreport from "./components/blog/Rmsreport/index";
import Boosthotel from "./components/blog/Boosthotel/index"
export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<HomeOne />} />
      <Route exact path="/home-two" element={<HomeTwo />} />
      <Route exact path="/home-three" element={<HomeThree />} />
      <Route exact path="/home-four" element={<HomeFour />} />
      <Route exact path="/home-five" element={<HomeFive />} />
      <Route exact path="/home-six" element={<HomeSix />} />
      <Route exact path="/home-seven" element={<HomeSeven />} />
      <Route exact path="/home-eight" element={<HomeEight />} />
      <Route exact path="/home-nine" element={<HomeNine />} />
      <Route exact path="/about-us" element={<AboutOne />} />
      {/* <Route exact path="/about-two" element={<AboutTwo />} />
      <Route exact path="/about-Three" element={<AboutThree />} />
      <Route exact path="/about-four" element={<AboutFour />} />
      <Route exact path="/about-five" element={<AboutFive />} />
      <Route exact path="/about-six" element={<AboutSix />} />
      <Route exact path="/about-seven" element={<AboutSeven />} /> */}
      <Route exact path="/service-one" element={<ServiceOne />} />
      <Route exact path="/products/cloud" element={<Cloud />} />
      <Route exact path="/careers" element={<Careers/>} />
      <Route exact path="/partner" element={<Partner />} />
      <Route exact path="/products/premises" element={<Premises />} />
      <Route exact path="/products/integration" element={<Integration />} />
      <Route exact path="/Fbcosting-system" element={<Fbcosting />} />
      <Route exact path="/services" element={<ServiceTwo />} />
      <Route exact path="/service-three" element={<ServiceThree />} />
      <Route exact path="/service-four" element={<ServiceFour />} />
      <Route exact path="/service-five" element={<ServiceFive />} />
      <Route exact path="/service-six" element={<ServiceSix />} />
      <Route exact path="/service-seven" element={<ServiceSeven />} />
      <Route exact path="/service-details" element={<ServiceDetails />} />
      <Route exact path="/frontoffice" element={<Frontoffice />} />
      <Route exact path="/hotel-front-office-software" element={<FO />} />
      <Route exact path="/bar-management-system" element={<Barm />} />
      <Route exact path="/hotel-and-restaurant-website-builder" element={<Web />} />
      <Route exact path="/bar-management-system" element={<Bar />} />
      <Route exact path="/pointofsales" element={<Pointofsales />} />
      <Route exact path="/Webdevelopment" element={<Webdevelopment />} />
      <Route exact path="/housekeeping" element={<Housekeeping />} />
      <Route exact path="/banquet-management-system" element={<Banquet />} />
      <Route exact path="/wireless-door-lock" element={<Doorlock />} />
      <Route exact path="/wifi-services" element={<Wifi />} />
      <Route exact path="/Material-Management-System" element={<Material />} />
      <Route exact path="/payroll-management-system" element={<Payroll />} />
      <Route exact path="/sms-ealert-system" element={<Sms />} />
      <Route exact path="/power-automation-system" element={<Powerautomation />} />
      <Route exact path="/hotel-channel-manager" element={<Hotelchannel />} />
      <Route exact path="/Websitebuilder" element={<Websitebuilder />} />
      <Route exact path="/hotel-revenue-member-management-software" element={<Revenue />} />
      <Route exact path="/purchase-software" element={<Purchase />} />
      <Route exact path="/Club" element={<Club />} />
      <Route exact path="/club-member-management-software" element={<MEMBER />} />
      <Route exact path="/gatepass-management-system" element={<Gatepass />} />
      <Route exact path="/task-management-system" element={<Task />} />
      <Route exact path="/hotel-management-mobile-application" element={<Mobile />} />
      <Route exact path="/Crm" element={<Crm />} />
      <Route exact path="/guest-feed-back" element={<Guestfeedback />} />
      <Route exact path="/whatsapp-business-integration" element={<Whatsapp />} />
      <Route exact path="/linen-management-software" element={<Linen />} />
      <Route exact path="/email-notification-software" element={<Email />} />
      <Route exact path="/central-reservation-system" element={<Crs />} />
      <Route exact path="/internet-reservation-system" element={<Irs />} />
      <Route exact path="/Kot" element={<Kot />} />
      <Route exact path="/tally-interface" element={<Tally />} />
      <Route exact path="/leisure-management-system" element={<Leisure />} />
      <Route exact path="/Leave" element={<Leave />} />
      <Route exact path="/travel-desk" element={<Travel />} />
      <Route exact path="/qr-based-ordering-system" element={<Self />} />
      <Route exact path="/self-ordering-room-services-qr-based" element={<Selfroom />} />
      <Route exact path="/swiggy-pos-integration" element={<Swiggy/>} />
      <Route exact path="/zomato-pos-integration" element={<Zomato />} />
      <Route exact path="/digital-menu-digital-system" element={<DMDS />} />
      <Route exact path="/Inventory-management-system" element={<INV />} />
      <Route exact path="/Webcheck" element={<Webcheck />} />
      <Route exact path="/retail-management" element={<Retail />} />
      <Route exact path="/assest-management" element={<Asset />} />
      <Route exact path="/kitchen-display-system" element={<Kds />} />
      <Route exact path="/portfolio-one" element={<PortfolioOne />} />
      <Route exact path="/portfolio-two" element={<PortfolioTwo />} />
      <Route exact path="/portfolio-three" element={<PortfolioThree />} />
      <Route exact path="/portfolio-four" element={<PortfolioFour />} />
      <Route exact path="/portfolio-details" element={<ProtfolioDetails />} />
      <Route exact path="/team-one" element={<TeamOne />} />
      <Route exact path="/team-two" element={<TeamTwo />} />
      <Route exact path="/team-three" element={<TeamThree />} />
      <Route exact path="/team" element={<TeamFour />} />
      <Route exact path="/team-single" element={<TeamSingle />} />
      <Route exact path="/testimonial-one" element={<TestimonialOne />} />
      <Route exact path="/testimonial-two" element={<TestimonialTwo />} />
      <Route exact path="/testimonial-three" element={<TestimonialThree />} />
      <Route exact path="/testimonial-four" element={<TestimonialFour />} />
      <Route exact path="/pricing" element={<PricingOne />} />
      <Route exact path="/pricing-two" element={<PricingTwo />} />
      <Route exact path="/pricing-three" element={<PricingThree />} />
      <Route exact path="/pricing-four" element={<PricingFour />} />
      <Route exact path="/pricing-five" element={<PricingFive />} />
      <Route exact path="/pricing-six" element={<PricingSix />} />
      <Route exact path="/shop" element={<Shop />} />
      <Route exact path="/shop-details" element={<ShopDetails />} />
      <Route exact path="/blog" element={<Blog />} />
      <Route exact path="/blog/blog-details" element={<BlogDetails />} />
      <Route exact path="/404" element={<FourZeroFour />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/counters" element={<CounterPage />} />
      <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route exact path="/CancellationRefund" element={<CancellationRefund />} />
      <Route exact path="/Shipping" element={ <Shipping />} />
      <Route exact path="/TermsandConditions" element={ <TermsandConditions />} />
      <Route exact path="/Payment" element={<Payment></Payment>}></Route>
      <Route exact path="/blog/hotel-management-systems-shaping-the-future-of-hospitality" element={<HotelManagment></HotelManagment>}></Route>
      <Route exact path="/blog/top-advantages-of-using-hotel-management-software-for-small-hotels" element={<HotelManagmentAdv></HotelManagmentAdv>}></Route>
      <Route exact path="/blog/essential-rms-reports-for-optimizing-hotel-revenue-management" element={<Rmsreport></Rmsreport>}></Route>
      <Route exact path="/blog/how-hotel-management-software-boosts-efficiency-and-guest-satisfaction" element={<Boosthotel></Boosthotel>}></Route>

    </Routes>
  );
}
