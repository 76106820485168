import React from "react";
import Layouts from "../../helpers/LayoutsSelfr";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Selfroom() {
  return (
    <>
        {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Self-ordering Room Service QR based        </title> */}
        <meta
          name="title"
          content="Self-ordering Room Service QR based     "
        />
        <meta name="description" content="Enhance guest experience with QR based room service. Scan, order, and pay directly from your mobile device." />
        <meta name="keywords" content="QR code hotel room service, digital menu, in-room dining, hotel guest experience, hospitality technology, hotel menu innovation, digital ordering, room service app." />
      </Helmet>
    <Layouts
      pageTitle="Self-ordering Room Service QR code based"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Self-ordering Room Service QR code based", path: "/self-ordering-room-services-qr-based" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
