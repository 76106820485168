import React from "react";
import Layouts from "../../helpers/Layouts";
import MainSection from "./MainSection";


export default function Crm() {
  return (
    <Layouts
      pageTitle="CRM (Customer Relationship Management)"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "CRM", path: "/Crm" },
      ]}
    >
      <MainSection />
    </Layouts>
  );
}
