import React from "react";
import background from "../../assets/images/background/progress.png";
import SliderCom from "../helpers/SliderCom";

import logo2 from "../../assets/images/client-logo/vijayin.png";
import logo3 from "../../assets/images/client-logo/Bhimas.png";
import logo4 from "../../assets/images/client-logo/pvk.jpg";
import logo5 from "../../assets/images/client-logo/SR.jpg";
import logo6 from "../../assets/images/client-logo/vijay.png";
import logo7 from "../../assets/images/client-logo/vijayelanza.png";
import logo8 from "../../assets/images/client-logo/porch inn.png";
import logo9 from "../../assets/images/client-logo/legendsinn.jpeg";
import { Link } from "react-router-dom";


export default function WorkProcessSection() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <section
      className="work-progress-three bg-blue text-white pb-20 rpb-65"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="logo-carousel-wrap style-two bg-white py-50 px-25 br-5">
          <SliderCom settings={settings}>
            
            <div className="logo-item">
              <a href="#" >
                <img
                  src={
                    logo2
                  }
                  alt="Client Logo" 
                />
              </a>
            </div>
            <div className="logo-item">
              <a href="#">
                <img
                  src={
                   logo3
                  }
                  alt="Client Logo" width="193px" height="90px"
                />
              </a>
            </div>
            
            <div className="logo-item">
              <a href="#">
                <img
                  src={
                    logo4
                  }
                  alt="Client Logo"
                />
              </a>
            </div>
            <div className="logo-item">
              <a href="#">
                <img
                  src={
                   logo5
                  }
                  alt="Client Logo" width="193px" height="90px"
                />
              </a>
            </div>
            <div className="logo-item">
              <a href="#">
                <img
                  src={
                   logo6
                  }
                  alt="Client Logo" 
                />
              </a>
            </div>
            <div className="logo-item">
              <a href="#">
                <img
                  src={
                   logo7
                  }
                  alt="Client Logo" 
                />
              </a>
            </div>
            <div className="logo-item">
              <a href="#">
                <img
                  src={
                   logo8
                  }
                  alt="Client Logo" 
                />
              </a>
            </div>
            <div className="logo-item">
              <a href="#">
                <img
                  src={
                   logo9
                  }
                  alt="Client Logo" 
                />
              </a>
            </div>
 
          </SliderCom>
        </div>
        <div className="work-progress-inner-three ">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6 mt-70">
              <div className="progress-item-two style-two wow fadeInUp delay-0-2s">
                
                <div className="icon">
                  <i className="flaticon flaticon-speech-bubble"></i>
                </div>
                <h3>
                Website <br />
                Development 
                </h3>
                <p className="text-center">
                Microgenn comprises a website that strengthens your brand&#39;s 
                online presence ..
                </p>
                <Link to="/Webdevelopment" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-70">
              <div className="progress-item-two style-two wow fadeInUp delay-0-4s">
                
                <div className="icon">
                  <i className="flaticon flaticon-vector"></i>
                </div>
                <h3>
                Mobile <br />
                Application
                </h3>
                <p className="text-center">
                Microgenn mobile application provides check-in and check-out,
                 food ordering, instant and ..
                </p>
                <Link to="/Mobile" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-70">
              <div className="progress-item-two style-two wow fadeInUp delay-0-6s no-border mb-0">
                
                <div className="icon">
                  <i className="flaticon flaticon-like-1"></i>
                </div>
                <h3>
                Revenue <br />
                Management
                </h3>
                <p className="text-center">
                A revenue management system helps hotels to 
                determine how much to charge  ..
                </p>
                <Link to="/Revenue" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
