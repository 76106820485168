import React from "react";
import Layouts from "../../helpers/Layoutscrs";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Crs() {
  return (
    <>
       {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Central Reservation System | Best CRS System for Hotel</title> */}
        <meta
          name="title"
          content="Central Reservation System | Best CRS System for Hotel"
        />
        <meta name="description" content="Manage bookings, automate operations, and maximize customer engagement with our central reservation system. Streamline pricing, reporting, and reservations." />
        <meta name="keywords" content="Central Reservation System, booking management, automate operations, reservation software, hotel booking system, pricing management, customer engagement, overbooking prevention." />
      </Helmet>
    <Layouts
      pageTitle="Central Reservation system"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Central Reservation system", path: "/central-reservation-system" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
