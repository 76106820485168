import React from "react";
import Layouts from "../../helpers/Layoutspoint";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Pointofsales() {
  return (
    <>
     {/* Helmet for SEO Metadata */}
     <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Point of Sale Software (POS) | MicroGenn Solution.   </title> */}
        <meta
          name="title"
          content="Point of Sale Software (POS) | MicroGenn Solution."
        />
        <meta name="description" content="MicroGenn’s Point of sale software simplifies billing, automates settlements, and offers personalized services for fine dining outlets and restaurants." />
        <meta name="keywords" content="Point of sale software, POS software, restaurant POS, bar POS, fine dining POS, inventory management, guest profile, billing system, KOT printing, table management, sales tracking, hospitality POS." />
      </Helmet>
    <Layouts
      pageTitle="Point Of Sale"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Point Of Sale", path: "/pointofsales" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}
