import React from "react";
import Layouts from "../../helpers/Layoutshc";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Bar() {
  return (
    <>
           {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Hotel Channel Manager | Global Distribution System (GDS)</title> */}
        <meta
          name="title"
          content="Hotel Channel Manager | Global Distribution System (GDS)"
        />
        <meta name="description" content="Manage hotel inventory and rates seamlessly with Microgenn’s channel manager. Instant updates across travel channels via smartphones and tablets." />
        <meta name="keywords" content="Hotel Channel Manager, Global Distribution System, GDS, Mobile access, Automatic updates." />
      </Helmet>
    <Layouts
      pageTitle="Channel Manager"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Channel Manager", path: "/hotel-channel-manager" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}