import React from "react";
import GetInTouchSection from "../AboutPages/AboutOne/GetInTouchSection";
import Layouts from "../helpers/LayoutP/index";
import { Helmet } from 'react-helmet'; 

export default function Partner() {
  return ( 
    <>
         {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Grow Your Business: Partner with Microgenn</title> */}
        <meta
          name="title"
          content="Grow Your Business: Partner with Microgenn"
        />
        <meta name="description" content="Join our community of 200+ partners and achieve business goals with Microgenn’s guidance. Unlock growth opportunities!" />
        <meta name="keywords" content="Business partnership, Microgenn collaboration, Business growth." />
      </Helmet>
    <Layouts
      pageTitle="Partner With Microgenn "
    >
    <GetInTouchSection />
    </Layouts>
   
    
</>  );
}

