import React from "react";
import { Link } from "react-router-dom";

function FeatureSection() {
  return (
    <section className="featured-section bgs-cover pt-150 rpt-150 pb-120 rpb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="section-title text-center mb-35">
              <span className="sub-title">One-step ideal software solution for your hotels</span>
              <h2><span id="color">Integration </span>Products</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">

        <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Wifi Interface</h5>
                <p>Automated communication with customers, volunteers, and members facilitates the mitigation of problems quickly. With our alert system ..</p>
                <Link to="#" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>E-Alert SMS</h5>
                <p>Automated communication with customers, volunteers, and members facilitates the mitigation of problems quickly. With our alert system ..</p>
                <Link to="/sms-ealert-system" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>E-Alert Email</h5>
                <p>Email is one of the most widely used communication channels in the corporate world.Email notification software is a useful and practical ..</p>
                <Link to="/email-notification-software" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>


          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>E-Alert Whatsapp</h5>
                <p>In recent times WhatsApp business for hotels gained good traction in recent times. It seems to be a good choice for several types of industries .. </p>
                <Link to="/whatsapp-business-integration" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Hotel Channel Manager</h5>
                <p> Microgenn&#39;s channel manager provides help to hotels for easily managing inventory and property rates on various travel websites. Hotel channel ..</p>
                <Link to="/hotel-channel-manager" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Tally Interface</h5>
                <p>The tally interface enables restaurants and hotels to post their invoice and receivables details automatically to the financial accounting..</p>
                <Link to="/tally-interface" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Door Lock</h5>
                <p>Automated communication with customers, volunteers, and members facilitates the mitigation of problems quickly. With our alert system ..</p>
                <Link to="#" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        
        </div>
     
      </div>
    </section>
  );
}

export default FeatureSection;
