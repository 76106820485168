import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/self.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li>
                    <a href="/Websitebuilder">Hotels and restaurant website builder</a>
                  </li> 
                
                  <li  >
                    <a href="/Fbcosting">F & B Costing</a>
                  </li>
                  <li  >
                    <a href="/Club">Club Management</a>
                  </li>
                 
                  <li>
                    <a href="/Guestfeedback">Guest Feedback</a>
                  </li>
                  
                  
                  <li >
                    <a href="/Kot">Tab-based KOT System</a>
                  </li>
                 
                  <li  className="active">
                    <a href="/Self">Self-ordering Restaurant QR code based</a>
                  </li>
                  <li>
                    <a href="/Webcheck">Web Check-in</a>
                  </li>
                 
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-9566411119">+91-95664-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Self Ordering"
                /> */}
              </div>
              <h2> Self-ordering restaurant <span id="color">QR </span>code based System</h2>
              <p>When your customer comes to your restaurant and finds a print of a QR code at
                multiple places in your restaurant premises like on the table, counter, entrance,
                and even advertisement materials. Customers will scan the QR code through the
                phone camera and it shows a link to open in the browser. There the customers
                get the mobile responsive version of your food menu. The customers don’t need
                to install any app or anything. 
                {" "}
              </p>
              <p>Customers can directly place the order from their mobile devices and pay the
                amount without touching any card or physical currency payment. The order
                goes directly to your kitchen tablet app, you process the order and deliver the
                food to the table number, as punched by the customer along with their order. In
                the kitchen tablet app, there is a feature to print orders on your thermal printer
                manually or it will automatically print KOT order receipts in the kitchen
                without any human intervention. QR-based ordering system is the best &amp;
                innovative way to greet your customer with full safety.
              {" "}
              </p>
              <p>QR-based ordering system even works for dine-in customers. A QR code can be
                placed at each table in your restaurant dining area. The customers come to take
                their place at the table and scan the QR to get a full-fledged digital menu and
                they start to place their orders. The order will go to the kitchen table app and the
                preparation process starts to serve your dining area customers. Customers can
                pay the bills online in a mobile responsive web ordering system or can pay cash
                directly at the table or cash counters.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-5 pb-20">
                <li>Order easily with a digital menu & Process mode of payment
                </li>
                <li>Orders placed without human interaction
                </li>
                <li>Reduces human/manual error & Track guest preferences
                </li>
                
                <li>Customer interaction, saving favorite menu items and adding likes to dishes </li>
                
                <li>Improve customer satisfaction</li>
                <li>manage your entire restaurant from one centralized platform</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
