import React from "react";
import Layouts from "../../helpers/LayoutsBar";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Barm() {
  return (
    <>
       {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Bar Management System for Hotel | Bar Management Software</title> */}
        <meta
          name="title"
          content=" Bar Management System for Hotel | Bar Management Software"
        />
        <meta name="description" content="Reduce losses and enhance bar efficiency with Microgenn's Bar Management System. Automate inventory, track usage, and make informed business decisions." />
        <meta name="keywords" content="Bar management, inventory control, bar efficiency, liquor management, bar software, bar analytics, reduce waste, bar automation, bartending system, stock management, hospitality management." />
      </Helmet>
    <Layouts
      pageTitle="Bar Management system"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Bar Management System", path: "/bar-management-system" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
