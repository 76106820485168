import React from "react";
import GetInTouchSection from "../../AboutPages/AboutOne/GetInTouchSection";
import Layouts from "../../helpers/Layouts/index";
import FeatureSection from "../../HomeOne/FeatureSection";
import PricingSection from "../../HomeOne/PricingSection";
import WorkProcessSection from "./WorkProcessSection";

export default function ServiceOne() {
  return ( 
    <Layouts
      pageTitle="Our Products"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Products", path: "/service-one" },
      ]}
    >
      <FeatureSection />
      <WorkProcessSection />
      <PricingSection />
      <GetInTouchSection />
    </Layouts>
  );
}
