import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/email.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                 
                <li >
                    <a href="#">Wifi Interface</a>
                  </li>
                <li  >
                    <a href="/Sms">E-Alert SMS</a>
                  </li>
                  <li  className="active">
                    <a href="/Email">E-Alert Email</a>
                  </li>
                  <li>
                    <a href="/Whatsapp">E-Alert WhatsApp</a>
                  </li>
                  
                  <li>
                    <a href="/Hotelchannel">Hotel Channel Manager</a>
                  </li>
                
                  
                  <li>
                    <a href="/Tally">Tally Interface</a>
                  </li>
                  <li >
                    <a href="#">Door lock</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Email Alerts"
                /> */}
              </div>
              <h2>E-Alert Email | Email Notification Software</h2>
              <p>Email is one of the most widely used communication channels in the corporate
                world. Email notification software is a useful and practical investment for a
                company. Mail templates can be drawn up with booking offers, invoices,
                confirmations, requests, etc. Personalized push notifications can be created in
                order to communicate with clients.
                {" "}
              </p>
              <p>Time consumption is possible just by creating templates and communicating
                with customers. With the help of the mail archive, communication with the
                clients can be tracked and customized offers can be made. The future of the
                hospitality industry lies in digital communication, so email alerts play an
                important role. As the tool is synchronized with hotel management software, by
                generating push notifications, you can get guest info.
              {" "}
              </p>
              <p>Attracting more customers and improving sales can be done by sending emails
                and notifications with package deals, info about hotel services and amenities,
                and special offers.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Booking confirmation email with details to guests and hotel staff
                </li>
                <li>Advance payment/transaction alerts
                </li>
                <li>Check-in and check-out notifications
                </li>
                <li>Final settlement bill notification</li>
                <li>Star-based feedback rating link for guests</li>
                <li>Feedback link covers overall departments</li>
                <li>Filled feedback results sent to managers and respective staff</li>
                <li>Collects positive and negative feedback</li>
                <li>Daily financial report submitted to the manager and front office</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
