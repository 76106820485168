import React from "react";
import Layouts from "../../helpers/LayoutsPo";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Bar() {
  return (
    <>
        {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        <title>Power Automation System for Hotel | Power Automation Software</title>
        <meta
          name="title"
          content="Power Automation System for Hotel | Power Automation Software"
        />
        <meta name="description" content="Microgenn's power automation system improves operational efficiency, reduces energy costs, and enhances guest experience in hotels." />
        <meta name="keywords" content="Power automation, hotel room automation, energy savings, Microgenn system, guest room control, lighting control." />
      </Helmet>
    <Layouts
      pageTitle="Power Automation"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Power Automation", path: "/power-automation-system" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}