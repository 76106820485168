import React from "react";
import Layouts from "../../helpers/Layoutswifi";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Wifi() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Hospitality WiFi Services | MicroGenn</title> */}
        <meta
          name="title"
          content="Hospitality WiFi Services | MicroGenn"
        />
        <meta name="description" content="Over 90% of travelers demand Wi-Fi. MicroGenn Offering free, fast access enhances guest satisfaction and loyalty." />
        <meta name="keywords" content="hotel Wi-Fi, guest expectations, free Wi-Fi access, hotel amenities, business travel Wi-Fi, leisure travel Wi-Fi, fast internet hotel." />
      </Helmet>
    <Layouts
      pageTitle="Wifi Interface"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Door Lock", path: "/wifi-services" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
