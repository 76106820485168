import React from "react";
import { useState } from "react";
import imageIcon from '../../assets/images/logos/logo.png'
import Swal from "sweetalert2";
import {  useNavigate } from "react-router-dom";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function Hero() {
  const [property, setProperty] = useState("");
  const [gst, setGst] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [invoice, setInvoice] = useState("");
  const [paymentfor, setPaymentFor] = useState("");
  const [amount, setAmount] = useState("");
  const [formErrors, setFormError] = useState("");
  const navigate = useNavigate;

  const clearDatas=() =>{
    setProperty("");
    setGst("");
    setAmount("");
    setEmail("");
    setFormError("");
    setMobile("");
    setInvoice("");
    navigate('/Payment');
  }

  const navigateHome = ()=>{
    setProperty("");
    setGst("");
    setAmount("");
    setEmail("");
    setFormError("");
    setMobile("");
    setInvoice("");
    navigate('/');
  }
  const handlePayment = async (data) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: "rzp_live_ziKvQL6fIb9PwM",
      amount: data.amount * 100,
      currency: "INR",
      name: "Microgenn Payment Portal",
      description: data.paymenttype,
      image:imageIcon,
      handler: function (response) {
        Swal.fire({
          title: "Thanks, Payment Successfull",
          text: `Your Payment Id ${response.razorpay_payment_id}.Our Accounts Team Will contact you soon.`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        }).then(()=>{
          navigateHome();
        });
      },
      prefill: {
        name: data.propertyName,
        email: data.Email,
        contact: data.mobileno,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      Swal.fire({
        title: "Payment Failed",
        text: `Your ${response.error.reason}.Our Accounts Team Will contact you soon.`,
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        cancelButtonText: "Try Again",
      }).then((result)=>{
        if(result.value){
          navigateHome()
        }
        if(result.dismiss){
          clearDatas()
        } 
      });
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };
  const SubmitEvent = () => {
    if (property === "") {
      setFormError("Fill Your Property ");
    } else if (gst === "") {
      setFormError("Fill Your GSTINN No");
    } else if (email === "") {
      setFormError("Fill your  Email Address");
    } else if (email.includes("@") === false) {
      setFormError("Fill your valid Email Address");
    } else if (mobile === "" || mobile === 0) {
      setFormError("Fill your Mobile No");
    } else if (paymentfor === "") {
      setFormError("Fill your reason for payment");
    }else if (invoice === "") {
      setFormError("Fill your Reference Invoice Number");
    }  else if (amount === "" || amount === 0) {
      setFormError("Fill Valid Amount");
    } else {
      setFormError("");
      handlePayment({
        propertyName: property,
        gst: gst,
        Email: email,
        mobileno: mobile,
        InvoiceNumber: invoice,
        paymenttype: paymentfor,
        amount: amount,
      });
    }
  };
  return (
    <section className="hero-section overlay bgs-cover pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="signup-content col-6 offset-3">
            <div className="signup-form">
              <h3 className="form-title">
                Microg<span className="form-title-span">en</span>n Payment
                Portal
              </h3>
              <form method="POST" className="register-form" id="register-form">
                <p className="text-danger">
                  {formErrors !== "" ? formErrors : null}
                </p>

                <p className="text-danger"></p>
                <div className="form-group">
                  <label htmlFor="property">
                    <i className="zmdi zmdi-account material-icons-name"></i>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setProperty(e.target.value)}
                    value={property}
                    name="property"
                    id="property"
                    placeholder="Your Property "
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="gst">
                    <i className="zmdi zmdi-cast"></i>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setGst(e.target.value.toUpperCase())}
                    value={gst}
                    name="gst"
                    id="gst"
                    placeholder="Your GSTINN No"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    <i className="zmdi zmdi-email"></i>
                  </label>
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    name="email"
                    id="email"
                    placeholder="Your Email"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Mobile">
                    <i className="zmdi zmdi-smartphone-android"></i>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setMobile(e.target.value);
                      }
                    }}
                    maxLength={10}
                    value={mobile}
                    name="Mobile"
                    id="Mobile"
                    placeholder="Your Mobile No"
                  />
                </div>
                <div className="form-group">
                 
                 <select onChange={(e) => setPaymentFor(e.target.value)}  name="paymenttype" >
                  <option value="">Payment For</option>
                  <option value="Software Payment">Software Payment</option>
                  <option value="AMC Payment">AMC Payment</option>
                  <option value="Whatsapp Sms Credits Payment">Whatsapp Sms Credits Payment</option>
                  <option value="Sms Credits Payment">Sms Credits Payment</option>
                  <option value="Other Service">Other Service</option>
                 </select>
                </div>
                <div className="form-group">
                  <label htmlFor="invoice">
                    <i className="zmdi zmdi-file-text"></i>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setInvoice(e.target.value.toUpperCase())}
                    value={invoice}
                    name="invoice"
                    id="invoice"
                    placeholder="Reference Invoice.No"
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="amount">
                    <i className="zmdi zmdi-format-strikethrough-s"></i>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      const re = /[0-9]\d{0,9}(\.\d{1,2})?%?/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setAmount(e.target.value);
                      }
                    }}
                    value={amount}
                    name="amount"
                    id="amount"
                    placeholder="Charges Amount"
                  />
                </div>
                <div className="form-group form-button">
                  <input
                    type="button"
                    style={{background:"#135893", color:"#fff"}}
                    onClick={SubmitEvent}
                    className="btn btn-primary"
                    name="blinkCheckoutPayment"
                    id="blinkCheckoutPayment"
                    value="Pay"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
