import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/asset.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li   className="active">
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Asset Management"
                /> */}
              </div>
              <h2>Asset Tracking | Assest Management </h2>
              <p>Like all businesses &amp; resort industries will have a period of peak performance
                and periods of economic decline. There is no doubt that there are uncertainties
                and risks in running a hotel, but with a good asset management strategy, these
                events can be minimized to a great extent. Microgenn’s hotel asset management
                tracker encompasses essential tools that enable efficient tracking and managing
                of your resources. To meet the demands of their business objectives, hotels &amp;
                resorts purchase a variety of assets regularly. Assets include food items,
                housekeeping equipment, furniture, electrical equipment, kitchen wares,
                facilities, building, and more. These assets need to be taken care of, from time
                to time such that their conditions don’t deteriorate.
                {" "}
              </p>
              <p>If the quality of the asset’s declines, then it will cost the management a lot of
                money to fix or even replace the assets. Another problem that arises out of
                having those assets, is knowing where they are located. Misplaced assets can
                stall the workflow of the hotel. These are only a few challenges among the
                extensive list of issues that come from mismanaging assets.
              {" "}
              </p>
              <p>The gist of asset management includes recording the data from the time an asset
                is acquired, up until the time it is decommissioned. It is not only confined to
                knowing the quality and whereabouts of assets, it also deals with evaluating the
                financial value of the assets over time. Asset management aims to track
                resources, minimize expenditure, and optimize the usage of assets. Using digital
                programs to execute asset management strategies will reap exceptional results.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>efficient tracking and managing of resources
                </li>
                <li>evaluating the financial value of the assets over time
                </li>
                <li>minimize expenditure
                </li>
                <li>optimize the usage of assets</li>
                <li>tracking the maintenance of asset</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
