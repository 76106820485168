import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li  className="active">
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              
              <h2>Retail Management Services</h2>
              <p>While maintaining established standards, the field of hospitality retail
                management offers managers an opportunity to enhance a customer’s
                hospitality experience based on creativity. The role includes managing aspects
                of any hospitality establishment such as restaurants, casinos, hotels, museums,
                and gift shops.
                {" "}
              </p>
              <p>Hospitality retail management will reflect the feeling of familiarity and security,
                the customers experience when they enter a hotel, restaurant, pub, or other
                establishments. and knowing what level of quality and service to expect.
                Hospitality retail management somewhat differs from other forms of
                management. Hospitality retail management focuses on managing the customer
                experience to provide consistent service, quality, and presentation.
              {" "}
              </p>
              <p>Depending on the establishment, this role requires varying degrees of
                merchandising, coaching employees, and quality assurance. The field of
                hospitality retail management will include various behind-the-scenes workers
                and functions. Workers include back office workers such as chefs, sources,
                planners, security specialists, and purchases. Managers must carefully control
                the customer’s environment.
              {" "}
              </p>
              <p>Many retail companies have ventured into the hotel business to contextualize
                their products and also engage customers in a less transactional, and more
                meaningful way. {" "}</p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Bill management for additional POS
                </li>
                <li>Tracking and recording of payments
                </li>
                <li>Tracking of inventory
                </li>
                <li>Feedback through customer satisfaction surveys</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
