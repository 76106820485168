import React, { useState } from "react";
// import background from "../../../assets/images/contact/home-two.jpg";
import Swal from "sweetalert2";
import axios, * as others from 'axios';

export default function GetInTouchSection() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mesage, setMeaage] = useState("Tell us about Yourself");
  const [mobile, setMobile] = useState("");
  const [Designation,setDesignation] = useState("PHP Developer");


  const addCarrer = () => {
    
    if(email !='' && name !='' && mesage !='' && Designation !="" &&  mobile !=""){

       axios.post('https://formspree.io/f/xyyqqyly', {
      name : name,
      email: email,
      msg : mesage,
      mobile: mobile,
      Designation:Designation
    }).then(()=>{
    
      Swal.fire({
        title: "Thanks for Applying! ",
        text: " We will Contact you Shortly.",
        icon: "success",
        showConfirmButton: false,
        timer: 2000
      }).then(()=>{
        setEmail("");
        setMobile("");
        setMeaage("");
        setName("");
        setDesignation("PHP Developer");
        setMeaage("Tell us about yourself");
      });
    
    });
  }
    else{

      Swal.fire({
        title: "Required",
        text: "fill out All the required fields",
        icon: "error",
        showConfirmButton: false,
        timer: 2000
      })
    }
  };
  return (
    <section className="contact-four py-120 rpy-100 back" id="register">
      <div className="container">
        <div>
          <div className="row">

          <div className="col-lg-6">
              <div>
                <div >
                  <div className="section-title mb-20">
                    <h2>Let's  Be <span id="color">Connected</span></h2>
                  </div>
                  <p className="careerr-form">Looking for a challenging careers in a digital solutions environment? We’re ready to hire you Welcome to our culture!</p>
                  <hr></hr>
                  
                    <p className="careerr-form">Any interested can forward their updated resume via email.</p>
                    <div>
                    <i className="fas fa-envelope icon"></i>
                    <a href="mailto:info@microgenn.com" className="careerr-form">info@microgenn.com</a>
                    </div>
                    
                
                  
                  <hr></hr>
                  <div className="copyright-area">
                    <p className="careerr-form">Follow Us On Our Social Hubs</p>
                    <div className="container">
                        <div className="social-style-one mb-10">
                          <a href="https://www.facebook.com/microgennhms/">
                            <i className="fab fa-facebook-f icon"></i>
                          </a>
                          <a href="https://mobile.twitter.com/microgennss">
                            <i className="fab fa-twitter icon"></i>
                          </a>
                          <a href="https://in.linkedin.com/company/microgennss">
                            <i className="fab fa-instagram icon"></i>
                          </a>
                          <a href="https://in.pinterest.com/microgennsoftwaresolutions/">
                            <i className="fab fa-pinterest-p icon"></i>
                          </a>
                        </div>
                        
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-lg-6" >
              <div className="contact-section-form px-15 rpx-0 rmb-50 wow fadeInUp delay-0-2s animated">
                
                <p className="career-form">Please complete the registration form below to continue your application process</p>
                <form
                  id="contact-form"
                  className="contact-form"
                  name="contact-form"
                  method="post"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          value={name}
                          placeholder="Name"
                          required="required"
                          onChange={(event) =>{ setName(event.target.value);}}
                          
                        />
                        
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          required="required"
                          onChange={(event) =>{ setEmail(event.target.value);}}
                         
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        
                        <input
                          type="text"
                          id="number"
                          name="number"
                          className="form-control"
                          placeholder="Phone Number"
                          required="required"
                          value={mobile}
                          maxLength={10}
                          onChange={(event) =>{ setMobile(event.target.value);}}
                          
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="desig"></label>
                        <select id="desig" name="desig" required="required" onChange={(event) =>{ setDesignation(event.target.value);}}>
                          <option  value="PHP Developer">PHP Developer</option>
                          <option value="Dot Net Developer">Dot NET Developer</option>
                          <option value="Sales Executive">Sales Executive</option>
                          
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        {/* <input
                          type="file"
                          id="resume"
                          name="upload"
                          className="form-control"
                          placeholder=".PDF, .DOCX,."
                          maxLength={10}
                          required="required"
                        
                        /> */}
                        <textarea name="messgae" onChange={(event)=>{setMeaage(event.target.value)}}
                         placeholder={mesage} value={mesage}
                          required="required"></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button type="button" className="theme-btn1" onClick={addCarrer} >
                       Apply Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </section>
  );
}
