import React from "react";
import Layouts from "../../helpers/Layouts";
import MainSection from "./MainSection";


export default function Webcheck() {
  return (
    <Layouts
      pageTitle="Web Check-in"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Web Check-in", path: "/Webcheck" },
      ]}
    >
      <MainSection />
    </Layouts>
  );
}
