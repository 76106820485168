import React from "react";
import Layouts from "../../helpers/layoutsWeb";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Web() {
  return (
    <>
       {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Hotels and restaurant website builder | MicroGenn </title> */}
        <meta
          name="title"
          content="Hotels and restaurant website builder | MicroGenn"
        />
        <meta name="description" content="Boost your brand's online presence with Microgenn's website builder for hotels and restaurants. Responsive, SEO-friendly, and customizable for your needs." />
        <meta name="keywords" content="hotel website builder, restaurant website creator, affordable website design, F&B website maker, responsive hotel websites, SEO-friendly hotel websites, Microgenn website builder, online presence hotels, website templates hotels." />
      </Helmet>
    <Layouts
      pageTitle="Website Builder"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Website builder", path: "/hotel-and-restaurant-website-builder" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}