import React, { useEffect } from "react";
import logo2 from "../../../../assets/images/logos/logo.png";
import logo from "../../../../assets/images/logos/logo.png";
import StickyMenu from "../../../../lib/StickyMenu";
import Navigation from "../../../helpers/Navigation";
import MobileHeaderCom from "../../Mobile/MobileHeaderCom";
import { Link } from "react-router-dom";

function HeaderHomeOne() {
  useEffect(() => {
    StickyMenu(".main-header");
  });

  return (
    <>
      <MobileHeaderCom logo={logo2} />
      <header className="main-header header-one">
        <div className="header-top bg-lighter py-10">
          <div className="top-left">
            <ul>
              <li>
                <a href="callto:9566411119">Support : +919566411119</a>
              </li>
              <li>
                <a href="callto:8220311119">Sales : +918220311119</a>
              </li>
              <li>
                <a href="callto:9952511119">Partnership : +919952511119</a>
              </li>
             
             {/* } <li>Our address: 45 Dream street Austria</li> { */}
            </ul>
          </div>
          <div className="top-right">
            <div class="pr-2">
              <ul>
              <li>
                Email us: <a href="mailto:info@microgenn.com">info@microgenn.com</a>
              </li>
              </ul>
            </div>
            <div className="office-time">
              <i className="far fa-clock"></i>
              <span>09:30 am - 06:30 pm</span>
            </div>
            <div className="social-style-one">
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/microgennhms/">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a target="_blank" rel="noreferrer" href="https://mobile.twitter.com/microgennss">
                <i className="fab fa-twitter"></i>
              </a>
              <a target="_blank" rel="noreferrer" href="https://in.linkedin.com/company/microgennss">
                <i className="fab fa-linkedin"></i>
              </a>
              <a target="_blank" rel="noreferrer" href="https://in.pinterest.com/microgennsoftwaresolutions/">
                <i className="fab fa-pinterest-p"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="header-upper ">
          <div className="container-fluid clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer py-10 px-85">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="Logo" title="Logo" width="50px" />
                  </a>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex ">
                <Navigation className="nav-home-one" />
                
                <div className="menu-btn align-self-end">
                  <Link to="/contact" className="theme-btn">
                    meet with us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderHomeOne;
