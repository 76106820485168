import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/leisure.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li className="active">
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Leisure"
                /> */}
              </div>
              <h2>Leisure Management System</h2>
              <p>Covering all business aspects of planning, reservations, and service accounting,
                Microgenn offers many possibilities for tracking and keeping records of
                transactions and printing different reports and statistics. You can optimize your
                capacity occupancy and achieve your staff&#39;s efficiency, With advanced
                schedules and itemized statements. With Microgenn’s HMS integration, your
                hotel reception can simply enter the names of guests who are scheduled for on-
                site activities or spa appointments.
                {" "}
              </p>
              <p>Specifically designed to support the unique needs of the day spa and resort spa
                markets, which is a rapidly growing hospitality market. Microgenn leisure
                solution includes a number of seamlessly integrated, guest-centric modules. It
                provides staff with tools required to personalize your service, exceed guest
                expectations and anticipate guest needs. The full-featured spa management
                software coordinates all attractive offers and services.
              {" "}
              </p>
              <p>Microgenn leisure suite offers a full membership solution for small to medium
                size sports clubs, gyms, and fitness centres, even though your club is a single
                site or multi-complex organization. It offers an ideal solution for clubs in search
                of a wide range of member and administrative functions. It is made possible to
                easily check-in members, track their attendance, track inventory, create reports,
                administer payments and POS sales and manage your employee’s schedules
                from within the software.
              {" "}
              </p>
              <p>Microgenn leisure offers an integrated wellness management solution, user-
                friendly and it is easy to implement in hair salons, nail salons, tanning salons,
                beauty salons, day spas, and other service-oriented companies. The wellness
                management system covers all aspects of business-like scheduling, management
                reporting, and analysis.{" "}</p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Maintain records of transactions, reports, and statistics
                </li>
                <li>Provide predefined token based on selected activities
                </li>
                <li>Track inventory and create reports
                </li>
                <li>Administer payments and POS sales</li>
                <li>Manage employee schedules</li>
                <li>Wellness management system includes scheduling, managing, reporting, and analyzing</li>
                <li>Helps to personalize your service</li>
                <li>Optimize occupancy capacity</li>
                <li>Maintain and track audits for all the amenities.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
