import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
// import product from "../../../assets/images/products/central.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li     className="active">
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Crs"
                /> */}
              </div>
              <h2>Door Lock System</h2>
              <p>Considering the safety and privacy of all the guest staying is one of the important
                responsibilities if you are a hotel or resort owner, and one of the best ways to assure this is by
                integrating the Microgenn door lock system in every room. Modern security technology has
                provided hotels with wireless electronic door locks which can be opened using keycards and
                mobile credentials. The risks of lockpicking is removed and it also prevents unauthorized
                users from being privy to access codes. The door lock systems provide numerous benefits and
                possibilities for the safety and security of the guests. The entire operations process can be
                fastened and smoothened by installing wireless or electronic door lock systems for hotels.
                {" "}
              </p>
              <p>The hotel staff and employees will be convenient in the automated environment of the door
                lock mechanism. It also helps in cutting down operational costs. You can get the flexibility to
                grant your employees the right access to rooms for cleaning and maintenance purposes
                without traveling to their location and unlocking the door. With the door lock interface
                system, all the cleaning and maintenance staff including hotel desk staff will be able to
                complete their tasks more swiftly, efficiently, and quickly.
              {" "}
              </p>
              <p>Having a wireless electronic door lock interface allows your guests to check in and check out
                using their mobile devices, which will then become their keycards. It will eliminate the long
                waiting time and shorten the whole check-in process. You can also implement guest surveys
                to provide a more detailed and complete picture of guest satisfaction at your hotel, during the
                check-in process. The mobile registration process will also prevent your staff from becoming
                overwhelmed during the check-in and check-out times.
              {" "}
              </p>
              <p>Integrate your hotel’s technology with access control to ensure that the lights and electricity
                  turn on when guests enter their rooms and turn off when they leave the room. This can help
                  your hotel’s monthly costs to decrease significantly while making your hotel more sustainable
                  and eco-friendly.
              {" "}
              </p>
              <p>Monitoring the whole security feed and accessing the huge heaps of information for bigger
                and larger hotels can be a quite challenging task by using the traditional door locks
                mechanism. However, with an automated and electronic wireless door-locking system
                provided by Microgenn, you can manage your entire hotel and resort system with enhanced
                and increased security – overall on one single interface by further integrating with your hotel
                security systems. For easier identification of potential security incidents, door locks can be
                integrated with other security features such as a video camera feed. Having video analytics
                will make it possible to trigger alerts when incidents occur so that security staff will be able
                to monitor the security feed more effectively. Furthermore, in the event of an emergency
                evacuation, you will have a detailed account of the location of all guests to ensure that
                nobody is left inside the building.
              {" "}
              </p>

              <p>The possibilities are endless with Microgenn wireless electronic door lock interface.
                Installing wireless door locks in your hotel and integrating it with smarter technologies will
                allow you to make operations more convenient for both staff and guests. Your hotel will be
                much more secure, and you can cut operational costs.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Provides Security
                </li>
                <li>Enhanced Staff Productivity
                </li>
                <li>Better monitoring &amp; management
                </li>
                <li>Automatic deactivation of lost cards</li>
                <li>Automatic key cancellation after checkout</li>
                <li>Cardholder tracking</li>
                <li>Cost-effective</li>
                <li>Guest convenience</li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
