import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/central.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li     className="active">
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Crs"
                /> */}
              </div>
              <h2>Central Reservation System</h2>
              <p>Microgenn central reservation system manages all bookings and distribution
                from one place fast and easily. It maintains accounting accuracy, eliminates
                overbookings, minimizes reservation administration, automates operations in a
                single system, and maximizes customer engagement and reservations
                management.
                {" "}
              </p>
              <p>Seasonal prices, meals, and cancellation policy manages the highs and lows of
                demand. It is possible to promote sales and get fast and accurate information on
                products, prices, room availability, and service bookings. To expose facilities
                and develop new sales policies, understand the real picture of the marketing
                situation. Handle phone booking inquiries and provide existing and potential
                clients with relevant information swiftly via email. Use new-generation
                programs to grow your hotel business.
              {" "}
              </p>
              <p>Create financial, guest, and custom reports, sort them, configure payment
                methods and transactions, and maintain records. Gain access to important data
                like daily lists, statistics, and manager analytics. Make pricing plans
                manageable and vary them depending on the number of guests, seasons, age,
                broad types, included services, and more. Restrictions and cancellation policies
                can be changed at your discretion.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Handle multichannel bookings from tens of platform
                </li>
                <li>Track cancellations and other changes to bookings
                </li>
                <li>Plan to-do’s and distribute tasks among your staff
                </li>
                <li>Keep your accounting GDPR complaint</li>
                <li>Process online payments</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
