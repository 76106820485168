import React from "react";
import Layouts from "../helpers/Layouts";
import Hero from './Hero';

export default function PrivacyPolicy() {
  return (
    <>
      <Layouts
        pageTitle="Privacy Policy"
        breadcrumbs={[
          { name: "home", path: "/" },
          { name: "Privacy Policy", path: "/PrivacyPolicy" },
        ]}
      >
       <Hero />  
      </Layouts>
    </>
  );
}
