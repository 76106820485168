import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/irs.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li >
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li     className="active">
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>

            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Irs"
                /> */}
              </div>
              <h2>Internet Reservation System</h2>
              <p>The Internet reservation system is an online booking engine that allows guests
                to make reservations through the hotel website. It helps the hotels to accept
                bookings and to collect payment online. Internet reservation system helps to
                automate day-to-day operations and increase bookings. It is an online
                reservation system that processes reservations through the hotel website and
                manages online hotel room booking and availability.
                {" "}
              </p>
              <p>It provides instant confirmation for hotel bookings and increases customer
                experience. This online software allows guests to schedule the dates and lengths
                of their stay, select room types, and make payments. Nowadays, most travelers
                book hotels online. So, hotels are looking for an online system to maximize
                hotel revenues. It is a complete hotel booking IT solution that comes with a
                hotel quotation booking system for tour operators, hotel chains, and travel
                agents to collect the inventory of hotels from multiple sources to present the
                best prices to clients including channel managers and direct contracts.
              {" "}
              </p>
              <p>Find the best Hotel Booking System that simplifies hotel operations, maximizes
                online bookings, and increases efficiency. Microgenn offers the best hotel
                management software that provides the best reservation software to automate
                day-to-day hotel management operations and maximize revenues. It is a
                complete hotel management system that manages all operations in the hotel
                industry. It securely processes the internet reservation system through the
                hotel’s website.
              {" "}
              </p>
              <p>The hotel data can be accessed by managing bookings and providing instant
                confirmation. The Internet reservation system is easily integrated with the
                hotel’s website allowing online reservations and providing hoteliers with hotel
                channel management and resources to improve the experience of online
                booking. It allows hoteliers to provide the best options for amenities and hotel
                rooms along with online booking and payment options to improve the guest
                experience. {" "}</p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Hotel Search and Book Functionality
                </li>
                <li>Reservation Management
                </li>
                <li>Channel Management
                </li>
                <li>Hotel API Integration</li>
                <li>Back-office management</li>
                <li>Multiple-currency feature</li>
                <li>Multilanguage feature</li>
                <li>Payment Gateway Integration</li>
                <li>Mobile-responsive design</li>
                <li>Detailed Report</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
