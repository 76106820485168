import React from "react";
import Layouts from "../../helpers/Layoutsfo";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Fo() {
  return (
    <> 
       {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        <title>Front Office System for Hotels | MicroGenn</title>
        <meta
          name="title"
          content="Front Office System for Hotels | MicroGenn"
        />
        <meta name="description" content="Microgenn's advanced front office system for hotels, designed to streamline operations and enhance customer satisfaction with an intuitive user interface." />
        <meta name="keywords" content="Front Office System, Hotel Management Software, Hospitality Solutions, Hotel Operations, Hotel Chains, Intuitive Interface, Business Growth, Customer Satisfaction, Banquet Hotel." />
      </Helmet>
       <Layouts
      pageTitle="Frontoffice"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Frontoffice", path: "/hotel-front-office-software" },
      ]}
    >
      <MainSection />
    </Layouts>
</>
  );
}
