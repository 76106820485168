import BacktoTopComm from "../helpers/BackToTopComm";
import BacktoTopCom from "../helpers/BackToTopCom";
import SliderCom from "../helpers/SliderCom";
import FooterHomeOne from "../partials/Footers/FooterHomeOne";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne/index";
import AboutSection from "./AboutSection";

import Hero from "./Hero";
import ServiceSection from "./ServiceSection";
import { Helmet } from 'react-helmet';

function HomeOne() {
  return (
    <>
     {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Hotel management software | HMS - Microgenn Software Solutions
        </title> */}
        <meta
          name="title"
          content="Hotel management software | HMS - Microgenn Software Solutions"
        />
        <meta name="description" content="Microgenn’s ideal hotel management software for hotels, resorts, lodges, hostels, and apartments. Reduce complexity, get user-friendly solutions.

" />
        <meta name="keywords" content="hotel management software, linen management, payroll management software, task management, Leisure Management, Hospitality retail management, Hospltality Management Software, inventory management system, club management software, revenue management, employee management.
" />
      </Helmet>
    <div style={{ overflowX: "hidden" }}>
      <HeaderHomeOne />
      <Hero />
      <ServiceSection />
      <AboutSection />
      <FooterHomeOne />
      <BacktoTopCom />
      <BacktoTopComm />
      <SliderCom />
    </div>
 </> );
}

export default HomeOne;
