import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/travel.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li className="active">
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image ">
                {/* <img
                  src={
                    product
                  }
                  alt="Travel Desk"
                /> */}
              </div>
              <h2>Travel Desk Services</h2>
              <p>Employees must be within the reach of the guests to create a memorable
                experience, throughout their stay. Whether your employees are working behind
                the front desk, housekeeping department, or in the restaurant or café. Each
                employee should treat each hotel guest with the kind of hospitality that you
                would expect from a four-star luxury hotel.
                {" "}
              </p>
              <p>To keep guests coming back to the hotel trip after the trip, the hotel guests
                should have a great experience every time they stay at your hotel. This level of
                indulgence and attentiveness expands past the cleanliness of a room and the
                willingness to make a guest feel at home. A travel desk focuses on creating a
                memorable and remarkable experience for hotel guests. Bearing the desires and
                specific expectations in their mind.
              {" "}
              </p>
              <p>Guests should be able to plan their stay at the hotel with the help of the
                employees who are familiar with the area and all the tourist attractions and
                activities in the place. Experienced employees can assist guests in planning their
                itinerary from a travel desk. Consider advertising the service at your front desk,
                if your hotel does not have a travel desk dedicated solely to excursion planning
                and activities.
              {" "}
              </p>
              <p>A travel desk not only helps guests make the most of their trip, but it will also
                be able to remove the burden of planning. Whether guests are visiting from afar
                or are locals staying for a weekend getaway, they will appreciate this added
                benefit and will take advantage of the amenity.</p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Customize travel policy
                </li>
                <li>Customizable approval workflows
                </li>
                <li>Consolidated invoicing
                </li>
                <li>Tracking of user’s trips and bills</li>
                <li>Experienced employees can assist guests with itinerary</li>
                <li>Being in touch with guests throughout the journey</li>
                <li>Centralized information storage</li>
                <li>Organic travel corporate data and audit reports</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
