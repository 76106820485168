import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/feed.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li>
                    <a href="/Websitebuilder">Hotels and restaurant website builder</a>
                  </li> 
               
                  <li  >
                    <a href="/Fbcosting">F & B Costing</a>
                  </li>
                  <li  >
                    <a href="/Club">Club Management</a>
                  </li>
                
                  <li className="active">
                    <a href="/Guestfeedback">Guest Feedback</a>
                  </li>
                  
                  
                  <li>
                    <a href="/Kot">Tab-based KOT System</a>
                  </li>
                 
                  <li>
                    <a href="/Self">Self-ordering Restaurant QR code based</a>
                  </li>
                  <li>
                    <a href="/Webcheck">Web Check-in</a>
                  </li>
                 
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
               // style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-9566411119">+91-95664-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-30">
                {/* <img
                  src={
                    product
                  }
                  alt="Guest Feedback"
                /> */}
              </div>
              <h2>Hotel Guest Feedback</h2>
              <p>The feedback module collects feedback directly from customers without
                manual intervention and generates analysis from the received feedback. The
                feedback collected can enhance the guest experience by converting it into
                various corrective actions.
                {" "}
              </p>
              <p>
              By using built-in feedback templates included in the template designer tool,
                the guest feedback forms/questionnaires can be custom-built. The system
                dispatches a feedback form link as a part of the guest’s thank you e-mail
                when the guest checks out. By clicking on the feedback link, the guest can
                electronically complete the form and submit it via the internet. The
                completed feedback form will be received and stored in the property
                management system database as a part of the guest profile, without any user
                intervention.
              {" "}
              </p>
              <p>The feedback form can be accessed from the guest profile at any form and
                viewed. An analysis is provided for each feedback action based on the score.
                The negative feedback details are dispatched to the customer service
                personnel via email for corrective action. The user will have the ability to
                classify ratings as positive and negative ensuring that feedback is diligently
                captured and actioned.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Access the feedback form from the guest profile
                </li>
                <li>Negative feedback details dispatched to customer service personnel
                </li>
                <li>Feedback analysis is created
                </li>
                <li>Custom-built guest feedback forms</li>
                <li>Dispatch feedback link as a part of thank you mail, after check-out</li>
                <li>Guest can digitally complete feedback</li>
                <li>Storage of feedback in the database</li>
                <li>Integration of feedback with guest profiles</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
