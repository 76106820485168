import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/central.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li     className="active">
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Crs"
                /> */}
              </div>
              <h2>Swiggy Online Order</h2>
              <p>Microgenn offers seamless integrations with major online order aggregators like 
                Zomato. Manage all online orders from a single screen without the need to switch tabs or
                screens to accept orders. The addition of new dishes to the menu, updating prices, and
                launching of special occasion themed menu can be done hassle-free with Microgenn across
                the aggregator platforms, without manually changing everything. Microgenn also enables
                features like managing discounts and offering coupons for order aggregators.
                {" "}
              </p>
              <p>Through our home delivery module, you can track the delivery of orders made by customers
                and also track the operations team of the restaurant. To maintain the record of each home
                delivery and both feedback from customers and as well of the delivery boy, it comes
                integrated with CRM. Home delivery billing, packaging, and delivery charges, assigning of
                delivery boys, and capturing of client information can be done with Microgenn order
                integration.
              {" "}
              </p>
              <p>With the integrated POS application you can receive the orders from  Zomato,
              processing and discharge of order, generation of combined or segregated report of each
              aggregator which in turn simply and speed up the service operation by avoiding handling of
              multiple ordering panels. Through integrated POS, increase the order processing efficiency
              using consolidated ordering screen and also maintain customer records through CRM.
              {" "}
              </p>
              <p>
              Zomato is integrated with our cloud-based restaurant management system,
              which brings online orders from your website and mobile app, which in turn helps the F&amp;B
              establishments to manage the order integration platforms from our POS Software.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Reach out to a wider audience
                </li>
                <li>Accept orders from your own website and mobile application
                </li>
                <li>Collect feedbacks effortlessly
                </li>
                <li>Strengthen your online presence</li>
                <li>Promote your restaurant&#39;s events, promotions, and discounts easily</li>
                <li>Complete your Home Delivery Billing</li>
                <li>Manage packaging/delivery charges</li>
                <li>Capture Client information</li>
                <li>Assign Delivery Boys  Receive orders from Swiggy, Zomato directly in your POS</li>
                <li>Manage Processing and Discharge of delivery order From POS</li>
                <li>Generate Combined and Segregated Report for each aggregator</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
