import React from "react";
import Layouts from "../../helpers/LayoutsGuest";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Guestfeedback() {
  return (
    <>
       {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Hotel Guest Feedback | MicroGenn</title> */}
        <meta
          name="title"
          content="Hotel Guest Feedback | MicroGenn"
        />
        <meta name="description" content="Enhance guest experience with automated feedback collection and analysis. Customize forms, store feedback in the system, and take corrective actions seamlessly." />
        <meta name="keywords" content="guest feedback, feedback analysis, feedback module, automated feedback, guest experience, feedback form, customer service." />
      </Helmet>
    <Layouts
      pageTitle="Guest Feedback"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Guest Feedback", path: "/guest-feed-back" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
