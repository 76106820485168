import React from "react";
// import Layouts from "../../components/helpers/Layouts/index";
import Layouts from "../../helpers/Layouts/index";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Blog() {
  return (
<>
    {/* Helmet for SEO Metadata */}
    <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Hotel Management Systems: Shaping the Future of Hospitality</title> */}
        <meta
          name="title"
          content=" Boost Hotel Efficiency & Guest Satisfaction with Management Software"
        />
        <meta name="description" content=" Learn how hotel management software boosts efficiency by improving guest satisfaction, & optimizing key hotel services for better performance.
" />
        <meta name="keywords" content=" hotel management software, streamline operations, guest satisfaction, hotel efficiency, check-in process, personalized guest experience, revenue management, housekeeping optimization, hotel analytics, hospitality software
" />
      </Helmet>

   

    <Layouts
      pageTitle="Blog "
      breadcrumbs={[
        { name: "home", path: "/" },
        { name: "blog", path: "/blog/how-hotel-management-software-boosts-efficiency-and-guest-satisfaction" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}
