import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/task.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li   className="active">
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Task Management"
                /> */}
              </div>
              <h2>Task Management System</h2>
              <p>Microgenn task management module helps enhance overall guest experience
                and satisfaction by providing services like- automated task handling, complaint
                escalation, and request escalation.
                {" "}
              </p>
              <p>
              Automated task handling will provide the guest and/or staff the ability to
                register complaints. Automated alerts are sent to the attendant via SMS., once
                the complaint has been raised. This automated complaint management process
                ensures that the complaints are resolved effectively, clear visibility of staff
                performance levels and effective handling of staff.
              {" "}
              </p>
              <p>
                Automated complaint routing is provided to the shift-based staff based on their
                schedule and workload. Within the system, it is made possible to hold, reassign,
                manually reset and auto-restart complaints. Pending complaint details with
                effective detailed drill down are provided for time-efficient and easy operations.
                Maximum closure time is provided in the input to ensure that both complaints
                and requests are resolved within a certain time frame. If complaints remain
                unresolved, they will be escalated to multiple higher levels through
                programmed escalation rules. The programmed escalation rules will ensure the
                effective resolution of all requests.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Automated task handling
                </li>
                <li>Complaint escalation and request escalation
                </li>
                <li>Automated alerts integrated with SMS
                </li>
                <li>clear visibility of staff performance levels</li>
                <li>effective handling of staff</li>
                <li>Automated complaint routing is provided to staff based on their schedule and workload</li>
                <li>made possible to hold, reassign, manually reset and auto-restart complaints</li>
                <li>Pending complaint details are provided</li>
                <li>Complaints and requests are resolved within certain timeframe</li>
                <li>Unresolved complaints are escaladed to multiple higher levels</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
