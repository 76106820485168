import React from "react";
import Layouts from "../../components/helpers/Layouts/index";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function Blog() {
  return (
<>
    {/* Helmet for SEO Metadata */}
    <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Hotel Management Systems: Shaping the Future of Hospitality</title> */}
        <meta
          name="title"
          content=" Hotel Management Systems: Shaping the Future of Hospitality"
        />
        <meta name="description" content=" Explore how Hotel Management Systems are transforming the hospitality industry with advanced features, automation, and personalized guest experiences.
" />
        <meta name="keywords" content="Hotel Management System, HMS, hospitality technology, hotel automation, smart rooms, guest experience, hotel reservations, IoT, data-driven decisions, hospitality industry trends
" />
      </Helmet>

   

    <Layouts
      pageTitle="Blog "
      breadcrumbs={[
        { name: "home", path: "/" },
        { name: "blog", path: "/blog/hotel-management-systems-shaping-the-future-of-hospitality" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}
