import { useEffect } from "react";
import { useSelector } from "react-redux";
// Todo: for spacific page rtl disabled
// import { useLocation } from "react-router-dom";
import Routers from "./Routers";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  const { rtl } = useSelector((state) => state.rtl);
  const html = document.getElementsByTagName("html");
          
   // Todo: for spacific page rtl disabled
  // const location = useLocation();
  useEffect(() => {
    if (rtl) {
      html[0].dir = "rtl";
    } else {
      html[0].dir = "";
    }
    // Todo: for spacific page rtl disabled
    // if (location.pathname !== "/home-eight") {
    //   if (rtl) {
    //     html[0].dir = "rtl";
    //   } else {
    //     html[0].dir = "";
    //   }
    // }
  });

          
  
  return (
    <div className={rtl ? "direction-rtl" : ""}>
          
      <TawkMessengerReact
          propertyId="63f0af254247f20fefe1463d"
          widgetId="1gpi2oavv"/> 
      <Routers />   

    </div>
  );
}

export default App;
