import React from "react";
// import background from "../../../assets/images/contact/home-two.jpg";
import { useState } from "react";
import axios, * as others from 'axios';
import Swal from "sweetalert2";

export default function GetInTouchSection() {

  const [pname, setPname] = useState("");
  const [pemail, setPemail] = useState("");
  const [bname, setBname]= useState("");
  const [pmobile, setPmobile] = useState("");
  const [ptype, setPtype] = useState("");
  const [pmsg, setPmsg] = useState("");

  const addPartner = () => {

    var number = document.getElementById('number').value;
      var name = document.getElementById('name').value;
      var email = document.getElementById('email').value;
      var bname = document.getElementById('bname').value;
      
      if(number !='' && name !='' && email !='' && bname !='' && ptype != ''){
     
        axios.post('https://formspree.io/f/meqbbqpe', {
          pname : pname,
          pemail: pemail,
          bname : bname,
          pmobile:pmobile,
          ptype:ptype,
          pmsg: pmsg
    
        }).then(()=>{
          Swal.fire({
            title: "Success",
            text: "Thanks, We will Contact you Shortly.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000
          }).then(()=>{
            setBname(" ");
            setPemail(" ");
            setPmobile(" ");
            setPmsg(" ");
            setPname(" ");
            setPtype(" ");
          });
        });

      }
      else{
       
        Swal.fire({
          title: "Required",
          text: "Fill out All the rquired Fields",
          icon: "Warning",
          showConfirmButton: false,
          timer: 2000
        });
      }
   
  };

  return (
    <section className="contact-four py-120 rpy-100 section1"id="p_page ">
      <div className="container">
        <div className="contact-inner-four box-shadow p-35 rpx-25 br-5 bg-white">
          <div className="row">
            <div className="col-lg-7">
              <div className="contact-section-form px-15 rpx-0 rmb-50 wow fadeInUp delay-0-2s animated">
                <div className="section-title mb-20">
                  <h2>Get In <span id="color">Touch</span></h2>
                </div>
                <form
                  id="contact-form"
                  className="contact-form"
                  name="contact-form"
                  action="#"
                  method="post"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name">Your name</label>
                        <span className="spanco">*</span>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                          onChange={(event) =>{ setPname(event.target.value);}}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name">Bussiness name</label>
                        <span className="spanco">*</span>
                        <input
                          type="text"
                          id="bname"
                          name="bname"
                          className="form-control"
                          placeholder="Bussiness name"
                          required="required"
                          onChange={(event) =>{ setBname(event.target.value);}}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="email">Your email address</label>
                        <span className="spanco">*</span>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                          onChange={(event) =>{ setPemail(event.target.value);}}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="number">Your phone number</label>
                        <span className="spanco">*</span>
                        <input
                          type="text"
                          id="number"
                          name="number"
                          className="form-control"
                          placeholder="Phone Number"
                          required="required"
                          maxLength={10}
                          onChange={(event) =>{ setPmobile(event.target.value);}}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="service">Partnership Type</label>
                        <span className="spanco">*</span>
                        <select id="service" name="service" required="required" onChange={(event) =>{ setPtype(event.target.value);}}>
                          <option value="">
                            Choose ..
                          </option>
                          <option value="ChannelManager">Channel Partner</option>
                          <option value="Distributor">Sales Distributor</option>
                          <option value="CommissionBase">Commission Base</option>
                          <option value="Fransis">Franchiser</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <textarea
                          name="comments"
                          id="comments"
                          className="form-control"
                          rows="4"
                          placeholder="Let us know what you need."
                          onChange={(event) =>{ setPmsg(event.target.value);}}
                          required=""
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button type="button" className="theme-btn1" onClick={addPartner}>
                        Join Team
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="contact-image-number style-two bgs-cover overlay wow fadeInUp delay-0-4s animated"
                style={{
                 // backgroundImage: `url(${background})`,
                  visibility: "visible",
                  animationName: "fadeInUp",
                }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                        <a href="callto:+91-9790090010"><span>Partnership </span>: +91-99525-11119</a><br />
                        <a href="callto:+91-9566411119" ><span className="contactpadp3">Support </span> : +91-95664-11119</a><br />
                        <a href="callto:+91-8220311119"><span className="contactpadp1">Sales </span>: +91-82203-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                        <a href="mailto:info@microgenn.com" className="text-center">info@microgenn.com</a>{" "}
                        <a href="mailto:sales@microgenn.com" className="text-center">customersupport@microgenn.com</a>{" "}
                        <a href="mailto:sales@microgenn.com" className="text-center">sales@microgenn.com</a>{" "}
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 AM - 6:30 PM</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
