import React from "react";
import Layouts from "../../helpers/Layoutsfo";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Frontoffice() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
      <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Front Office System| MicroGenn Solutions</title> */}
        <meta
          name="title"
          content="Front Office System| MicroGenn Solutions"
        />
        <meta name="description" content="Manage hotel operations with ease using Microgenn's front office system. Features include quick check-in, reservation management, billing, etc." />
        <meta name="keywords" content="hotel front office software, front office system, check-in system, reservation management, billing system, occupancy management, GST reports, hotel locks integration, user-friendly, secure, hospitality software." />
      </Helmet>
    <Layouts
      pageTitle="Frontoffice"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Frontoffice", path: "/frontoffice" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}
