import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/point.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li  className="active">
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li>
                    <a href="/Bar">Bar</a>
                  </li>
                  
                  <li>
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li>
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li>
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li>
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-99525-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Frontoffice"
                /> */}
              </div>
              <h2>Point Of <span id="color">Sales</span> Software</h2>
              <p>Microgenn’s Point of sale software is meant for fine dining outlets, Cafes, Food
                courts and quick service restaurants, Bars, and Elite Bars. Our POS system
                interfaces with the front office system and its easy to use with minimal training.
                Creating bills by pulling up the table or room number from the system is quite
                simple.
                {" "}
              </p>
              <p>
                The settlements/bills are posted to the room automatically and are transferred to
                the accounting system of the hotel and its front office. The settlement methods
                include foreign currency, credit coupons &amp; plans, and paymaster. Support is
                provided for scenarios like billing of single tables or linked tables, bill
                cancellation, split bills, and split quantity.
              {" "}
              </p>
              <p>
                Microgenn’s POS system maintains a detailed guest profile, which ensures that
                details of repeat visits of guests are stored in history along with their
                preferences in order to provide them with personalized services. Before
                settlement bills, high security is provided to verify the guest and member’s
                signature and photos. A separate discount percentage is allotted for food &amp;
                beverages, liquor, and smoke. Corporate discounts, guest discounts, and
                member discounts are considered during bill preparation.
              {" "}
              </p>
              <p>
                A kitchen display system with integration of PDA/ handheld devices is used for
                taking new orders and also allows the restaurant managers to handle gift
                vouchers and promotions, loyalty programs, and payrolls. The web-based
                reporting feature helps with keeping track of all reports.<br></br>
                Our POS Software module consists of 4 modules namely:
                <ul className="list-style-three pt-15 pb-30">
                    <li>Point Of Sale.</li>
                    <li>Account Receivables.</li>
                    <li>Inventory.</li>
                    <li>Food &amp; Beverage Costing.</li>
                </ul>
                {" "}
              </p>
              <p>
                For any business to assess its performance, reports play a vital role.
                Microgenn’s POS has several reports which can be configured based on needs.
                It helps you obtain
                <ul className="list-style-three pt-15 pb-30">
                    <li>Table Wise Reports.</li>
                    <li>Caption Wise Reports.</li>
                    <li>Session Wise Reports.</li>
                    <li>Revenue Reports.</li>
                    <li>Guest Reward Reports.</li>
                    <li>Discount Reports.</li>
                    <li>Tax Reports.</li>
                    <li>Expense Reports.</li>
                </ul>
                {" "}
              </p>
              <p>
                Our Microgenn’s POS allows you to automate the transaction process and track
                important sales data. We have integrated a large number of modules into this
                software, that help you in tracking inventory changes, gross revenue, and sales
                patterns.
                {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Fine Dine In / Take Away &amp; Delivery.
                </li>
                <li>Table Live Status with Vacant, Occupied &amp; Billed.
                </li>
                <li>Table Transfer.
                </li>
                <li>Displaying Menu according to Item Category &amp; Item Group.</li>
                <li>Custom Menu &amp; Rate for Special occasions.</li>
                <li>KOT Printing with Item Instruction / Message.</li>
                <li>Non-Charge KOT.</li>
                <li>Item Group-wise Discount and User-wise Discount Restriction.</li>
                <li>Kitchen-wise KOT Printing.</li>
                <li>Kitchen Display System with Escalation Matrix SMS.</li>
                <li>Miscellaneous Sales/Income.</li>
                <li>Accounts Receivables.</li>
                <li>Happy Hours.</li>
                <li>Confirmation of services to guests Via SMS.</li>
                <li>Notification to the management of daily reports and updates via SMS.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
