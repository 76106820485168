import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/webc.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li>
                    <a href="/Websitebuilder">Hotels and restaurant website builder</a>
                  </li> 
                 
                  <li  >
                    <a href="/Fbcosting">F & B Costing</a>
                  </li>
                  <li  >
                    <a href="/Club">Club Management</a>
                  </li>
                  <li>
                    <a href="/Guestfeedback">Guest Feedback</a>
                  </li>
                  
                  
                  <li >
                    <a href="/Kot">Tab-based KOT System</a>
                  </li>
                 
                  <li>
                    <a href="/Self">Self-ordering Restaurant QR code based</a>
                  </li>
                  <li   className="active">
                    <a href="/Webcheck">Web Check-in</a>
                  </li>
                  
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-9566411119">+91-95664-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Web check-in"
                /> */}
              </div>
              <h2>Web Check-in</h2>
              <p>Your guests will receive an email with the registration link (or you can copy the
                link and send it via WhatsApp, SMS, etc). We will show your guests the full
                details of their reservation for confirmation. You can also get their email or
                phone number. We ask the guests to take a photo of their original ID or passport
                and to take a selfie. Our software will compare both photos to find similarities.
                {" "}
              </p>
              <p>Guests will read and sign the lease, by accepting the rules of the
                accommodation. Guests will be able to access the offers and experiences that
                you propose to them. This increases your income for each reservation. Once the
                process is completed, your guests will have the option to pay online safely,
                easily, and quickly.
              {" "}
              </p>
              <p>Web Check-in system brings out efficiency and convenience to the check-in
                process at your hotel. With seamless connectivity, web check-in is the next step
                in making your hotel more tech-savvy. From verifying the guest’s booking
                details, and uploading their documents to making an online payment, the Web
                Check-in system will take complete care of your guest’s check-in.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Check-in without waiting at the front office
                </li>
                <li>Email/SMS/WhatsApp integration to provide a registration link
                </li>
                <li>Enables customer to provide ID verification
                </li>
                <li>Verify booking confirmation details with web check-in details</li>
                <li>Secured online payment mode</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
