import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/material.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li className="active">
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Frontoffice"
                /> */}
              </div>
              <h2>Material Management System</h2>
              <p>Microgenn’s material management system is an all-inclusive tool that ensures
                the management of multiple stores and locations. It includes item-wise expiry
                inventory, re-order-based inventory, and minimum &amp; maximum stock control.
                {" "}
              </p>
              <p>Based on the receipt, the system compares the goods received with the purchase
                order that is generated. Material receipt return and receipt deletion are
                supported. It is possible to receive goods with cash purchase receipts. Materials
                consumed and food costing is maintained with the ability to revise receipt dates
                to later dates accurately. For effective costing, items issued are tagged with
                respective departments and cost centres. The material management options
                further include the physical inventory reconciliation for products, groups and
                subgroups, items nearing expiry and expired stock.
              {" "}
              </p>
              <p>The major challenge that materials managers face is maintaining a consistent
                flow of materials for production. There are many factors that inhibit the
                accuracy of inventory which results in production shortages. The most common
                issues that all materials managers face are incorrect bills of materials, shipping
                errors, receiving errors, production errors, etc. To overcome all these issues,
                microgenn’s material management system is a one-stop solution. A detailed
                view of audit trails and reports is maintained for goods requisition and issued
                items.
              {" "}
              </p>
              <p>A comprehensive Material management system can be defined as planning,
              coordinating, and controlling the flow of various activities. This will help you in
              the daily tasks of inventory management and material management through
              various modules. We are effective for all sizes of industries that are looking for
              a complete system that helps in tracking items etc. This system will automate all
              the processes related to the management of materials which include materials
              planning and control, production planning, purchasing, inventory control, stock
              maintenance, quality control, sales management, and logistics management. Our
              material management software is widely demanded by corporate houses,
              offices, factories, and small as well as big business organizations. {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Manage end-to-end inventory requirements.
                </li>
                <li>Direct communication with POS.
                </li>
                <li>Unparalleled efficiency works across departments.
                </li>
                <li>Role-based security.</li>
                <li>Advance multi-level access authentication.</li>
                <li>Interfaced with tally.</li>
                <li>UStock management options include expiry item-wise, re-order-based
                inventory, and minimum, and maximum stock control.</li> 
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
