import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/bar.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li  className="active">
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li>
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li>
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li>
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li>
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-30">
                {/* <img
                  src={
                    product
                  }
                  alt="Frontoffice"
                /> */}
              </div>
              <h2>Bar Management System
              </h2>
              <p>There are lots of ways to lose big money at the bar due to spills, overpouring,
                waste, free drinks, theft, and unrecorded sales are just a few of the common
                challenges that are present wherever liquor, beer, and wine are served. Starting
                from small bars to large hotels, resorts, and country clubs, our system has been
                used. In short, if you are struggling to manage your bar or club operation more
                effectively, Microgenn’s bar management system is a proven solution.
                  {" "}
              </p>
              <p>
              Our system creates perfect orders by calculating dynamic par levels from
              averaged historical product usage and ensuring that you never order too much or
              too little stock by keeping your inventory cash flow flowing. It systematically
              breaks down product usage into organized analytical reports, giving the data
              needed to make more focused business decisions.
              {" "}
              </p>
              <p>
              Microgenn’s Bar Management is loaded with extraordinary features which
              allow your bartenders to quickly serve customers and create a unique
              environment for your bar. We automate each hand-held operation to serve the
              customers in a better way. From taking orders to comprehensive analytics, our
              bar management allows you to control how much spirit is used in the bar.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Inventory everything that you sell.
                </li>
                <li>Inventory daily, weekly or monthly.
                </li>
                <li>Optional Inventory to counter wise.
                </li>
                <li>Add an unlimited number of products.</li>
                <li>View costs, usage, and variances. </li>
                <li>Calculate the product’s optimal pricing.</li>
                <li>Weigh open liquor, wine, and rum.</li>
                <li>Adjust happy hour and NC-Bills.</li>
                <li>Drink mixer – cocktail, and mocktails.</li>
                <li>Calculate potential sales and losses.</li>
                <li>Compare registered sales to actual.</li>
                <li>All for an affordable one-time price.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
