import React from "react";
import Layouts from "../../helpers/LayoutsMaterial";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Bar() {
  return (
    <>
    {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Material Management System for Hotel | Material Software </title> */}
        <meta
          name="title"
          content=" Material Management System for Hotel | Material Software "
        />
        <meta name="description" content="Microgenn’s offers material management system. Manage multiple stores, control stock, track expiry, and automate material processes efficiently." />
        <meta name="keywords" content="material management system, inventory control, stock management, expiry tracking, purchase order, material planning, inventory reconciliation, production management, logistics management, corporate inventory system." />
      </Helmet>
    <Layouts
      pageTitle="Material Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Material Management", path: "/Material-Management-System" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}