import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/kot.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li>
                    <a href="/Websitebuilder">Hotels and restaurant website builder</a>
                  </li> 
                 
                  <li  >
                    <a href="/Fbcosting">F & B Costing</a>
                  </li>
                  <li  >
                    <a href="/Club">Club Management</a>
                  </li>
                
                 
                  <li>
                    <a href="/Guestfeedback">Guest Feedback</a>
                  </li>
                  
                  
                  <li  className="active">
                    <a href="/Kot">Tab-based KOT System</a>
                  </li>
                 
                  <li>
                    <a href="/Self">Self-ordering Restaurant QR code based</a>
                  </li>
                  <li>
                    <a href="/Webcheck">Web Check-in</a>
                  </li>
              
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-9566411119">+91-95664-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image ">
                {/* <img
                  src={
                    product
                  }
                  alt="Kot"
                /> */}
              </div>
              <h2>Tab-based <span id="color">KOT</span> System</h2>
              <p>A Kitchen Order Ticket (KOT) is a document that is used to transfer a diner’s
                order to the kitchen, and it contains details such as items ordered table number,
                and quantities with diner requests for cooking/serving. Three copies are made
                from which the first is sent to the kitchen, the second is retained for billing and
                the third is with the waiter for reference.
                {" "}
              </p>
              <p>An automated KOT system generates real-time order reports, saves time &amp;
                effort, and is easy to use. It can be managed by an app on your mobile via POS
                software that comes in touchscreen format. Food orders are accepted directly on
                the POS system from different ordering platforms and a ticket is generated
                immediately in the kitchen. This ensures that orders are not delayed or missed
                and also minimizes discrepancies. A token number is also generated for easy
                tracking, where you can keep a tab on order duplication and can cut down on
                mistaken deliveries.
              {" "}
              </p>
              <p>A POS is a valuable and low-investment asset for your restaurant. Centralized
                control over accounting, billing, and other order tasks is obtained with an
                integrated POS system. It also helps you manage franchises or chains of your
                restaurant. These systems are placed at the service desk for ease of access. It can
                be integrated with online payment systems, digital menus, receipt printers, and
                more as its software works on different devices. The waiter just has to punch in
                the order and a KOT has generated automatically. The chefs in the kitchen are
                notified instantly about new orders as the data capture happens in real time.
                Hence, it reduces manual time.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Integrates with online ordering
                </li>
                <li>Ticket generation for each order
                </li>
                <li>Generation of token number for easy tracking
                </li>
                <li>Provides real-time reports</li>
                <li>Reduces manual ordering method</li>
                <li>Integrated with payment system, digital menu, and receipt printers</li>
                <li>Easy to use the module & Time consumption</li>
                <li>Reduces ordering discrepancies</li>
               
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
