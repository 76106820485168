import React from "react";
import Layouts from "../../helpers/LayoutsHouse";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 
export default function Bar() {
  return (
    <>
      {/* Helmet for SEO Metadata */}
      <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title> Housekeeping Software for Hotel Management | MicroGenn</title> */}
        <meta
          name="title"
          content="Housekeeping Software for Hotel Management | MicroGenn"
        />
        <meta name="description" content="Optimize hotel cleanliness with Microgenn's housekeeping software. Track room status, manage staff, handle laundry, and sync with front office seamlessly." />
        <meta name="keywords" content="housekeeping software, hotel housekeeping, hotel software, hotel management software, hospitality software, hotel booking software, hotel housekeeping services, hotel front desk software, cloud-based hotel management software." />
      </Helmet>
    <Layouts
      pageTitle="House Keeping"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "House Keeping", path: "/housekeeping" },
      ]}
    >
      <MainSection />
    </Layouts>
    </>
  );
}