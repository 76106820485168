import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/whatsapp.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="#">Wifi Interface</a>
                  </li>
                <li >
                    <a href="/Sms">E-Alert SMS</a>
                  </li>
                  <li>
                    <a href="/Email">E-Alert Email</a>
                  </li>
                  <li  className="active">
                    <a href="/Whatsapp">E-Alert WhatsApp</a>
                  </li>
                  
                  <li>
                    <a href="/Hotelchannel">Hotel Channel Manager</a>
                  </li>
                
                  
                  <li>
                    <a href="/Tally">Tally Interface</a>
                  </li>

                  <li >
                    <a href="#">Door lock</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-822031119">+91-822031119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-30">
                {/* <img
                  src={
                    product
                  }
                  alt="Whatsapp Integration"
                /> */}
              </div>
              <h2> E-Alert Whatsapp | WhatsApp Business API Integration</h2>
              <p>In recent times WhatsApp business for hotels gained good traction in recent
                times. It seems to be a good choice for several types of industries with specific
                features like business profiles, automatic greeting messages, automatic away
                messages, quick replies, message statistics, catalogues, contact labels, etc.
                WhatsApp business doesn’t solely work all the time for hotel businesses as
                communications happen on a wider level. Hence, accommodation facilities need
                additional capabilities to enhance customer experience and operational
                efficiency.
                {" "}
              </p>
              <p>WhatsApp Business API integration comes to play in hotel businesses.
                Application Programming Interface (API) for WhatsApp business integrates
                with the hotel management system and takes it to a whole new dimension.
              {" "}
              </p>
              <p>After integrating WhatsApp for hotels with the property management system,
                you get timely messages and notifications regarding successful bookings,
                cancelled bookings, rescheduled bookings and the ones that are on hold.
                Reports allow hotel owners to take a deeper look at all the happenings of the
                hotel and to make informed decisions.
              {" "}
              </p>
              <p>Keeping yourself updated will all the reports helps to know the performance of
                your accommodation and how to make things great. It is possible to opt for
                getting reports that are important to you. The sole purpose of WhatsApp is to
                help individuals and businesses communicate better and to keep guests updated
                with details about their stay.{" "}
              </p>
              <p>In terms of communicating with staff, it is about keeping them updated with
                tasks, shifts, etc. WhatsApp helps to share catalogues, and payment links and
                reply to queries of customers. An integrated WhatsApp Business account allows
                seeing analytics report about account activity.{" "}</p>
              <p>The integration helps run real-time monitoring of messages, and spending
                analytics, view metrics of all conversations, and check graphs to learn about
                how much metric is performing across time. For WhatsApp marketing, it is
                possible to schedule messages according to the broadcast list and timeline.{" "}</p>
              
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Automate notifications via WhatsApp API
                </li>
                <li>Superior customer experience
                </li>
                <li>Getting started, set up a business profile and connecting via WhatsApp API
                </li>
                <li>Strong Connections with customers</li>
                <li>Increase brand loyalty</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
