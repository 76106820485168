import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/fo.jpg";


export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                  <li  className="active">
                    <a href="/FO">Frontoffice</a>
                  </li> 
                  
                  <li>
                    <a href="/Barm">Bar</a>
                  </li>
                  
                  <li>
                    <a href="/Web">Hotels And Restaurant Website Builder</a>
                  </li>
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-9952511119">+91-99525-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
             
              <h2>Front Office System</h2>
              <p >Microgenn offers a gamut of software solutions that specifically fulfill
                every requirement of the hospitality industry. Microgenn develops
                solutions based on the complexity and correlation of various functions in
                hospitality. We reckon on the fact that software solutions should simplify
                your business process by helping you to focus on the issues of business
                growth and customer satisfaction.
                {" "}
              </p>
              <p>
              Microgenn front office system is a futuristic management system that
              facilitates hotels and hotel chains to manage business operations in an
              effective and efficient manner. To meet international standards, we have
              designed and developed using the latest technology.
              {" "}
              </p>
              <p>
              The simple Graphical User Interface and features ensure seamless
              understanding and adaptation to the solution. Microgenn is perfectly
              capable to meet the specific requirements of our clients, whether you are
              an individual banquet hotel or a multi-chain property. Its easy-to-use
              features and simple Graphical User Interface ensure learning and adapting
              the solution is effortless Whether you are an individual boutique hotel or a
              multi-chain property.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Quick Check-in
                </li>
                <li>Group Check-in
                </li>
                <li>Reservation Check-in
                </li>
                <li>Walk-in</li>
                <li>Checkout </li>
                <li>Group checkout</li>
                <li>Group Transfer</li>
                <li>Partial Checkout</li>
                <li>Split Bill</li>
                <li>Advance &amp; Bill Posting</li>
                <li>Color-Based Room Status</li>
                <li>Auto Rate positioning based on the Occupancy</li>
                <li>Occupancy Ratio and Position</li>
                <li>GST Ready Reports</li>
                <li>Integration with Any Accounting Software</li>
                <li>Greetings and notification to the Guest and Management</li>
                <li>Restrict Unauthorized Entry into the Room</li>
                <li>Mobile App for the Revenue, Room Position, and Collection</li>
                <li>Comparison Reports</li>
                <li>User-friendly operation</li>
                <li>Unbreakable security structure (Multi-level User rights escalation matrix)</li>
                <li>Integration with All leading Channel managers</li>
                <li>Integration with All leading hotel Locks</li>
                <li>Room type and Rack Rate Tariff based Booking.</li>
                <li>A complete reservation management system.</li>
                <li>Cashiering – Handles Various Transactions (Advance, checkout, Refund).</li>
                
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}
