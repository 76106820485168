import React from "react";

function FeatureSection() {
  return (
    <section className="featured-section bgs-cover pt-100 rpt-150 pb-20 rpb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="section-title text-center">
              <span className="sub-title">One-step ideal software solution for your hotels</span>
              <h2>Our <span id="color">Services</span></h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
