import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/fb.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li>
                    <a href="/Websitebuilder">Hotels and restaurant website builder</a>
                  </li> 
                
                  <li    className="active">
                    <a href="/Fbcosting">F & B Costing</a>
                  </li>
                  <li>
                    <a href="/Club">Club Management</a>
                  </li>
                 
                  <li>
                    <a href="/Guestfeedback">Guest Feedback</a>
                  </li>
                  
                  
                  <li>
                    <a href="/Kot">Tab-based KOT System</a>
                  </li>
                 
                  <li>
                    <a href="/Self">Self-ordering Restaurant QR code based</a>
                  </li>
                  <li>
                    <a href="/Webcheck">Web Check-in</a>
                  </li>
                
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-9566411119">+91-95664-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-30">
                {/* <img
                  src={
                    product
                  }
                  alt="Frontoffice"
                /> */}
              </div>
              <h2>F & B Costing System</h2>
              <p>The F&amp;B costing module is an add-on to banquet modules and customer-facing
                POS. The accounting menu is integrated so that all costs and expenses are
                tightly integrated and tracked. F&amp;B covers recipe management, costing covers,
                banquet costing, menu engineering, BOQ, RFQ, purchase requisition, price
                comparison and yield management.
                {" "}
              </p>
              <p>
                Detailed recipe creation can be created for menu items for outlets and banquets,
                with pictures and preparation instructions. Support is provided for ingredient
                creation and its can be mentioned in multiple recipes. Recipes can be created
                with preparation time, yield, portion size, menu price, standard wastage,
                recommended menu price and cost per portion. Market price recipe costing is
                based on the store rate/current purchase and the menu rates can be adjusted to
                market rates.
              {" "}
              </p>
              <p>
                Automated purchase requisitions can be tied to recipe ingredients in case of
                banquet functions. The advanced analysis will include menu engineering
                analysis with contribution margin, menu class, menu item evaluation, menu
                margin, and menu mix. Consumption-based costing is based on opening
                balance, adjustments, issues, closing balance, plan sales, and POS sales. It
                provides net profit and gross profit for each product group for specific days and
                periods. Costing analysis is displayed based on outlet-wise and cost centre wise
                views. Revenue analysis is made available for every completed function.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Costs and expenses are tightly integrated and tracked
                </li>
                <li>Covers Price comparison and price requisition
                </li>
                <li>Tracks yield & Waste management
                </li>
                <li>Keep records of raw materials purchased</li>
                <li>Production cost is tracked</li>
                <li>Market price recipe costing is based on the store rate/current purchase</li>
                
                            
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
