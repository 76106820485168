import React from "react";
import teams from "../../../data/Teams.json";
import TeamSection from "../../HomeSix/TeamSection";
import TeamLayout from "../TeamLayout";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet'; 

export default function TeamFour() {
  return (
<>
  {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Meet Our Team | MicroGenn</title> */}
        <meta
          name="title"
          content="Meet Our Team | MicroGenn"
        />
        <meta name="description" content="Explore our talented team members. Get to know the faces behind our success. Meet our colleagues today!" />
        <meta name="keywords" content="Team members, MicroGenn, Meet Our Team." />
      </Helmet>
    <TeamLayout
      pageTitle="Team"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Meet our colleague", path: "team" },
      ]}
    >
      <TeamSection teams={teams} className="pb-50" />
      <MainSection />
    </TeamLayout>
    
 </> );
}
