import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/purchase.jpg";


export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li>
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li  className="active">
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li>
                    <a href="/Material">Material Management</a>
                  </li>
                  <li>
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li>
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Frontoffice"
                /> */}
              </div>
              <h2>Purchase Management Software</h2>
              <p>Microgenn purchasing module is a well-organized procurement process based
                on intuitive features and functions. Tighter cost control and inefficiency
                elimination are achieved using automated features and reporting functionality.
                {" "}
              </p>
              <p>Purchase orders (PO) and purchase requisitions (PR) are segregated into various
                cost centres and departmental users through multiple approval levels. The
                purchase requisitions for daily bazaar purchases are received directly by Goods
                Received Note (GRN) without the need for a purchase order. Based on daily
                PR, the quantities and cost centres are validated within GRN and rate contract
                agreement within the system is used for rate nomination. the daily item
                quantities are displayed kitchen-wise for better quantity and control
                management.
              {" "}
              </p>
              <p>Time consumption can be eliminated by pre-defining PR templates for items
                that are required on daily basis. The ability to dispatch PO via email, helps
                maintain the entire system to be a paperless environment. To cover blanket
                agreement orders, and regular and service orders, comprehensive supplier
                management is included. Functionality is provided to group multiple purchase
                requisitions into a single purchase order and budgeting is provided for enabling
                cost control of both PR and PO. Budget analysis is provided to display against
                the budgeted amounts on a monthly and yearly basis.
              {" "}
              </p>
              <p>All the transactions can be viewed, tracked, and reviewed by extensive
                reporting and analysis. Audit reports will be provided for purchase order
                creation, amendment, and cancellation. Existing rate edits/deletions and new
                rate creations are audited. Automated emails are created for certain workflow
                scenarios like approval of PR, reminders, notification to a concerned part when
                PR/PO is approved and addition/deletion of remarks again PR/PO.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Purchase request creation
                </li>
                <li>Short close- purchase request
                </li>
                <li>Purchase order
                </li>
                <li>Purchase order amend</li>
                <li>Short close/cancel PO</li>
                <li>Bulk close-PO</li>
                <li>Management, Production control</li>
                <li>Production planning</li>
                <li>Efficient holding of bill of materials and range</li>
                <li>Provisions and treatment of stock exception</li>
                <li>Integrated scheduler</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
