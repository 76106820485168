import React from "react";


export default function TeamSection({ teams, className }) {
  return (
    <>
      <section
        className={`team-section bg-lighter text-center pt-65 rpt-95 pb-50 rpb-70 ${
          className || ""
        }`}
      >
        <div className="container">
          
          <div className="row">
              <div  className="col-lg-3 col-sm-6">
                <div className="team-member-four wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={
                        require(`../../assets/images/team/senthinayagam.jpg`)
                          
                      }
                      alt="Team Member"
                    />
                    <div className="social-style-two">
                        <a href="http://facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="http://twitter.com">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                  </div>
                  <div className="member-designation">
                    <h5>
                      <a href="/team-single">SenthilNayagam</a>
                    </h5>
                    <span>CEO & Founder</span>
                  </div>
                </div>
              </div>
              <div  className="col-lg-3 col-sm-6 ">
                <div className="team-member-four wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={
                        require(`../../assets/images/team/Padmanaban.jpg`)
                          
                      }
                      alt="Team Member"
                    />
                    <div className="social-style-two">
                        <a href="http://facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="http://twitter.com">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                  </div>
                  <div className="member-designation">
                    <h5>
                      <a href="/team-single">Padmanaban</a>
                    </h5>
                    <span>Sales Manager</span>
                  </div>
                </div>
              </div>
              <div  className="col-lg-3 col-sm-6">
                <div className="team-member-four wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={
                        require(`../../assets/images/team/arunkumar.jpg`)
                          
                      }
                      alt="Team Member"
                    />
                    <div className="social-style-two">
                        <a href="http://facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="http://twitter.com">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                  </div>
                  <div className="member-designation">
                    <h5>
                      <a href="/team-single">Arun Kumar</a>
                    </h5>
                    <span>Project Manager</span>
                  </div>
                </div>
              </div>
              <div  className="col-lg-3 col-sm-6">
                <div className="team-member-four wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={
                        require(`../../assets/images/team/suganraj.jpg`)
                          
                      }
                      alt="Team Member"
                    />
                    <div className="social-style-two">
                        <a href="http://facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="http://twitter.com">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                  </div>
                  <div className="member-designation">
                    <h5>
                      <a href="/team-single">Suganraj</a>
                    </h5>
                    <span>Adminstrator</span>
                  </div>
                </div>
              </div>
              {/* <div  className="col-lg-3 col-sm-6 ">
                <div className="team-member-four wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={
                        require(`../../assets/images/team/team1.jpg`)
                          
                      }
                      alt="Team Member"
                    />
                    <div className="social-style-two">
                        <a href="http://facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="http://twitter.com">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                  </div>
                  <div className="member-designation">
                    <h5>
                      <a href="/team-single">Daniel</a>
                    </h5>
                    <span>frontend developer</span>
                  </div>
                </div>
              </div>
              <div  className="col-lg-3 col-sm-6 ">
                <div className="team-member-four wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={
                        require(`../../assets/images/team/team1.jpg`)
                          
                      }
                      alt="Team Member"
                    />
                    <div className="social-style-two">
                        <a href="http://facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="http://twitter.com">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                  </div>
                  <div className="member-designation">
                    <h5>
                      <a href="/team-single">Daniel</a>
                    </h5>
                    <span>frontend developer</span>
                  </div>
                </div>
              </div>
              <div  className="col-lg-3 col-sm-6 ">
                <div className="team-member-four wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={
                        require(`../../assets/images/team/team1.jpg`)
                          
                      }
                      alt="Team Member"
                    />
                    <div className="social-style-two">
                        <a href="http://facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="http://twitter.com">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                  </div>
                  <div className="member-designation">
                    <h5>
                      <a href="/team-single">Daniel</a>
                    </h5>
                    <span>frontend developer</span>
                  </div>
                </div>
              </div>
              <div  className="col-lg-3 col-sm-6 ">
                <div className="team-member-four wow fadeInUp delay-0-2s">
                  <div className="image">
                    <img
                      src={
                        require(`../../assets/images/team/team1.jpg`)
                          
                      }
                      alt="Team Member"
                    />
                    <div className="social-style-two">
                        <a href="http://facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="http://twitter.com">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                  </div>
                  <div className="member-designation">
                    <h5>
                      <a href="/team-single">Daniel</a>
                    </h5>
                    <span>frontend developer</span>
                  </div>
                </div> 
              </div>*/}
            </div>
          {/* <img
            src={
              require(`../../assets/images/team/teamm.jpg`)
                
            }
            alt="Team Member"
          />
            <DataIteration datas={teams} startLength={0} endLength={4}>
              {({ datas }) => (
                <div key={datas.name} className="col-lg-3 col-sm-6">
                  <div className="team-member-four wow fadeInUp delay-0-2s">
                    <div className="image">
                      <img
                        src={
                          require(`../../assets/images/team/${datas.profile_photo}`)
                            .default
                        }
                        alt="Team Member"
                      />
                      <div className="social-style-two">
                        <a href="http://facebook.com">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="http://twitter.com">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                    <div className="member-designation">
                      <h5>
                        <a href="/team-single">{datas.name}</a>
                      </h5>
                      <span>{datas.designation}</span>
                    </div>
                  </div>
                </div>
              )}
            </DataIteration> */}
          {/* </div>*/}
        </div> 
      </section>
    </>
  );
}
