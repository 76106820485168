import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/power.jpg";

export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li >
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li    className="active">
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                   product
                  }
                  alt="Power Automation"
                /> */}
              </div>
              <h2>Power Automation System</h2>
              <p>The power automation system is a system for managing, controlling, and
                protecting the various components connected to the power network. It obtains
                real-time information from the system and local and remote control applications
                with advanced electrical system protection.
                {" "}
              </p>
              <p>Our microgenn’s power automation system enables hoteliers to provide their
                guests the convenience of controlling multiple functions in their room including
                lighting, temperature and audio/video systems using automated interfaces.
              {" "}
              </p>
              <p>Our power automation system automatically turns off all electrical appliances
                when the room is empty, reactivating only when the guest enters the room. This
                enables our customers to experience a high return on investment through
                improved operational efficiencies, reduced energy costs and effective
                management of resources.
              {" "}
              </p>
              <p>Hotel room automation uses a variety of techniques to turn off lights and reduce
                HVAC operation when a room is unoccupied in hotels and service motels.
                Because hotel and motel guests typically do not practice good energy
                conservation behavior when checked into a room, there is a significant
                opportunity for the lodging industry to capture the energy and other savings
                through automation. {" "}</p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Automated report generation of power on time &amp; off time for every room.
                </li>
                <li>Centralized Online Controls for Power Automation.
                </li>
                <li>Air conditioner control makes the room check-in an Ac Enabled room / Non-Ac room.
                </li>
                <li>Phase failure, MCB tripping is highlighted via SMS ensuring check-in for room power access.</li>
                <li>Power On / Off control and Monitoring will be handled from your smartphone using our APP. </li>               
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
