import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/asset.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li   className="active">
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li>
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-20">
                {/* <img
                  src={
                    product
                  }
                  alt="Asset Management"
                /> */}
              </div>
              <h2>Digital Menu Digital System</h2>
              <p>Microgenn digital menu display system helps in the process of finding and ordering what the
                customers want, which plays a major role in customer satisfaction at restaurants. Statistics
                show that an easy to read menu is one of top priority for customers who tend have a good
                experience. Digital menus makes it clear for understanding what the customers want to order.
                Guests can clearly see the items, its pricing and also its nutrition information, with digital
                menu board. These display systems are now replacing the static menu.
                {" "}
              </p>
              <p>At restaurants, guests have reported that digital menus influence their purchasing decisions.
                You can display new items, limited time offering deals, signature dishes that can enhance the
                social media reviews and the restaurant popularity. Updating menu items, pricing and other
                content is made simple and easy to use and also provides an opportunity to upsell and to
                increase the restaurant revenue.
              {" "}
              </p>
              <p>Eye catching digital menu display system makes every day menu items look amazing,
                tempting guests to add more items to their order which in turn increases sales. More sales are
                generated as more people learn about your product, increasing your profit margins. The
                content changing in digital menu is easier as there is no need for printing, shipping and
                installation. Promotions can be done at lower cost as it eliminates the printing costs for menu
                updates, promotions and menu item launches. It saves time for employees as they can focus
                on providing excellent customer service without updating menu boards, which can create
                potential cost savings.
              {" "}
              </p>
              <p>
              Instead of depending on different resources every time to make an update, it can be done by a
              single person within few minutes. The digital display system is more visually appealing than
              printed menus. Customers can be retained by posting video images of satisfied customers or
              by posting daily deals. Films and animations in digital displays can attract user’s attention.
              {" "}
              </p>
              <p>
              Digital menus are a one time investment, which will save you money in the long run by
              reducing operational expenses leading to long term financial benefit. Employees might
              overlook or neglect the changes to static menu boards. Microgenn digital display system
              ensures that all restaurant locations are up to date. If the restaurant has numerous branches,
              the same level of service will be provided to all outlets. As your competitors move along with
              rapid advancements in technology, you will be losing clients by using static menu boards.
              Switching to digital menu will enhance and develop your business to next level.
                {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Infotainment for guests
                </li>
                <li>Improved Menu Visibility
                </li>
                <li>Low costs for promotions
                </li>
                <li>Upsell and Market your New Products</li>
                <li>Menus That Can Be Modified</li>
                <li>Lower Prices, Greater Profits</li>
                <li>Useful and convenient</li>
                <li>Employee time savings</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
