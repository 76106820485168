import React from "react";
import './ServiceSection.css';

function ServiceSection() {

  function classChange(){
    document.getElementById('roles1').classList.add('rolesac');
    for(let i=1; i<=8; i++){
      if(i !=1){
        document.getElementById('roles'+i).classList.add('roles');
        document.getElementById('roles'+i).classList.remove('rolesac');
      }      
    }
    for(let i=1; i<=8; i++){
      if(i ==1){
        document.getElementById('btn'+i).classList.remove('rolesac');
        document.getElementById('btn'+i).classList.add('roles'); 
      }
      else{

        document.getElementById('btn'+i).classList.remove('roles');
        document.getElementById('btn'+i).classList.add('rolesac');
      }
    }
  }
  function classChange1(){
    document.getElementById('roles2').classList.add('rolesac');
    for(let i=1; i<=8; i++){
      if(i !=2){
        document.getElementById('roles'+i).classList.add('roles');
        document.getElementById('roles'+i).classList.remove('rolesac');
      }      
    }
    for(let i=1; i<=8; i++){
      if(i ==2){
        document.getElementById('btn'+i).classList.remove('rolesac');
        document.getElementById('btn'+i).classList.add('roles'); 
      }
      else{

        document.getElementById('btn'+i).classList.remove('roles');
        document.getElementById('btn'+i).classList.add('rolesac');
      }
    }
  }
  function classChange2(){
  
    for(let i=1; i<=8; i++){
      document.getElementById('roles3').classList.add('rolesac');
      if(i !=3){
        document.getElementById('roles'+i).classList.add('roles');
        document.getElementById('roles'+i).classList.remove('rolesac');
      }      
    }
    for(let i=1; i<=8; i++){
      if(i==3){
        document.getElementById('btn'+i).classList.remove('rolesac');
        document.getElementById('btn'+i).classList.add('roles'); 
      }
      else{

        document.getElementById('btn'+i).classList.remove('roles');
        document.getElementById('btn'+i).classList.add('rolesac');
      }
    }
  }
  function classChange3(){
    document.getElementById('roles4').classList.add('rolesac');
    for(let i=1; i<=8; i++){
      if(i !=4){
        document.getElementById('roles'+i).classList.add('roles');
        document.getElementById('roles'+i).classList.remove('rolesac');
      }      
    }
    for(let i=1; i<=8; i++){
      if(i ==4){
        document.getElementById('btn'+i).classList.remove('rolesac');
        document.getElementById('btn'+i).classList.add('roles'); 
      }
      else{

        document.getElementById('btn'+i).classList.remove('roles');
        document.getElementById('btn'+i).classList.add('rolesac');
      }
    }
  }
  function classChange4(){
    document.getElementById('roles5').classList.add('rolesac');
    for(let i=1; i<=8; i++){
      if(i !=5){
        document.getElementById('roles'+i).classList.add('roles');
        document.getElementById('roles'+i).classList.remove('rolesac');
      }      
    }
    for(let i=1; i<=8; i++){
      if(i ==5){
        document.getElementById('btn'+i).classList.remove('rolesac');
        document.getElementById('btn'+i).classList.add('roles'); 
      }
      else{

        document.getElementById('btn'+i).classList.remove('roles');
        document.getElementById('btn'+i).classList.add('rolesac');
      }
    }
  }
  function classChange5(){
    document.getElementById('roles6').classList.add('rolesac');
    for(let i=1; i<=8; i++){
      if(i !=6){
        document.getElementById('roles'+i).classList.add('roles');
        document.getElementById('roles'+i).classList.remove('rolesac');
      }      
    }
    for(let i=1; i<=8; i++){
      if(i ==6){
        document.getElementById('btn'+i).classList.remove('rolesac');
        document.getElementById('btn'+i).classList.add('roles'); 
      }
      else{

        document.getElementById('btn'+i).classList.remove('roles');
        document.getElementById('btn'+i).classList.add('rolesac');
      }
    }
  }
  function classChange6(){
    document.getElementById('roles7').classList.add('rolesac');
    for(let i=1; i<=8; i++){
      if(i !=7){
        document.getElementById('roles'+i).classList.add('roles');
        document.getElementById('roles'+i).classList.remove('rolesac');
      }      
    }
    for(let i=1; i<=8; i++){
      if(i ==7){
        document.getElementById('btn'+i).classList.remove('rolesac');
        document.getElementById('btn'+i).classList.add('roles'); 
      }
      else{

        document.getElementById('btn'+i).classList.remove('roles');
        document.getElementById('btn'+i).classList.add('rolesac');
      }
    }
  }
  function classChange7(){
    document.getElementById('roles8').classList.add('rolesac');
    for(let i=1; i<=8; i++){
      if(i !=8){
        document.getElementById('roles'+i).classList.add('roles');
        document.getElementById('roles'+i).classList.remove('rolesac');
      }      
    }
    for(let i=1; i<=8; i++){
      if(i ==8){
        document.getElementById('btn'+i).classList.remove('rolesac');
        document.getElementById('btn'+i).classList.add('roles'); 
      }
      else{

        document.getElementById('btn'+i).classList.remove('roles');
        document.getElementById('btn'+i).classList.add('rolesac');
      }
    }
  }

  return (
    <section className="services-section career-service pt-120 rpt-100 pb-90 rpb-70" id="career">
      <div className="container" >
        <div className="roww">
          <div className="col-sm-10">
              <div >
                <h3 >Support Engineer</h3>
                <p>
                 Job Role: Support Engineer Eligibility: Any Degree, Experience: 0-3 Years
                </p>
              </div>
              <div className="roles" id="roles1">
                <h4 className="subhead">Roles and Responsibility</h4>
                <ul className="list-style-three pt-15 pb-30 pl-30">
                  <li >Providing customers with IT support via desktop solutions, chat functions, emails, and calls.</li>
                  <li>Assisting customers with account setup and application installations.</li>
                  <li>Manage customer expectations and communicate thoroughly through tickets and calls.</li>
                  <li>Troubleshooting IT issues by asking customers targeted questions.</li>
                  <li>Providing customers with step-by-step guidance to resolve technical problems.</li>
                  <li>Following up on technical queries and providing prompt feedback.</li>
                  <li>Prioritizing customer queries and escalating serious technical issues to IT developers.</li>
                  <li>Documenting error reports and monitoring performance metrics.</li>
                  <li>Relaying application functionality feedback from customers to IT developers.</li>
                  <li>Compiling IT instruction manuals and useful IT support resources.</li>
                  <li>Take ownership of customer issues reported and see problems through to resolution.</li>
                  <li>Research, diagnose, troubleshoot and identify solutions to resolve customer issues.</li>
                  <li>Ensure proper recording and closure of all issues.</li>
                  <li>Prepare accurate and timely reports.</li>
                  <li>Help others in your group to maintain progress on their tickets.</li>
                </ul>
                <a href="#register">
                  <button type="button" className="theme-btn1 mb-20 ml-30" >
                     Apply Now
                   </button>
               </a>
              </div>
          </div>
          <div className="col-sm-2">
            
            <button type="button" className="theme-btnn" onClick={classChange} id="btn1">
                See More
            </button> 
             
          </div>
        </div>
        <div className="roww">
          <div className="col-sm-10">
              <div >
                <h3 >Marketing for sales</h3>
                <p>
                 Job Role: Marketing for sales Eligibility: Any Degree, Experience: 1-3 Years
                </p>
                
              </div>
              <div className="roles" id="roles2">
                <h4 className="subhead">Roles and Responsibility</h4>
                <ul className="list-style-three pt-15 pb-30 pl-30">
                  <li >Contributing to the development of marketing strategies.</li>
                  <li>Conducting market research on rival products.</li>
                  <li>Designing and implementing marketing plans for company products.</li>
                  <li>Developing internal and external marketing materials and initiatives.</li>
                  <li>Coordinating with media representatives and sponsors.</li>
                  <li>Working with the sales team to develop targeted sales strategies.</li>
                  <li>Assist and review all contracts before sending them to the client.</li>
                  <li>Assist in the communication of potential sales leads, both online and over the phone.</li>
                  <li>Creating awareness of the products and services offered by the company.</li>
                  <li>Answering client queries about product specifications and uses in a professional manner.</li>
                  <li>Take ownership of customer issues reported and see problems through to resolution.</li>
                  <li>Maintaining client relations.</li>
                  <li>Tracking sales data to ensure the company meets sales quotas.</li>
                  <li>Creates and presents regular performance reports for managers and executives.</li>
                  <li>Research and capitalize on different marketing opportunities to improve product
                  marketability and profitability.</li>
                  <li>Takes notes and keeps minutes during sales and hands-off meetings.</li>
                  <li>Taking feedback from the clients.</li>
                </ul>
                <a href="#register">
                  <button type="button" className="theme-btn1 mb-20 ml-30" >
                     Apply Now
                   </button>
               </a>

              </div>
          </div>
          <div className="col-sm-2">
         
            <button type="button" className="theme-btnn" onClick={classChange1} id="btn2"> 
                See More
            </button> 
          
          </div>
        </div>
        <div className="roww">
          <div className="col-sm-10">
              <div >
                <h3 >Digital Marketing</h3>
                <p>
                 Job Role: Digital Marketing: Any Degree, Experience: 1-3 Years
                </p>
              
              </div>
              <div className="roles" id="roles3">
                <h4 className="subhead">Roles and Responsibility</h4>
                <ul className="list-style-three pt-15 pb-30 pl-30">
                <li >Identify trends and insights.</li>
                  <li>Keep up to date with current digital trends.</li>
                  <li>Allocate marketing investments.</li>
                  <li>Plan and direct marketing campaigns.</li>
                  <li>Developing and monitoring campaign budgets.</li>
                  <li>Manage an organization&#39;s website and maintain it, keeping best practices in mind.</li>
                  <li>Optimize content for the website and social media platforms.</li>
                  <li>Work with various content formats such as blogs, videos, audio podcasts, etc.</li>
                  <li>Track the website traffic flow.</li>
                  <li>Implement and analyze performance metrics.</li>
                  <li>Provide internal reports on a regular basis.</li>
                  <li>Execute new and creative collaborations among technologies and platforms.</li>
                  <li>Plan and execute all digital marketing, including SEO/SEM, marketing database, email, social media and display advertising campaigns.</li>
                  <li>Design, build and maintain our social media presence.</li>
                  <li>Design website banners and assist with web visuals.</li>
                  <li>Develop and integrate content marketing strategies.</li>
                  <li>Measure and report the performance of all digital marketing campaigns, and assess against goals (ROI and KPIs)</li>
                  <li>Identify trends and insights and optimize spend and performance based on the insights.</li>
                  <li>Brainstorm new and creative growth strategies.</li>
                  <li>Collaborate with internal teams to create landing pages and optimize the user experience.</li>
                  <li>Utilize the strong analytical ability to evaluate end-to-end customer experience across multiple channels and customer touchpoints.</li>
                  <li>Collaborate with agencies and other vendor partners.</li>
                </ul>
                <a href="#register">
                  <button type="button" className="theme-btn1 mb-20 ml-30" >
                     Apply Now
                   </button>
               </a>

              </div>
          </div>
          <div className="col-sm-2">
          
            <button type="button" className="theme-btnn" onClick={classChange2} id="btn3">
                See More
            </button>  
          
          </div>
        </div>
        <div className="roww">
          <div className="col-sm-10">
              <div >
                <h3 >Website developer</h3>
                <p>
                 Job Role: Website developer: Any Degree, Experience: 1-3 Years
                </p>
              </div>
              <div className="roles" id="roles4">
                <h4 className="subhead">Roles and Responsibility</h4>
                <ul className="list-style-three pt-15 pb-30 pl-30">
                <li>Manage your company CMS, working with developers and editors to ensure it&#39;s meeting the needs of your organization.</li>
                  <li>Conduct content audits to identify gaps and redundancies in the site content.</li>
                  <li>Align the website with the organization’s brand, strategy and standards.</li>
                  <li>Create and implement content schedules for people to produce or update content.</li>
                  <li>Develop website content to support organizational goals and strategies.</li>
                  <li>Evaluate Competitor’s websites.</li>
                  <li>Produce maps and visualizations of the site to help staff understand the structure and function of the website.</li>
                  <li>Write and edit web copy.</li>
                  <li>Source, commission and sometimes edit images and videos.</li>
                  <li>Monitor for website security breaches and respond to them as needed.</li>
                  <li>Develop policies relating to your organization’s web content, such as a house style.</li>
                  <li>Analyze analytics data to learn how users interact with your site.</li>
                  <li>Identify ways to improve website user experience.</li>
                  <li>Survey users and hold focus groups to learn about how they view your site.</li>
                  <li>Train staff in writing and producing content for the web.</li>
                  <li>Occasionally update HTML, CSS and JavaScript on your site</li>
                  <li>Stay knowledgeable about your site&#39;s subject area.</li>
                  <li>Create strategies to improve website traffic.</li>
                  
                </ul>
                <a href="#register">
                  <button type="button" className="theme-btn1 mb-20 ml-30" >
                     Apply Now
                   </button>
               </a>

              </div>
              
          </div>
          <div className="col-sm-2">
          
            <button type="button" className="theme-btnn" onClick={classChange3} id="btn4">
                See More
            </button>  
          
          </div>
          
        </div>

        <div className="roww">
          <div className="col-sm-10">
              <div >
                <h3 >.Net developer</h3>
                <p>
                 Job Role: .Net developer: Any Degree, Experience: 1-3 Years
                </p>
              </div>
              <div className="roles" id="roles5">
                <h4 className="subhead">Roles and Responsibility</h4>
                <ul className="list-style-three pt-15 pb-30 pl-30">
                <li>Utilizing Microsoft&#39;s .NET infrastructure to create software solutions that meet clients&#39; requirements.</li>
                  <li>Collaborating with co-workers to facilitate the creation of large, complex developments, as required.</li>
                  <li>Write clean, scalable code using .NET programming languages.</li>
                  <li>Reformulating existing code to ensure adherence to stipulated guidelines.</li>
                  <li>Checking your code to detect and remedy errors and omissions.</li>
                  <li>Improve existing software by incorporating the latest industry advancements and upgrades.</li>
                  <li>Testing your outputs to ensure that they are in excellent working order.</li>
                  <li>Serve as an expert on applications and provide technical support.</li>
                  <li>Ensuring that outputs are visually appealing, user-friendly, and engaging.</li>
                  <li>Deploying developments and monitoring uptake by the target audience.</li>
                  <li>Using clients&#39; feedback to inform the creation of subsequent versions of each development.</li>
                  <li>Identify bottlenecks and bugs, and devise solutions to mitigate and address these issues.</li>
                  <li>Help maintain code quality, organization, and automatization.</li>
                  <li>Translate application storyboards and use cases into functional applications.</li>
                  <li>Integrate data storage solutions may include databases, key-value stores, blob stores, etc.</li>
                  <li>Participate in requirements analysis.</li>
                  <li>Collaborate with internal teams to produce software design and architecture.</li>
                  <li>Develop documentation throughout the software development life cycle (SDLC).</li>
                  
                </ul>
                <a href="#register">
                  <button type="button" className="theme-btn1 mb-20 ml-30" >
                     Apply Now
                   </button>
               </a>

              </div>
              
          </div>
          <div className="col-sm-2">
          
            <button type="button" className="theme-btnn" onClick={classChange4} id="btn5">
                See More
            </button>  
          
          </div>
          
        </div>

        <div className="roww">
          <div className="col-sm-10">
              <div >
                <h3 >React.js Developer</h3>
                <p>
                 Job Role: React.js Developer : Any Degree, Experience: 1-3 Years
                </p>
              </div>
              <div className="roles" id="roles6">
                <h4 className="subhead">Roles and Responsibility</h4>
                <ul className="list-style-three pt-15 pb-30 pl-30">
                <li>Developing new user-facing features using React.js</li>
                  <li>Building reusable components and front-end libraries for future use</li>
                  <li>Designing highly responsive web-based user interfaces.</li>
                  <li>Translating designs and wireframes into high-quality code.</li>
                  <li>Optimizing components for maximum performance across a vast array of web-capable devices and browsers..</li>
                  <li>Maintain existing React systems, including fixing bugs.</li>
                  <li>Design and develop new custom components to meet project requirements.</li>
                  <li>Install and configure server environments for React deployments.</li>
                  <li>Maintain and update project dependencies as well as fix any conflicts.</li>
                  <li>Working with other JavaScript developers and software engineers to develop front-end and back-end architecture that’s well-structured and flexible, along with the APIs to support it.</li>
                  <li>Providing code documentation and other inputs to technical documents.</li>
                  <li>Participating in code reviews.</li>
                  <li>Construct visualizations that are able to depict vast amounts of data.</li> 
                </ul>
                <a href="#register">
                  <button type="button" className="theme-btn1 mb-20 ml-30" >
                     Apply Now
                   </button>
               </a>

              </div>
              
          </div>
          <div className="col-sm-2">
          
            <button type="button" className="theme-btnn" onClick={classChange5} id="btn6">
                See More
            </button>  
          
          </div>
          
        </div>

        <div className="roww">
          <div className="col-sm-10">
              <div >
                <h3 >VB developer</h3>
                <p >
                 Job Role: VB developer : Any Degree, Experience: 1-3 Years
                </p >
              </div>
              <div className="roles" id="roles7">
                <h4 className="subhead">Roles and Responsibility</h4>
                <ul className="list-style-three pt-15 pb-30 pl-30">
                <li>Work with developers to design algorithms and flowcharts.</li>
                  <li>Produce clean, efficient code based on specifications.</li>
                  <li>Integrate software components and third-party programs.</li>
                  <li>Verify and deploy programs and systems.</li>
                  <li>Troubleshoot, debug and upgrade existing software.</li>
                  <li>Gather and evaluate user feedback.</li>
                  <li>Recommend and execute improvements.</li>
                  <li>Create technical documentation for reference and reporting.</li>
                  <li>Design and develop systems administration and maintenance.</li>
                  <li>Provide onsite customer support.</li>
                  <li>Determine and develop required enhancements to the system.</li>
                  <li>Initiate and implement full life cycle development.</li>
                  <li>Write code for software applications.</li> 
                  <li>Develop designs to support business requirements and needs.</li> 
                  <li>Initiate and implement quality assurance protocols for software.</li> 
                  <li>Manage and document concepts, practices, procedures, processes and issues.</li> 
                  <li>Maintain and manage system specification life cycle documents of software applications.</li> 
                  <li>Ensure compliance with organizational processes and procedures all the time.</li> 
                  <li>Translate functional requirements and detailed specifications into functional application code.</li> 
                  <li>Perform unit and system testing to ensure the designed functionality works correctly.</li> 
                  <li>Present the final product to demonstrate functional capability and correct functioning.</li>
               
                </ul>
                <a href="#register">
                  <button type="button" className="theme-btn1 mb-20 ml-30" >
                     Apply Now
                   </button>
               </a>

              </div>
              
          </div>
          <div className="col-sm-2">
          
            <button type="button" className="theme-btnn" onClick={classChange6} id="btn7">
                See More
            </button>  
          
          </div>
          
        </div>


        <div className="roww">
          <div className="col-sm-10">
              <div >
                <h3 >SQL Programmer</h3>
                <p >
                 Job Role: SQL Programmer : Any Degree, Experience: 1-3 Years
                </p>
              </div>
              <div className="roles" id="roles8">
                <h4 className="subhead">Roles and Responsibility</h4>
                <ul className="list-style-three pt-15 pb-30 pl-30">
                <li>Development of high-quality database solutions</li>
                  <li>Develop, implement and optimize stored procedures and functions using T-SQL</li>
                  <li>Review and interpret ongoing business report requirements.</li>
                  <li>Research required data.</li>
                  <li>Build appropriate and useful reporting deliverables.</li>
                  <li>Analyze existing SQL queries for performance improvements.</li>
                  <li>Suggest new queries.</li>
                  <li>Develop procedures and scripts for data migration.</li>
                  <li>Provide timely scheduled management reporting.</li>
                  <li>Investigate exceptions with regard to asset movements.</li>
                  <li>Help write and optimize in-application SQL statements.</li>
                  <li>Ensure the performance, security, and availability of databases.</li>
                  <li>Prepare documentation and specifications.</li> 
                  <li>Profile server resource usage and optimize and tweak as necessary.</li> 
                  <li>Collaborate with other team members and stakeholders.</li> 
                  <li>Troubleshooting and problem-solving using JavaScript and other programming languages.</li> 
                  <li>Maintain and back up databases regularly.</li> 
                  <li>Perform SQL database sharding and indexing procedures as required to handle heavy traffic loads.</li> 
                  <li>Use database analytical tools to analyze performance and generate reports</li>
                </ul>
                <a href="#register">
                  <button type="button" className="theme-btn1 mb-20 ml-30" >
                     Apply Now
                   </button>
               </a>

              </div>
              
          </div>
          <div className="col-sm-2">
            <button type="button" className="theme-btnn" onClick={classChange7} id="btn8">
                See More
            </button>  
          </div>          
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;
