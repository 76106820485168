import { Link } from "react-router-dom";

function Navigation({ className }) {
  return (
    <>
      <div className={`restly-header-main-menu ${className || ""}`}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
                <Link to="/about-us">About</Link>
          </li>
          <li>
                <Link to="/services">Service</Link>
          </li>         
          <li>
                <Link to="#">Products</Link>
                <ul className="sub-menu mr-0">
                <li><Link to="/products/premises" >On Premises</Link> 
                <ul className="sub-menu">
                <li><Link to="/frontoffice">Front Office</Link> </li>
                <li><Link to="#">Point of Sale</Link> 
                  <ul className="sub-menu">
                    <li><Link to="/pointofsales">Restaurant</Link></li> 
                    <li><Link to="/housekeeping">House Keeping</Link> </li>
                    <li><Link to="/banquet-management-system">Banquet Management</Link> </li>
                  </ul>
                </li>               
                <li><Link to="/bar-management-system">Bar</Link> </li>
                <li><Link to="#">Store</Link> 
                  <ul className="sub-menu">
                  <li><Link to="/purchase-software">Purchase</Link> </li>
                  <li><Link to="/Material-Management-System">Material Management</Link> </li>
                  <li><Link to="/gatepass-management-system">Gate Pass</Link> </li>
                  <li><Link to="/Fbcosting-system">F & B Costing</Link> </li>
                  <li><a href="/Inventory-management-system">Inventory Management</a></li>
                  </ul>
                </li>
                <li><Link to="/linen-management-software">Linen Management</Link> </li>
                <li><Link to="/payroll-management-system">Payroll Management</Link> </li>
                <li><Link to="/task-management-system">Task Management</Link> </li>
                <li><Link to="/leisure-management-system">Leisure Management</Link> </li>
                <li><Link to="/travel-desk">Travel Desk</Link> </li>
                <li><Link to="/retail-management">Retail Management</Link> </li>
                <li><Link to="/assest-management">Asset Tracking</Link> </li> 
                <li><Link to="/power-automation-system">Power Automation</Link> </li>
                <li><Link to="/kitchen-display-system">KDS</Link> </li>
                <li><Link to="/club-member-management-software">Member Management</Link></li>
                <li><Link to="/hotel-revenue-member-management-software">Revenue Management</Link></li>
                </ul>
                </li>
                  <li><Link to="/products/cloud">Cloud</Link>
                  <ul  className="sub-menu">
                  <li><Link to="/hotel-front-office-software">Front Office</Link> </li>
                  <li><Link to="/bar-management-system">Bar</Link> </li>
                  <li><Link to="/hotel-and-restaurant-website-builder">Website Builder</Link> </li>
                  <li><Link to="/central-reservation-system">CRS</Link> </li>
                  <li><Link to="/internet-reservation-system">IRS</Link> </li>
                  <li><Link to="/digital-menu-digital-system">DMDS</Link></li>
                  <li><Link to="/Inventory-management-system">Inventory Management</Link></li>
                  <li><Link to="/club-member-management-software">Club Management</Link></li>
                  <li><Link to="/hotel-management-mobile-application">Mobile Application</Link></li>
                  <li>
                    <Link to="/qr-based-ordering-system">Self-ordering Restaurant QR code based</Link>
                  </li>
                  <li>
                    <Link to="/self-ordering-room-services-qr-based">Self-ordering Room Service QR code based</Link>
                  </li>
                  <li><Link to="/guest-feed-back">Guest Feedback</Link></li>
                  </ul>
                  </li>
                  <li><Link to="/products/integration">Integrations</Link>
                  <ul  className="sub-menu">
                  <li><Link to="/wifi-services">Wifi Interface</Link> </li>
                  <li><Link to="/sms-ealert-system">E-Alert SMS</Link> </li>
                  <li><Link to="/email-notification-software">E-Alert Email</Link> </li>
                  <li><Link to="/whatsapp-business-integration">E-Alert Whatsapp</Link> </li>
                  <li><Link to="/hotel-channel-manager">Channel Manager</Link> </li>
                  <li><Link to="/tally-interface">Tally Interface</Link> </li>
                  <li><Link to="/wireless-door-lock">Door Lock</Link> </li>
                  <li><Link to="/swiggy-pos-integration">Swiggy Online Order</Link> </li>
                  <li><Link to="/zomato-pos-integration">Zomato Online Order</Link> </li>
                  </ul>
                  </li>
                  <li>
                  <a href="https://www.hotelsmartlock.com/" target="_blank" rel="noopener noreferrer">
                    Hotel Locks
                  </a>
                </li>
                </ul>

          </li>
          <li>
            <Link to="/partner">Partners</Link>
          </li>
          <li>
            <Link to="/careers">Careers</Link>
          </li>
       {/* } <li>
            <Link to="#">
              Home <i className="fa fa-angle-down" />
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/">Home 1</Link>
              </li>
              <li>
                <Link to="/home-two">Home 2</Link>
              </li>
              <li>
                <Link to="/home-three">Home 3</Link>
              </li>
              <li>
                <Link to="/home-four">Home 4</Link>
              </li>
              <li>
                <Link to="/home-five">Home 5</Link>
              </li>
              <li>
                <Link to="/home-six">Home 6</Link>
              </li>
              <li>
                <Link to="/home-seven">Home 7</Link>
              </li>
              <li>
                <Link to="/home-eight">Home 8</Link>
              </li>
              <li>
                <Link to="/home-nine">Home 9</Link>
              </li>
            </ul>
          </li> { */}        
      
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/team">Team</Link>
          </li>
          {/* <li>
            <Link to="/team">Blog</Link>
          </li> */}
          <li>
            <Link to="#">Blog</Link>
            <ul  className="sub-menu">
               <li>
                <Link to="/blog/hotel-management-systems-shaping-the-future-of-hospitality">Hotel Management</Link>
          </li>
           <li>
                <Link to="/blog/top-advantages-of-using-hotel-management-software-for-small-hotels
">Advantages of Hotel Management</Link>
          </li>
          <li>
                <Link to="/blog/essential-rms-reports-for-optimizing-hotel-revenue-management
">Essential RMS Reports for Optimizing Hotel Revenue Management</Link>
          </li>
          <li>
                <Link to="/blog/how-hotel-management-software-boosts-efficiency-and-guest-satisfaction
">Boost Hotel Efficiency & Guest Satisfaction with Management Software</Link>
          </li>
            </ul>
          </li>
         
         
        
        </ul>
      </div>
    </>
  );
}

export default Navigation;
