import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/club.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li>
                    <a href="/Websitebuilder">Hotels and restaurant website builder</a>
                  </li> 
            
                  <li  >
                    <a href="/Fbcosting">F & B Costing</a>
                  </li>
                  <li  className="active">
                    <a href="/Club">Club Management</a>
                  </li>

                  <li>
                    <a href="/Guestfeedback">Guest Feedback</a>
                  </li>
                  
                  
                  <li>
                    <a href="/Kot">Tab-based KOT System</a>
                  </li>
                 
                  <li>
                    <a href="/Self">Self-ordering Restaurant QR code based</a>
                  </li>
                  <li>
                    <a href="/Webcheck">Web Check-in</a>
                  </li>
              
                </ul>
              </div>
              <div
                className="widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                //style={{ backgroundImage: `url(${background})` }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+91-9566411119">+91-95664-11119</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:sales@microgenn.com">sales@microgenn.com</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:30 - 6:30</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-30">
                {/* <img
                  src={
                    product
                  }
                  alt="Club Management"
                /> */}
              </div>
              <h2>Club Management</h2>
              <p>Microgenn club management software is a comprehensive tool that helps the
                organization run its club effortlessly. Our solution is customizable, easy to
                navigate, and web-based. It creates an effective connection between employees,
                club members, leads, and activities of the club to enable seamless club
                management.
                {" "}
              </p>
              <p>
              Our software plays an important role in payment management, revenue
                management, inventory management, employee management, and more.
                Without adding further complexity, it helps to perform daily tasks quickly and
                accurately. Ensures personalized member history and activity tracking with a
                user-friendly and flexible interface. Offers management solutions for a wide
                range of sports clubs including health clubs, golf clubs, fitness clubs, swimming
                clubs, football clubs, and more.
              {" "}
              </p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Membership profile database (incorporating member activities, likes/dislikes, etc.)
                </li>
                <li>Non-member contact list
                </li>
                <li>Member types
                </li>
                <li>Automatic renewal/expiration</li>
                <li>E-Mail campaigns</li>
                <li>Event calendar</li>
                <li>Document Library</li>
                <li>Committee management</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
