import React from "react";
import Layouts from "../../helpers/LayoutsR";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Retail() {
  return (
    <>
       {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Hospitality Retail Management Services | MicroGenn</title> */}
        <meta
          name="title"
          content=" Hospitality Retail Management Services | MicroGenn  "
        />
        <meta name="description" content="Find how hospitality retail management ensures consistent service, quality, and customer satisfaction in hotels, restaurants, and more." />
        <meta name="keywords" content="Hospitality management, retail management, customer experience, quality service, hospitality industry, hotels, restaurants." />
      </Helmet>
    <Layouts
      pageTitle="Retail Management"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Retail Management", path: "/retail-management" },
      ]}
    >
      <MainSection />
    </Layouts>
</>  );
}
