import React from "react";
import Layouts from "../../helpers/LayoutsTally";
import MainSection from "./MainSection";
import { Helmet } from 'react-helmet';

export default function Tally() {
  return (
    <>
         {/* Helmet for SEO Metadata */}
  <Helmet>
    {/* import { Helmet } from 'react-helmet'; */}
        {/* <title>Tally Interface for Restaurants | MicroGenn</title> */}
        <meta
          name="title"
          content="Tally Interface for Restaurants | MicroGenn "
        />
        <meta name="description" content="Automate invoicing, inventory, and workforce analysis with Tally Interface for hotels. GST-compliant, cost-effective, and streamlined operations." />
        <meta name="keywords" content="Tally Interface, Hotel Accounting, Inventory Management, Cost Analysis." />
      </Helmet>
    <Layouts
      pageTitle="Tally Interface"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Tally Interface", path: "/tally-interface" },
      ]}
    >
      <MainSection />
    </Layouts>
 </> );
}
