import React from "react";
//import background from "../../../assets/images/contact/home-two.jpg";
import product from "../../../assets/images/products/kds.jpg";
export default function MainSection() {
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                <li >
                    <a href="/Frontoffice">Frontoffice</a>
                  </li> 
                  <li >
                    <a href="/Pointofsales">Point of Sale</a>
                  </li>
                  <li >
                    <a href="/Bar">Bar Management System</a>
                  </li>
                  
                  <li  >
                    <a href="/Housekeeping">House Keeping</a>
                  </li>
                  <li >
                    <a href="/Linen">Linen Management</a>
                  </li>
                 
                  <li >
                    <a href="/Banquet">Banquet Management</a>
                  </li>
                  <li>
                    <a href="/Purchase">Purchase</a>
                  </li>
                  <li >
                    <a href="/Material">Material Management</a>
                  </li>
                  <li >
                    <a href="/Payroll">Payroll Management</a>
                  </li>
                  <li>
                    <a href="/Gatepass">Gate Pass</a>
                  </li>

                  <li>
                    <a href="/Task">Task Management</a>
                  </li>
                  <li >
                    <a href="/Leisure">Leisure Management</a>
                  </li>

                  <li>
                    <a href="/Travel">Travel Desk</a>
                  </li>

                  <li>
                    <a href="/Retail">Retail Management</a>
                  </li> 
                 
                  <li>
                    <a href="/Asset">Asset Traking</a>
                  </li>
                  <li    className="active">
                    <a href="/Kds">KDS (Kitchen Display System)</a>
                  </li>
                  <li>
                    <a href="/Powerautomation">Power Automation System</a>
                  </li>
                  <li>
                    <a href="/Crs">Central Reservation System</a>
                  </li>
                  <li>
                    <a href="/Irs">Internet Reservation System</a>
                  </li>
                </ul>
              </div>
              
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image ">
                {/* <img
                  src={
                    product
                  }
                  alt="KDS"
                /> */}
              </div>
              <h2><span id="color">KDS </span>(Kitchen Display System)</h2>
              <p>Equip your kitchen with the most durable all-in-one kitchen display system.
                Microgenn KDS is simple and easy to install, it is built with long-life embedded
                components to withstand continuous and excessive exposure to heat, grease,
                humidity, liquids, and heavy use.
                {" "}
              </p>
              <p>It optimizes food quality, workflow, and speed of service. Color-coded orders
                are prioritized and updated from the restaurant POS system, mobile apps, and
                website in real time. Predefined cook timing is used to prioritize preparation
                tasks, break down each order, and automatically alert staff about ticket times
                that have exceeded your restaurant standards.
              {" "}
              </p>
              <p>Kitchen display systems do more than just reduce the need for paper in a
                restaurant. As every restaurant is unique, a high-quality KDS should adapt to
                different restaurant needs. KDS is not only a solution for a larger restaurant, but
                you can implement its measurable benefits in any establishment. If you are
                looking to decrease ticket times, promote efficiencies in the kitchen, or increase
                customer satisfaction, KDS may be the right solution.
              {" "}
              </p>
              <p>With meal coursing/pacing, when a table sits down and orders, the server can
                submit their orders simultaneously, which allows them to focus solely on the
                guest and their immediate needs. Likewise, in the kitchen, appetizers, drinks,
                and entrees are paced accordingly to the configured time which is based on prep
                times, ensuring that every item gets to the expo window simultaneously. {" "}</p>
              <p>A KDS ensures that food is fresh and at the right temperature when it arrives to
                the customer. In addition, the software will manage these ranges for balancing
                food items with shorter and longer cook times. A recipe viewer reduces the cost
                of training, calls attention to nutrition and allergy information, and reduces
                paper waste from kitchen printers. Recipe viewers manage quality control as the
                software includes images of the end product before getting run to diners.{" "}</p>
              <h3><span id="color">Features</span></h3>
              <ul className="list-style-three pt-15 pb-30">
                <li>Adds even more customizations to support complicated menus.
                </li>
                <li>Uses routing modifiers or child items to separate views.
                </li>
                <li>Manages priorities uniquely by the station.
                </li>
                <li>Load balances large kitchens.</li>
                <li>Supports Bin levels of configured items.</li>
                <li>Recipe viewer</li>
                <li>Decreases restaurant food waste.</li>
                <li>Improves product efficiencies.</li>
                <li>Provides up-to-the-second communication with servers on item statuses.</li>
                <li>Order views</li>
                <li>Item views</li>
                <li>Order ready</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
