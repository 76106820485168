 import { useState } from "react";
 import { Gallery } from "react-grid-gallery";
import React, { Component } from 'react';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images, CustomImage } from "./images";
    

function addsc(a){
    

    for(let i =1 ; i<=3 ; i++){
      if(i == a){
           document.getElementById('tabpanel'+i).classList.remove('isnot-active');
           document.getElementById('tabpanel'+i).classList.add('is-active');
           document.getElementById('box'+i).classList.add('tab-activee');
          
          
      }
      else
      {
          document.getElementById('tabpanel'+i).classList.remove('is-active');
          document.getElementById('tabpanel'+i).classList.add('isnot-active');
          document.getElementById('box'+i).classList.remove('tab-activee');
          
 
      }
    }
  }

    export default function MainSection() {
        
        const [index, setIndex] = useState(-1);
      
        const currentImage = images[index];
        const nextIndex = (index + 1) % images.length;
        const nextImage = images[nextIndex] || currentImage;
        const prevIndex = (index + images.length - 1) % images.length;
        const prevImage = images[prevIndex] || currentImage;
      
        const handleClick = (index: number, item: CustomImage) => setIndex(index);
        const handleClose = () => setIndex(-1);
        const handleMovePrev = () => setIndex(prevIndex);
        const handleMoveNext = () => setIndex(nextIndex);
      
        return (
          
       <section className="about-section-six pb-120 rpb-100" style={{backgroundColor:"#f7f7f9"}}>
        <div class = "tab-bar">
			<a href = "#tabpanel1"   class="tab-active tab-activee" id="box1" onClick={() => addsc(1)}>Office</a>
			<a href = "#tabpanel2"   class="tab-active" id="box2" onClick={() => addsc(2)} >Celebrations</a>
			<a href = "#tabpanel3"   class="tab-active" id="box3" onClick={() => addsc(3)} >Awards</a>
	    </div>
	
        <div id="tabpanel1" class ="is-active">
            {/* <div className="section-title mb-15">
                 <span className="sub-title" id="color">Export Team</span> 
                 <h2 class="text-center">Moments</h2> 
            </div> */}
            <div className="container">
                
                <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
                />
                {!!currentImage && (
                /* @ts-ignore */
                <Lightbox
                    mainSrc={currentImage.original}
                    imageTitle={currentImage.caption}
                    mainSrcThumbnail={currentImage.src}
                    nextSrc={nextImage.original}
                    nextSrcThumbnail={nextImage.src}
                    prevSrc={prevImage.original}
                    prevSrcThumbnail={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
                )}
            </div>
        </div>

        <div id="tabpanel2" class ="isnot-active">
            {/* <div className="section-title mb-15">
               <span className="sub-title" id="color">Export Team</span> 
                 <h2 class="text-center">Birthday celebrations</h2> 
            </div> */}
            <div className="container">
                
                <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
                />
                {!!currentImage && (
                /* @ts-ignore */
                <Lightbox
                    mainSrc={currentImage.original}
                    imageTitle={currentImage.caption}
                    mainSrcThumbnail={currentImage.src}
                    nextSrc={nextImage.original}
                    nextSrcThumbnail={nextImage.src}
                    prevSrc={prevImage.original}
                    prevSrcThumbnail={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
                )}
            </div>
        </div>

        <div id="tabpanel3" class ="isnot-active">
            {/* <div className="section-title mb-15">
                 <h2 class="text-center">Celebrations</h2> 
            </div> */}
            <div className="container">
                
                <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
                />
                {!!currentImage && (
                /* @ts-ignore */
                <Lightbox
                    mainSrc={currentImage.original}
                    imageTitle={currentImage.caption}
                    mainSrcThumbnail={currentImage.src}
                    nextSrc={nextImage.original}
                    nextSrcThumbnail={nextImage.src}
                    prevSrc={prevImage.original}
                    prevSrcThumbnail={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
                )}
            </div>
        </div>

    </section>
    
        );
    }
      